<template>
    <div>
        <v-sheet class="footer-info" color="#000">
            <CustomContainer class="d-flex flex-column">
                <div class="white--text footer-info-title font-weight-bold">{{ $t("common.contact_us") }}</div>
                <v-form v-model="valid" class="csutom-form">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                class="custom-field"
                                v-model="firstname"
                                :counter="10"
                                :label="$t('contact_us.input_1')"
                                color="white"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                class="custom-field"
                                v-model="lastname"
                                :counter="10"
                                :label="$t('contact_us.input_2')"
                                color="white"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                class="custom-field"
                                color="white"
                                v-model="email"
                                :label="$t('contact_us.input_3')"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                class="custom-field"
                                color="white"
                                v-model="phone"
                                :label="$t('contact_us.input_4')"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                class="custom-field"
                                color="white"
                                v-model="company_name"
                                :label="$t('contact_us.input_5')"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                class="custom-field"
                                color="white"
                                v-model="looking_type"
                                :label="$t('contact_us.input_6')"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                class="custom-field"
                                color="white"
                                v-model="other_message"
                                :label="$t('contact_us.input_7')"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-checkbox color="white" class="custom-checkbox" v-model="checkbox">
                                <template #label>
                                    <div class="text-12">
                                        {{ $t("contact_us.subtitle_1") }}

                                        <span class="cursor white--text font-weight-bold"> {{ $t("contact_us.subtitle_2") }}</span>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-btn color="primary font-weight-bold" block x-large dark @click="onSendMsg" :disabled="!checkbox">{{
                        $t("contact_us.submit")
                    }}</v-btn>
                </v-form>

                <div v-if="false" class="d-flex">
                    <v-row>
                        <v-col
                            class="d-flex flex-column justify-space-between white--text contacts-dev-item"
                            lg="3"
                            cols="12"
                            v-for="(item, index) of addressData"
                            :key="index"
                        >
                            <div class="dev-header">{{ $t(item.title) }}</div>
                            <div class="white--text text-36 mb-8">{{ $t(item.name) }}</div>
                            <div class="text-gray text-16" style="min-height: 88px">
                                {{ $t(item.address) }}
                            </div>
                            <div class="white--text text-18 font-weight-bold">{{ $t(item.phone) }}</div>
                            <div class="white--text text-18 font-weight-bold">{{ $t(item.phone_back) }}</div>
                        </v-col>
                    </v-row>
                </div>
            </CustomContainer>
        </v-sheet>
        <v-sheet v-if="false" class="footer-default" color="#1c1c1c" height="650">
            <CustomContainer class="d-flex flex-column justify-space-around">
                <v-autocomplete
                    color="white"
                    item-text="Description"
                    label="Search"
                    placeholder="Start typing to Search"
                    append-icon="mdi-magnify"
                    class="custom-autocomplete"
                ></v-autocomplete>
                <div>
                    <v-row>
                        <v-col v-for="n in 8" :key="n" lg="auto" sm="3" xs="4">
                            <v-sheet color="transparent" class="d-flex flex-column align-center">
                                <img class="mb-5" height="85" src="https://www.softeq.com/hubfs/static/images/certs/AWS.svg" alt="" />
                                <p class="text-gray text-13">AWS Certified Partner</p>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
                <div class="d-flex align-end">
                    <div class="text-gray text-12">
                        © 2023 Softeq Development Corp.
                        <span class="white--text cursor">Privacy Policy</span>
                    </div>
                    <div class="ml-auto">
                        <v-btn height="44" width="44" class="mx-2" color="#dedede" fab v-for="n in 5" :key="n">
                            <v-icon size="28" color="#000">mdi-twitter</v-icon>
                        </v-btn>
                    </div>
                </div>
            </CustomContainer>
        </v-sheet>
    </div>
</template>
<script>
import { track_error_event } from "@/core/ErrorEvent";
export default {
    name: "Footer",
    components: {},

    data: () => ({
        lastSendObj: {},
        model: null,
        valid: false,
        firstname: "",
        lastname: "",
        nameRules: [(v) => !!v || "Name is required", (v) => v.length <= 10 || "Name must be less than 10 characters"],
        email: "",
        emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+/.test(v) || "E-mail must be valid"],
        phone: "",
        phoneRules: [(v) => !!v || "phone is required", (v) => v.length > 5 || "phone must be valid"],

        company_name: "",
        looking_type: "",
        other_message: "",
        checkbox: false,

        addressData: [
            {
                title: "address_info.title_1",
                name: "address_info.name_1",
                address: "address_info.address_1",
                phone: "address_info.phone_1",
                phone_back: "address_info.phone_1_back",
            },
            {
                title: "address_info.title_2",
                name: "address_info.name_2",
                address: "address_info.address_2",
                phone: "address_info.phone_2",
                phone_back: "address_info.phone_2_back",
            },
            {
                title: "address_info.title_3",
                name: "address_info.name_3",
                address: "address_info.address_3",
                phone: "address_info.phone_3",
                phone_back: "address_info.phone_3_back",
            },
            {
                title: "address_info.title_4",
                name: "address_info.name_4",
                address: "address_info.address_4",
                phone: "address_info.phone_4",
                phone_back: "address_info.phone_4_back",
            },
        ],
    }),
    methods: {
        submit() {
            this.$v.$touch();
        },
        /**
         * 验证邮箱是否合法
         * @param value
         */
        checkMail(value) {
            //@ts-ignore
            const Regx = /^(?:[a-zA-Z0-9]+[_\-\+\.]?)*[a-zA-Z0-9]+@(?:([a-zA-Z0-9]+[_\-]?)*[a-zA-Z0-9]+\.)+([a-zA-Z]{2,})+$/;
            return Regx.test(value);
        },
        onSendMsg() {
            if (!((this.email && this.email.trim()) || (this.phone && this.phone.trim()))) {
                alert(this.$t("common.phone_or_mail_error"));
                return;
            }
            if (this.email && this.email.trim()) {
                if (!this.checkMail(this.email.trim())) {
                    alert(this.$t("common.phone_or_mail_error"));
                    return;
                }
            }

            if (!this.other_message || !this.other_message.trim()) {
                alert(this.$t("common.message_error"));
                return;
            }
            const obj = {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                phone: this.phone,
                company_name: this.company_name,
                looking_type: this.looking_type,
                other_message: this.other_message,
            };
            let isSame = true;
            const keys = Object.keys(obj);
            for (let index = 0; index < keys.length; index++) {
                const element = keys[index];
                if (obj[element] != this.lastSendObj[element]) {
                    isSame = false;
                    break;
                }
            }

            if (!isSame) {
                this.lastSendObj = JSON.parse(JSON.stringify(obj));
                alert(this.$t("common.send_success"));
                track_error_event(obj);
            } else {
                alert(this.$t("common.send_repeat"));
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.text-gray {
    color: #939393;
}
.footer-info {
    // padding: 125px 0 120px;
    .footer-info-title {
        font-size: 54px;
    }
}
::v-deep .custom-autocomplete {
    max-width: 770px;
    max-height: 54px;
    &.v-text-field > .v-input__control > .v-input__slot:before {
        border-color: #383838;
    }
    &.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
        border-color: #383838;
    }
    .v-label,
    .v-icon {
        color: #939393;
    }
    .v-icon {
        transform: unset !important;
    }
    input {
        color: #fff !important;
        &::placeholder {
            color: #939393 !important;
        }
    }
}

::v-deep .custom-field {
    &.v-input {
        color: #939393;
    }
    .v-label,
    .v-icon {
        color: #939393;
    }

    &.v-text-field > .v-input__control > .v-input__slot:before {
        border-color: #383838;
    }
    &.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
        border-color: #383838;
    }
    input {
        color: #fff !important;
        &::placeholder {
            color: #939393 !important;
        }
    }
}

.csutom-form {
    // width: 900px;
    margin: 0 auto 140px;
}

::v-deep .custom-checkbox {
    .v-label,
    .v-icon {
        color: #939393;
    }
}

.contacts-dev-item {
    margin-bottom: 80px;
    .dev-header {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 30px;
        margin-bottom: 25px;
        max-width: 220px;
        padding-top: 9px;
        color: #383838;
        border-top: 2px solid #383838;
    }
}
</style>

<template>
    <v-app id="inspire" v-scroll-reveal="{ reset: false, opacity: 0 }">
        <Header />
        <v-main>
            <router-view />
        </v-main>
        <Footer />
        <GoToTop />
        <!-- {{ counter }}
        <v-btn @click="add">add</v-btn> -->
    </v-app>
</template>
<script>
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";
// import { mapState } from "pinia";
// import { useCounterStore } from "./stores/counter";
import GoToTop from "@/components/GoToTop.vue";

export default {
    name: "App",

    components: {
        Header,
        Footer,
        GoToTop,
    },
    computed: {
        // ...mapState(useCounterStore, ["counter"]),
    },

    data: () => ({
        // counterStore: useCounterStore(),
    }),
    methods: {
        // add() {
        //     this.counterStore.add();
        // },
        goToTop() {
            // Scroll to the top of the page smoothly
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    overflow: hidden;
}
</style>

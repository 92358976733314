<template>
    <v-btn v-scroll="onScroll" v-show="fab" fab dark color="primary" fixed bottom right @click="goToTop">
        <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
</template>
<script>
export default {
    name: "GoToTop",
    components: {},
    data: () => ({
        fab: false,
    }),
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },
        goToTop() {
            this.$vuetify.goTo(0);
        },
    },
};
</script>
<style lang="scss" scoped></style>

import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

class FirebaseData {
    static get Instance() {
        if (!this._instance) {
            this._instance = new FirebaseData();
        }
        return this._instance;
    }

    static firebaseConfig = {
        //databaseURL: "https://firsttestfiredata.firebaseio.com",
        apiKey: "AIzaSyCbUzXF7Mj2ka4t54E1LHrLciH2bpQiNvU",
        authDomain: "tokuu-84437.firebaseapp.com",
        projectId: "tokuu-84437",
        storageBucket: "tokuu-84437.appspot.com",
        messagingSenderId: "311752558526",
        appId: "1:311752558526:web:9a629594211d05d5489bef",
        measurementId: "G-JQ3Q8E0CZ2",
    };

    constructor() {
        const app = initializeApp(FirebaseData.firebaseConfig);
        this.firebaseDB = getFirestore(app);
    }

    get getTimeInfo() {
        const time_info = {};
        const currentTime = new Date();
        const options = {
            timeZone: "Asia/Shanghai",
            hour12: true,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        };
        const localTimeString = currentTime.toLocaleString("en-US", options);
        console.log(localTimeString);
        time_info.time = currentTime.getTime() + "";
        time_info.time_local = new Date();
        time_info.time_baijing = localTimeString;
        return time_info;
    }

    get getPageInfo() {
        const obj = {};
        obj.url = window.location.href;
        return obj;
    }

    get getDiverInfo() {
        const deviceType = window.innerWidth < 768 ? "mobile" : "desktop";
        let browserName = "";
        const aa = navigator.userAgent.match(/(chrome|firefox|safari|edge|opera)/i);
        if (aa && aa.length > 0 && aa[0]) {
            browserName = aa[0].toLowerCase();
        }

        let browserVersion = "";
        const bb = navigator.userAgent.match(/(chrome|firefox|safari|edge|opera)\/([\d.]+)/i);
        if (bb && bb.length > 0 && bb[2]) {
            browserVersion = bb[2];
        }

        const operatingSystem = navigator.platform;
        const resolution = window.screen.width + "x" + window.screen.height;
        const viewportSize = window.innerWidth + "x" + window.innerHeight;
        const language = navigator.language || navigator.userLanguage;
        const currentURL = window.location.href;

        return {
            deviceType: deviceType,
            browserName: browserName,
            browserVersion: browserVersion,
            operatingSystem: operatingSystem,
            resolution: resolution,
            viewportSize: viewportSize,
            language: language,
            currentURL: currentURL,
        };
    }

    async addDocData(doc_name, data) {
        if (!data) return;
        const newObj = {};
        newObj.message = JSON.parse(JSON.stringify(data));
        newObj.time_info = JSON.parse(JSON.stringify(this.getTimeInfo));
        newObj.page_info = JSON.parse(JSON.stringify(this.getPageInfo));
        newObj.diver_info = JSON.parse(JSON.stringify(this.getDiverInfo));

        try {
            const collectionRef = collection(this.firebaseDB, "email_data");
            await addDoc(collectionRef, newObj);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }
}

export function track_error_event(data = {}, eventName = "windowerror") {
    console.log("---调用---");
    const dataLayer_info = window.dataLayer || [];
    dataLayer_info.push(Object.assign({ event: eventName }, data));
    const skin = window.location.host;
    FirebaseData.Instance.addDocData(skin, data);
}

window.track_error_event = track_error_event;

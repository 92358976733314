export default {
    common: {
        home: "ホーム",
        contact_us: "お問い合わせ",
        learn_more: "詳細を学ぶ",
        show_more: "もっと見る",
        get_in_touch: "お問い合わせ",
        get_in_touch_2: "詳細をお知らせください",
        send_success: "发送成功!",
        send_repeat: "请勿重复发送！",
        phone_or_mail_error: "请输入正确的手机号或邮箱!",
        message_error: "请输入内容!",
    },
    hander: {
        head_title_1: "サービス",
        head_title_2: "カスタムソフトウェア開発",
        head_title_3: "Webシステム",
        head_title_4: "モバイルアプリ",
        head_title_5: "クラウドシステム",
        head_title_6: "AWSソフトウェア",
        head_title_7: "デスクトップアプリ",
        head_title_8: "QT開発",

        head_title_9: "UX/UIデザイン",
        head_title_10: "サイバーセキュリティ",
        head_title_11: "組み込みシステム開発",
        head_title_12: "フロントエンド",
        head_title_13: "バックエンド",
        head_title_14: "保守およびサポート",
        head_title_15: "DevOps",
        head_title_16: "ソリューション",
        head_title_17: "IoT",
        head_title_18: "ストレージ",

        head_title_19: "コンピュータビジョン",
        head_title_20: "ブロックチェーン",
        head_title_21: "業界",
        head_title_22: "家庭用電化製品",
        head_title_23: "ヘルスケア",
        head_title_24: "ソフトウェアとテクノロジー",
        head_title_25: "自動車",
        head_title_26: "データストレージ",
        head_title_27: "会社について",
    },

    home_page: {
        title_1: "私たちと一緒に作りましょう",
        subtitle_1: "早期スタートアップを強化するために、現金と開発サービスで125,000ドルを受け取るために今すぐ申し込んでください。",
        title_2: "ビジネスを変革する",
        subtitle_2: "単体のテクノロジー修正では満足しないでください。オムニチャネルのデジタルエコシステムを考えてみてください。",
        title_3: "AからZまでエンジニアリング",
        subtitle_3: "どの段階からでも始められる、複雑なエンドツーエンドの開発を、1つのフルスタックチームで行います。",
        title_4: "スケールに合わせて構築",
        subtitle_4: "段階1から製品を成功に導きます。",

        title_5: "tokuuイノベーションエンジン",

        title_6: "イノベーションとアイデア",
        subtitle_6: "tokuuイノベーションラボとハンズオンブートキャンプを通じて、早期のアイデアを提供し、突破的な成長を実現します。",
        title_7: "ニーズアセスメント",
        subtitle_7:
            "ビジネスソリューションとソリューションエンジニアリングチームが、ニーズを特定し、明確な進むべき道を定義するのに役立ちます。",
        title_8: "ソリューションの発見",
        subtitle_8: "我々のアーキテクトとエンジニアは、最も困難な問題に取り組み、創造的な提案を提供する準備ができています。",
        title_9: "プロトタイピングとR&D",
        subtitle_9:
            "多くの場合、実用的なソリューションを発見するためにプロトタイプや概念モデルが必要とされる新しいテクノロジーを開発します。",
        title_10: "デザインと仕様",
        subtitle_10:
            "細部にこだわるため、デザインはヒューマンセンタードであり、仕様は詳細レベルでキャプチャされていることを確認するため、余分な労力を払います。",
        title_11: "開発とテスト",
        subtitle_11:
            "グローバルな開発チームは、ハードウェア、ファームウェア、ソフトウェア全般にわたるエンドツーエンドのソリューションを提供できるように構築されています。",
        title_12: "プロダクションとサポート",
        subtitle_12: "グローバルな調達、保守、サポート、DevOpsを含む、開発後のサービスの完全な補完を提供します。",

        title_13: "フルスタックの能力",

        title_14: "IoTソリューション",
        subtitle_14:
            "ウェアラブルガジェット／バックエンドインフラ／IoTアプリケーションおよびソフトウェア／アナログからデジタルへの変換／産業用IoTシステム／データウェアハウジングおよび分析",
        title_15: "組み込みソフトウェア",
        subtitle_15:
            "ファームウェア開発／ミドルウェアとカスタムドライバー／Bluetoothアプリケーション／ヒューマンマシンインターフェース開発／スマートホームシステム／センサーソリューション",
        title_16: "ハードウェア設計",
        subtitle_16: "電子回路設計／PCBシミュレーション／電子システム設計／PCBレイアウト開発／FPGA設計／エンクロージャデザイン",
        title_17: "Webアプリケーション開発",
        subtitle_17:
            "決済ソリューション／インテリジェントボットアプリ／デジタルアセット管理システム／ポータル開発／ソーシャルネットワーク／エンタープライズWebシステムAI／MLアプリケーション開発",
        title_18: "モバイルソリューション",
        subtitle_18:
            "エンタープライズモバイルアプリ／スマートデバイス＆ウェアラブル／デジタル画像処理／マルチメディア配信／Uberのようなアプリ／モバイルメッセンジャー／VoIPアプリ開発／ソーシャルネットワーク",
        title_19: "クラウドソリューション",
        subtitle_19:
            "クラウドアプリケーション開発／IoTクラウドソリューション／クラウドインフラ最適化／クラウド統合／クラウドコンサルティング／クラウドデプロイメントサービス／クラウドへの移行／DevOps",

        title_20: "顧客のレビュー",
        title_21: "Thorsten Stremlau",
        content_21: "レノボ グローバルCTO",
        subtitle_21:
            "tokuuと一緒にいると、プロジェクト管理だけでなく、クライアントのニーズも理解し、私たちのやり方で対応してくれる専門家の手に渡ります。",
        title_22: "Lutwin Schommer",
        content_22: "AGU Baby CEO",
        subtitle_22:
            "彼らの優れたチームワークのおかげで、私たちは目標を達成しました。開発の最初の6か月で、最も重要な問題を修正し、オーディエンスを倍増させ、両方のアプリストアのインストール数を増やしました。",
        title_23: "Jared Schrieber",
        content_23: "Revolution Robotics 社長",
        subtitle_23:
            "はじめてのハードウェア製品を構築し、すべての専門知識を持っていないスタートアップは、tokuuと協力を検討すべきだと思います。なぜなら、彼らは何百ものプロジェクトから経験を持ち込んでいるからです。",

        title_24: "私たちが提供するソリューション",

        title_25: "IoT",
        subtitle_25:
            "消費者向けガジェット、スマートホームヘルスケアソリューション、コンピュータ数値制御、DSPおよびネットワークソリューション。",
        title_26: "ストレージ",
        subtitle_26: "NANDフラッシュメモリ、SSD/eMMC用のファームウェアおよびアルゴリズム、ファームウェア機能の検証。",
        title_27: "産業用オートメーションとロボティクス",
        subtitle_27:
            "リモートプロセス制御、装置監視、生産ラインの自動化のためのソリューション；ファームウェア、MCU、センサー、アルゴリズムによって駆動されるエレクトロニクス。",
        title_28: "AR/VR/MR",
        subtitle_28: "知識共有、従業員のオンボーディング、フィールドサービス管理、没入型を促進するビジネスアプリとゲームアプリ。",
        title_29: "コンテンツ配信と会議",
        subtitle_29:
            "スマートテレビソリューション、ビデオキャプチャおよび処理ソフトウェア、メディアコンテンツ配信システム、ビデオ共有機能を備えたインスタントメッセンジャー、対話型ビデオ会議ソリューション。",
        title_30: "コンピュータビジョンソフトウェア",
        subtitle_30: "物体、動き、パターン認識、物体追跡、ビデオコンテンツ分析のためのソリューション。",
        title_31: "AI/ML",
        subtitle_31: "アナリティクス、製品の推奨、認知コンピューティング、予測分析を提供するソリューション。",
        title_32: "ブロックチェーン",
        subtitle_32:
            "検証可能なトランザクションの保護、トランザクションデータの維持と追跡、トランザクションフローと富の分布の分析のためのソリューション。",

        title_33: "私たちが提供するソリューション",
        title_34: "石油とガス",
        subtitle_34: "多者間のコミュニケーションの自動化、装置監視、健康と安全条件の遵守、データを活用した情報の収集に役立ちます。",
        title_35: "ヘルスケア",
        subtitle_35:
            "診断機器とソフトウェア、遠隔患者モニタリングソリューション、AIベースの医用画像ソリューション、業界固有の基準と規制に準拠したデータ取得および管理システムを開発します。",
        title_36: "家庭用電化製品",
        subtitle_36:
            "パフォーマンス、ペットのアクティビティ、データの視覚化および分析をトラッキングするデバイスとアプリケーションの開発を支援します。",
        title_37: "自動車",
        subtitle_37:
            "HMI/マルチメディアユニット、高度な運転支援システムおよびオブジェクト認識ソフトウェアを構築するためにハードウェア、ファームウェア、埋め込みおよびMLのノウハウを活用します。",
        title_38: "データストレージ",
        subtitle_38: "フラッシュをSSDに組み込み、よりスマートで高速なストレージソリューションを実現する方法を理解しています。",
        title_39: "産業製造",
        subtitle_39:
            "技術プロセスのリモート制御、装置監視、モーション制御、生産ラインの自動化、M2Mおよびサービスプロセスの自動化のためのソリューションを実装します。",
    },

    contact_us: {
        input_1: "名",
        input_2: "姓",
        input_3: "Eメール",
        input_4: "電話番号",
        input_5: "会社名",
        input_6: "探しているサービスの種類：",
        input_7: "メッセージ",

        subtitle_1: "私はtokuuの",
        subtitle_2: "プライバシーとクッキーポリシーに同意します。",

        submit: "送信",
    },
    address_info: {
        title_1: "本社",
        name_1: "アメリカ、ヒューストン",
        address_1: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_1: "+1 888 552-5001",
        phone_1_back: "+1 888 552-5001",

        title_2: "本社",
        name_2: "アメリカ、ヒューストン",
        address_2: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_2: "+1 888 552-5001",
        phone_2_back: "+1 888 552-5001",

        title_3: "本社",
        name_3: "アメリカ、ヒューストン",
        address_3: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_3: "+1 888 552-5001",
        phone_3_back: "+1 888 552-5001",

        title_4: "本社",
        name_4: "アメリカ、ヒューストン",
        address_4: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_4: "+1 888 552-5001",
        phone_4_back: "+1 888 552-5001",
    },

    custom_dev: {
        title_1: "カスタムソフトウェア開発サービス",
        subtitle_1:
            "モバイルコンシューマーアプリ、法人向けウェブソリューション、システムレベルのデスクトップユーティリティ、大規模な法人ITシステムまで、あなたの持っているソフトウェアのアイデアを具現化できます。",

        name_2: "ウェブ",
        content_web_1: "デジタルアセット管理システム",
        content_web_2: "VoIPソリューション",
        content_web_3: "ウェブポータル",
        content_web_4: "決済ソリューション",
        content_web_5: "IoTソリューション",
        content_web_6: "AI/MLウェブアプリ",
        content_web_7: "インテリジェントボットアプリ",
        content_web_8: "ソーシャルネットワーク",
        content_web_9: "ETLソリューション",
        title_2: "機能的でインタラクティブで安全なウェブアプリケーションとシステムでビジネスプロセスを最適化します。",
        subtitle_2:
            "tokuuでは、スタンドアロンのウェブソリューションと、企業が運用上および技術的な課題に効果的に対処できるマルチレベルシステム部品の両方を作成します。",

        name_3: "モバイル",
        content_mobile_1: "法人向けモバイルアプリ",
        content_mobile_2: "ソーシャルネットワーキングアプリ",
        content_mobile_3: "モバイルメッセンジャーアプリ",
        content_mobile_4: "決済統合",
        content_mobile_5: "VoIPアプリ",
        content_mobile_6: "ARアプリ",
        content_mobile_7: "スマートデバイス＆ウェアラブルソリューション",
        content_mobile_8: "GEO-Locationアプリ",
        content_mobile_9: "デジタル画像処理およびカメラソフトウェア",
        content_mobile_10: "マルチメディア配信ソリューション",
        title_3: "すべての業界向けに複雑なクロスプラットフォーム、ネイティブ、または法人向けのモバイルソリューション。",
        subtitle_3:
            "私たちは、あらゆる種類のモバイルカスタムソフトウェア開発ソリューションを設計・開発します。どんなプラットフォーム用の複雑なモバイルアプリ、モバイルSDKおよびユーティリティ、ウェブサービスおよび社内法人システムにスムーズに統合される、リーンなモバイルビジネスソリューションを提供します。",

        name_4: "クラウド",
        content_cloud_1: "クラウドアプリケーション開発",
        content_cloud_2: "IoTクラウドベースのソリューション",
        content_cloud_3: "クラウド最適化",
        content_cloud_4: "クラウド統合",
        content_cloud_5: "クラウドへの移行",
        content_cloud_6: "クラウドコンサルティング",
        content_cloud_7: "DevOpsとクラウド管理",
        content_cloud_8: "クラウドデプロイメント",
        title_4: "ビジネスプロセスを効率化し、ソフトウェアの運用性能を向上させるためのハイエンドクラウドテクノロジー。",
        subtitle_4:
            "私たちはクラウドに関するすべてのことを行います。あらゆる複雑さのスケーラブルなソリューションを構築し、ビジネスプロセスに応じてカスタマイズおよび設定し、クラウドソリューションをデザインおよび構築し、既存のITシステムを移行し、既存のクラウドアプリをサポートおよび維持します。",

        name_5: "デスクトップ",
        content_desktop_1: "ネイティブおよびクロスプラットフォームのデスクトップアプリ",
        content_desktop_2: "システムレベルのデスクトップアプリ",
        content_desktop_3: "マルチメディアアプリ",
        content_desktop_4: "3Dモデリング/CADシステム",
        content_desktop_5: "プラグイン",
        content_desktop_6: "ライブラリおよびSDK",
        title_5: "あなたのビジネスまたは技術的なニーズに合わせた高性能でレスポンシブで生産的なデスクトップソフトウェア。",
        subtitle_5:
            "私たちのデスクトップ開発の専門知識は、ハードウェアまたはOS固有のデスクトップアプリケーション、スタンドアロンまたはウェブ対応アプリケーション、ユーティリティまたはプラグインソリューションなど、幅広い分野にわたります。",
    },

    industry_experience: {
        main_title: "業界経験",
        title_1: "家庭用電化製品",
        subtitle_1:
            "家庭用電化製品に関する当社の専門知識には、モバイル、ウェアラブル、家庭用電化製品、Bluetooth対応デバイス、自動車システム、ストレージプラットフォームなどのカスタムソフトウェア開発ソリューションが含まれます。",
        title_2: "スポーツ",
        subtitle_2:
            "当社のポートフォリオには、スポーツデータのトラッキング、処理、分析、高度な認識パターンを備えたトレーニングアプリ、AR/VR、機械学習スポーツソリューションなど、スポーツに関するカスタムソフトウェアソリューションが含まれています。",
        title_3: "ヘルスケア",
        subtitle_3:
            "当社の専門家チームは、患者の健康診断用ソフトウェアソリューション、健康データの取得および管理用システム、医療ポータル、医療ワークフロー管理用ソリューション、HIPAA準拠のアプリなど、ヘルスケア企業がソフトウェアソリューションを構築するのを支援します。",
        title_4: "石油とガス",
        subtitle_4:
            "AR/VR、ML、アルゴリズムデータ処理および可視化ソリューションを提供することで、石油・ガス企業は操作、データ管理、装置監視プロセスを最適化できます。",
        title_5: "産業製造",
        subtitle_5: "工業用企業が機械制御と生産、リモート装置監視、エネルギー消費の最適化などの操作活動をより良く管理するのを支援します。",
        title_6: "ソフトウェア＆テクノロジー",
        subtitle_6:
            "私たちの信頼性のあるテクノロジーパートナーと協力して、新興テクノロジーと革新的な製品を提供できることを誇りに思っています。センサーデバイス、データストレージソリューション、ML駆動のソフトウェアなど、専任の協力を通じて獲得したいくつかの競争力があります。",

        subtitle_7:
            "ウェアラブルやIoTデバイスからスマートホームや高度なセキュリティシステムまで、最新の埋め込み技術を使用したソリューションでエンドユーザーエクスペリエンスを促進します。tokuuは、ベアメタル、BSP、RTOS、DSP、AOSPなどの技術を活用して消費者向け電子機器を開発する分野で20年以上の経験があります。",
        subtitle_8:
            "tokuuは、スポーツテックプロジェクトの一環として埋め込み開発を提供します。トレーニングを改善し怪我を防ぐためのソリューションを開発し、プロスポーツ、フィットネス、リハビリテーション向けのプロジェクトを含みます。物理的なパフォーマンスをトラッキングおよび分析するために、ウェアラブルを開発します。プロジェクトに応じて、RFID、GPS/GIS、またはBluetoothなどのさまざまな接続ソリューションを装備することがあります。より詳細な分析のために、コンピュータビジョンなどの高度なツールを追加することもあります。",
        subtitle_9:
            "tokuuは、医療機関および医療機器を製造する企業向けに、埋め込みシステムのフルサイクル開発サービスを提供します。医療機器およびウェアラブルセンサーシステムのためのストレージソリューション、ハードウェア、ファームウェア、およびミドルウェアコンポーネントを開発し、健康データの収集と処理を行います。",
        title_10: "自動車",
        subtitle_10:
            "当社のチームは、自動車用の埋め込みシステムのフルサイクル開発を提供します。HMI、システム、ADASコントローラー、およびフリート管理、リモート車両診断、クラウドベースのデータ管理向けのオフボードソフトウェアなど、車載ソリューションを構築します。",
        title_11: "メディア＆エンターテインメント",
        subtitle_11:
            "埋め込みソリューション開発サービスの一環として、メディアプレーヤー、メディア処理およびストリーミングツール、コンテンツの圧縮と保護用ソフトウェア、メディアストレージソリューションなどを製作します。",
        title_12: "産業製造",
        subtitle_12:
            "埋め込みソフトウェア開発サービスを提供することで、ビジネスの成功を支援します。資産トラッキングと電力制御を向上させることで、日常業務を改善できます。より革新的なシナリオを想定する場合、tokuuは課題に取り組みます。工場の床でのワークフローを自動化し、作業時の安全性を向上させます。",
    },

    time_line: {
        main_title: "私たちのアプローチ：RFPからサポートまで",
        title_1: "ディスカバリー",
        subtitle_1: "あなたのアイデアと要件を収集し、それらをソリューションの概念、目標、およびビジネスの新しい機会に変換します。",
        title_2: "アセスメント",
        subtitle_2:
            "あなたのドメイン、ビジネスの優先事項、プロジェクトのスコープを調査し、関連する技術、技術的な制約、実装の実用性と照らし合わせて評価します。",
        title_3: "プランニング",
        subtitle_3:
            "システムを設計します：アーキテクチャ、モジュール、インターフェース、およびデータを定義し、チームを割り当て、開発ライフサイクルと方法論を決定します。",
        title_4: "実装",
        subtitle_4: "プロジェクトの成果物を開発および完了します。要件と時間枠に応じて、確立されたアプローチと方法論の範囲を使用します。",
        title_5: "開発アプローチと方法論",
        subtitle_5_1: "反復的またはアジャイルアプローチ - スクラムとカンバンの方法論。",
        subtitle_5_2: "ユースケース駆動、アーキテクチャ中心、反復的および増分アプローチ - RUPの方法論。",
        subtitle_5_3: "プロジェクト活動を直線的な連続フェーズに分解 - ウォーターフォールの方法論。",
        title_6: "デリバリー",
        subtitle_6: "適切な技術スタックを使用して、すべてのソリューションコンポーネントを開発し、デプロイメントを安定化させます。",
        title_7: "サポート",
        subtitle_7: "システムが完全に機能し、期待どおりに常に機能し、必要に応じてスケーリングできるようにします。",
    },
    time_line_2: {
        main_title: "私たちの作業方法",
        title_1: "ディスカバリー",
        subtitle_1:
            "埋め込みシステムプロジェクトの要件を収集し、プロジェクトリスクを軽減するために初期段階で包括的な調査と分析を実施します。コミットする前に、見積もりと計画を通じてすべての情報を把握できます。",
        title_2: "ラフシステムデザイン",
        subtitle_2:
            "オペレーティングシステム、サードパーティのライブラリとフレームワークの選択をサポートし、ハードウェアとソフトウェアコンポーネントを決定およびパーティションに分けます。埋め込み開発チームに渡す前に、最終的な設計図を確認できます。",
        title_3: "開発と実装",
        subtitle_3_1: "当社の埋め込みシステム開発サービスには以下が含まれます：",
        subtitle_3_2: "カスタムハードウェアの開発およびデバイスドライバーの開発、およびボードの起動",
        subtitle_3_3: "カスタムハードウェアのセキュアブートローダーの開発",
        subtitle_3_4: "RTOS、Linux、およびその他のオペレーティングシステムの移植を含むBSPの開発",
        title_4: "次に、以下を実装します：",
        subtitle_4_1: "サードパーティのソフトウェアパッケージの移植またはミドルウェアおよびプロトコルスタック",
        subtitle_4_2: "ソフトウェア開発ツールのセットアップと設定",
        subtitle_4_3: "ファームウェアの設計、コーディング、およびテスト",
        subtitle_4_4: "設計とソフトウェアアーキテクチャのレビューとテスト",
        subtitle_4_5: "継続的な統合、展開、設定、および埋め込みシステム管理。",
        title_5: "統合",
        subtitle_5:
            "すべての埋め込みシステムコンポーネントを結びつけ、すべてのプロジェクト仕様に準拠し、全体として動作することを確認するために、埋め込みシステム全体をテストします。",
        title_6: "デリバリー",
        subtitle_6:
            "当社の埋め込み開発の一環として包括的なQAおよびデバッグを実施します。製品とシステムのテストプラクティスにより、コードがクリーンで読みやすく、適切にコメントが付いていることを確認します。その後、最終的に配信の準備ができます。",
        title_7: "メンテナンスとサポート",
        subtitle_7:
            "システムのパフォーマンスを24/7で監視および管理します。バグが発生した場合、すぐに修正し、リクエストに応じて新しい機能を追加できます。これは埋め込みソフトウェア開発サービスの重要な部分です。",
    },
    tech_stack: {
        main_title: "テックスタックと検証済みの専門知識",
        title_1: "WEB",
        subtitle_1:
            "当社のWeb開発チームは、最新かつ信頼性の高いテクノロジーとフレームワークを活用して、製品の堅牢で中断のないパフォーマンスを保証します。",
        title_2: "MOBILE",
        subtitle_2:
            "当社のモバイルアプリ開発の専門知識は、複数のプラットフォームとテクノロジーにわたり、複雑さの異なるモバイルソリューションを作成し、カスタムモバイルアプリをアップグレードしたり、他のシステムと統合したりできるようにします。",
        title_3: "DESKTOP",
        subtitle_3:
            "カスタム開発サービスの20年以上の経験を持つtokuuの専門チームは、すべてのプロジェクトに最適なテクノロジーを選択する方法を知っています。",
        title_4: "CLOUD",
        subtitle_4:
            "当社のクラウドアプリ開発の専門知識は、すべての主要なプラットフォームをカバーしています。業界のベストプラクティスに従い、アジャイルで柔軟でスケーラブルなクラウドソリューションを構築します。",
        title_5: "検証済みの専門分野",
        subtitle_5:
            "私たちの経験は、数多くの成功したプロジェクト、証明書、および提携によって証明されています。アプリのアーキテクチャの草稿から最終デプロイメントまで、すべてのプロジェクトフェーズで最高品質のカスタムソフトウェア開発サービスを提供します。",
        subtitle_6: "ファームウェア",
        subtitle_7: "ミドルウェア",
        subtitle_8: "ソフトウェア",
        subtitle_9: "接続性",
        subtitle_10: "プロキシミティ",
    },
    why_tokuu: {
        main_title: "なぜTukuu？",
        title_1: "数十年の経験",
        subtitle_1:
            "カスタムソフトウェア開発サービスで20年以上の経験。アーキテクト、開発者、ビジネスアナリストの75％がクロスドメイン領域で6〜12年の実務経験を持っています。",
        title_2: "柔軟性と透明性",
        subtitle_2: "クライアントには、ビジネスニーズとプロジェクト目標に合わせたさまざまなエンゲージメントモデルを提供しています。",
        title_3: "成熟したプロセス",
        subtitle_3:
            "アジャイル（SCRUM）やRADなどの実証済みの方法論に従って、複雑な長期プロジェクトを推進し、RUPなどの「クラシックな」ソフトウェア開発プロセスと一致させることで、リスクを軽減します。",
        title_4: "品質管理",
        subtitle_4: "品質保証部門は、開発プロセスの初期段階から参加し、包括的なテストカバレッジと正確な品質評価を確保します。",
        title_5: "数十年の経験",
        subtitle_5:
            "私たちは、ハードウェア、ファームウェア、埋め込みアプリケーションの開発に20年以上の実務経験を持つプロのチームです。さまざまな業界に関する幅広い実務知識もあります。",
        title_6: "エンドツーエンドの開発",
        subtitle_6:
            "tokuuは、初期の分析、要件管理、ハードウェアおよびPCB設計、エンクロージャ設計、ラピッドプロトタイピングから実装、持続的なサポート、および継続的な改善まで、フルサイクルの開発を提供します。",
        title_7: "透明性とコミュニケーション",
        subtitle_7:
            "プロジェクトのプロセスと進捗に対する完全な透明性を保証します。アジャイル、カンバン、RUP、ウォーターフォールのエンゲージメントを使用している場合でも、製品マネージャーや開発リーダーと非常に密接にコミュニケーションを取りながら、詳細な文書、定期的な報告、プロジェクト管理ソフトウェアツールへのアクセスが可能です。",
        title_8: "品質管理",
        subtitle_8: "品質保証部門は、開発プロセスの初期段階から参加し、包括的なテストカバレッジと正確な品質評価を確保します。",
    },

    how_can_we_help: {
        main_title: "どのようにお手伝いできますか？",
        title_1: "リリース後の保守とサポート",
        subtitle_1: `市場で競争力を維持し、収益性を確保するために、有用で便利なソフトウェアを提供することが顧客にとってどれだけ重要かを理解しています。
        <br/> <br/>
        これらの課題に対処するために、機能の不整合を修正し、脆弱性を修正し、システムのパフォーマンスを向上させることを目的とした特別な保守とサポートサービスを提供しています。検討されたQA戦略とリリース後の保守とサポートは、新しいソフトウェアの導入に関連するリスクを軽減するのに役立ちます。
        <br/> <br/>
        将来のソリューションの拡張ニーズを評価し、関連する計画を提案します。ユーザーフィードバックを組み込み、オペレーティングシステムのアップグレードによる障害や故障からソリューションを保護し、アプリの機能を強化します。
        `,
        title_2: "最適な品質",
        subtitle_2: `当社のカスタムソフトウェア開発サービスを利用すると、以下の3つの主要な基準を満たす最高品質のソリューションを提供します。当社のソフトウェアは次のようにする必要があります。
        <br/> <br/>
        - それが作成されたビジネスニーズに対処する
        <br/> <br/>
        - エンジニアリングの標準と規約に準拠して設計されていること
        <br/> <br/>
        - バグなしで設計に従って動作すること
        <br/> <br/>
        すべての3つの基準で最適な品質を実現するために、ソフトウェア開発プロセス全体で持続的なQA活動を実施しています。当社のQAチームは、品質評価プロセスに対する公平な視点と高度な透明性をもたらす別のグループとして機能します。
        `,

        title_3: "R&Dイニシアティブ",
        subtitle_3: `R&Dに焦点を当て、次世代の業界のトレンドの幅広い能力と適用分野を探索し、お客様の成長の可能性のある領域を特定します。
        <br/> <br/>
        当社の埋め込みソフトウェアエンジニアは、埋め込みソフトウェア開発サービス内でR&Dプロジェクトも提供しています。以下は当社の作業の例です。カスタムハードウェアプラットフォームをAndroidに移植する方法を試してテストしました。目的は、プロプライエタリデバイスをAndroid OSの上で実行される製品と連携させることでした。もう1つの例はゲーム開発です。エンジニアは、ゲーマーにより没入感のある体験を提供することを目指しました。これを実現するために、モーションコントローラやその他の次元技術を統合しました。
        `,

        title_4: "QAラボ",
        subtitle_4: `埋め込みシステムの品質保証も、クライアント向けの独立したサービスとして提供しています。さまざまな埋め込みソリューションのテストとデバッグを提供します。
        <br/> <br/>
        効率的で強固で完璧なソフトウェアを作成するために、当社は2つの専門のQAラボ、Firmware＆Embedded QA LabとMobile QA Labを設立しました。これらはユニット、統合、システム、受け入れテスト中に適用されます。
        `,

        title_5: "エンタープライズバックエンドのための.NETおよびJava",
        subtitle_5: `信頼性のある.NETおよびJavaをソリューションのバックボーンとして使用します。
        <br/> <br/>
        これらの信頼性のあるテクノロジーを使用して、チームはスケーラブルな分散システムを作成し、ビジネスの主要な目標に合致し、効率性を確保する信頼性のあるデータ集中型アプリケーションを設計します。
        `,

        title_6: "Mean Stack & Node.Js Development for SPA",
        subtitle_6: `ノンSQLデータスキーマとNode.js開発のスキルを活用して、イベント駆動型のWebバックエンドとリアルタイムコミュニケーションアプリケーションを構築します。
        <br/> <br/>
        これにより、多くのロジックを高度に対話型のリッチなJavaScriptクライアントにシフトし、非同期入出力を介した柔軟性を確保します。
        `,

        title_7: "リリース後のソフトウェア保守とサポート",
        subtitle_7: `tokuuは、ソリューションが安全で障害のない動作と高速なパフォーマンスを示すようにするためのアプリケーション保守およびサポートサービスを提供します。私たちのチームは、ソフトウェア開発プロジェクトのサポートと保守を促進し、持続的な改善とUXの一貫性を可能にしつつ、企業の俊敏性を高めるお手伝いをします。
        `,

        title_8: "",
        subtitle_8: `通常、サポートの優先順位付け、文書化、ルーティングに基づいたHelp Deskシステムを使用しています。ヘルプデスクを使用することで、アプリケーションの保守とサポートチームがユーザーからの問い合わせに迅速に対応し、各タスクの進行状況を確認し、コメントの交換を行い、各チケットのアクション履歴を追跡できます。
        `,

        title_9: "DevOpsによる具体的な結果",
        subtitle_9: `DevOpsコンサルティングおよび発想から展開まで、開発チームと運用チームが一緒にソフトウェアソリューションに取り組み、新しいスキルを習得し、適切なツールを使用し、DevOpsのベストプラクティスに従ってエンドツーエンドのソフトウェアデリバリープロセスを自動化します。これにより、次のことが可能になります。
        `,

        title_10: "",
        subtitle_10: `製品を市場に迅速に導入する
        <br/> <br/>
        全体のプロジェクトコストを削減する
        <br/> <br/>
        顧客が必要とするタイミングで正しい機能を提供する
        <br/> <br/>
        安全でスムーズに動作する製品を提供する
        <br/> <br/>
        顧客の期待に応える
        `,

        title_11: "デジタルトランスフォーメーションの文脈でのIoT",
        subtitle_11: `私たちは、企業内の明確に識別されたプロセスを完全または部分的に自動化する堅牢なサイバーフィジカルシステムを開発します。これらのシステムは、デジタルトランスフォーメーションを経験する企業が従業員の時間を確保し、顧客へのより良いサービス提供に焦点を当てるのに役立ちます。
        `,

        title_12: "リスクのないイノベーション",
        subtitle_12: `tokuuでは、知識密度の高いプロジェクトの前にコンセプトの証明/ディスカバリフェーズが先行しています。これにより、IoTのアイデアを実装するための技術の障壁を特定し、プロジェクトのロードマップを作成し、現実的な目標を設定できます。
        `,

        title_13: "リリース後の保守とサポ",
        subtitle_13: `市場で競争力を維持し、収益性を確保するために、有用で便利なソフトウェアを提供することが顧客にとってどれだけ重要かを理解しています。
        <br/> <br/>
        これらの課題に対処するために、機能の不整合を修正し、脆弱性を修正し、システムのパフォーマンスを向上させることを目的とした特別な保守とサポートサービスを提供しています。検討されたQA戦略とリリース後の保守とサポートは、新しいソフトウェアの導入に関連するリスクを軽減するのに役立ちます。
        `,

        title_14: "コントロール可能なタイムライン",
        subtitle_14: ``,

        title_15: "フォトアプリとデジタルイメージング",
        subtitle_15: `オブジェクト分類、セグメンテーション、その他のマシンビジョンソリューションに加えて、tokuuは顔認識、画像処理、エッジ検出に基づく高度なフィルターやデジタル写真編集機能を備えたAIアプリケーションの豊富なポートフォリオを持っています。コンピュータービジョンソリューションを使用すると、ユーザーは写真を修正し、背景を編集し、ステッカーやマスクを追加できます。
        `,

        title_16: "さまざまなカメラタイプ用のカスタムソフトウェア",
        subtitle_16: `tokuuのマシンビジョンシステムには、ドローンマウント型からIPおよび固定型までのすべてのタイプのカメラ用の管理ソフトウェアが含まれています。HDビデオと画像の記録と分析のハードウェア機能を拡張し、多くの場合、過酷な条件とアクセスできない場所で24/7のビデオ監視サービスを提供します。
        `,

        title_17: "データベースアプローチ",
        subtitle_17: `革新的なソリューションを開発している場合、アイデアの実現可能性を評価するためにDiscovery Phaseを開始します。これにより、適切なテクノロジースタックとBOMコンポーネントを選択し、将来のスケーラビリティと高性能のためのシステムアーキテクチャを設計できます。
        `,

        title_18: "イノベーションを通じた成長",
        subtitle_18: `tokuuは、ビジネスがIoTを活用し、新しい収益機会を探るための消費者向けエレクトロニクスソリューションを設計します。IoTソリューションを使用すると、ブランドは購入時点を超えてサービスを拡張し、顧客体験をカスタマイズできます。
        `,

        title_19: "USBおよび信頼性のあるフラッシュ",
        subtitle_19: `tokuuは、USB規格に基づいて構築された大容量記憶デバイスに対する実務経験を持っており（Leef、Sandiskなどのプロジェクト例を参照）、信頼性と整合性に関してコードとデータを保護するために信頼性のある実行環境（TEE）を使用する経験も豊富です。
        <br/> <br/>
        `,

        title_20: "SSDデータストレージ",
        subtitle_20: `機械部品を持たないため、組み込み回路アセンブリをメモリとして使用する固体状のドライブは、機械部品を持たないため、問題が発生することがあります。これらの問題とその緩和技術について十分に理解しています。
        `,
    },

    ui_ux_design: {
        main_title: "UI/UXデザインサービス",
        main_subtitle:
            "tokuuは、UXリサーチとエンドユーザーデータの収集からプロトタイプの構築、使いやすさの監査まで、UI/UXデザインサービス全般を提供しています。私たちは、魅力的な製品を構築し、ビジネスの成果に変換される意味のあるユーザーエクスペリエンスを作成するのに役立ちます。",

        title_1: "私たちのUI/UXサービスカバー",
        title_2: "エンタープライズアプリケーション",
        subtitle_2_1:
            "私たちはエンタープライズレベルのシステム用のユーザーインターフェースを設計しています：SaaSアプリ、複雑なWebソリューション、リッチインターネットアプリケーション、クロスプラットフォームのデスクトップソフトウェアなど。",
        subtitle_2_2:
            "私たちは、ビッグデータを理解しやすくし、魅力的なユーザーエクスペリエンスを提供する構造に整理する情報アーキテクチャを構築します。",
        title_3: "埋め込みデバイス",
        subtitle_3_1:
            "tokuuは、IoTデバイス用のユーザーインターフェースを作成します：スマートウォッチやスマートグラス、フィットネストラッカー、GPSペットカラーなど、スマートホームやコネクテッドカー用の完全なUI/UXソリューションも提供します。",
        subtitle_3_2:
            "私たちは徹底的なUXリサーチを実施し、クロスデバイスコミュニケーションをスムーズにし、すべてのデバイスでシームレスなユーザーエクスペリエンスを保証するUI/UXデザインを構築します。画面サイズ、解像度、フォームファクタに関係なく、すべてのデバイスでシームレスなユーザーエクスペリエンスを保証します。",
        title_4: "Webソリューション",
        subtitle_4_1:
            "私たちはWebソリューション用のユーザーエクスペリエンスを提供します：コンテンツ管理システム、Webポータル、eコマースウェブサイト、インテリジェントボットアプリなど。",
        subtitle_4_2:
            "ワイヤーフレームからユーザーフロー、プロトタイプまで、UI/UXチームは、Webアプリのユーザーの特定のニーズに合ったインターフェースをカスタマイズするお手伝いをします。",
        title_5: "AR/VRアプリ",
        subtitle_5_1:
            "私たちは、VRおよびARアプリ用の概念的なユーザーフロー、人間工学、インタラクションモデルに基づいた特定の環境とインターフェースを作成します。",
        title_6: "ウェブサイト",
        subtitle_6_1:
            "tokuuは、ランディングページとウェブサイト用の直感的で高機能なUI/UXデザインを作成し、各デザインコンポーネントがすべての画面サイズで正しく機能することを確認するUI/UX監査を実施するお手伝いができます。",
        title_7: "モバイルアプリ",
        subtitle_7_1:
            "私たちはiOS、Android、クロスプラットフォームのモバイルアプリ向けのグラフィックデザインとインターフェースを作成し、すべてのデバイスで動作し、すべての画面サイズに適合するようにします。それ以上に、顧客のニーズを理解し、モバイルアプリのユーザーに素晴らしいエクスペリエンスを提供するために焦点を当てます。UX/UIチームは、すべてのデザインがフォームと機能で一貫性があり、使用が簡単であることを確認します。",

        title_8: "私たちのアプローチ：ニーズの理解からエクスペリエンスの提供",
        title_9: "あなたが得るもの",

        title_10: "高いコンバージョン率",
        subtitle_10:
            "グラフィクスとレイアウト、メッセージとインタラクションの使用を通じて、高いコンバージョン率をもたらすユーザーエクスペリエンスを作成するお手伝いをします。ユーザーリサーチからCTAの作成まで、UI/UXデザインチームはプロジェクトのROIを向上させるお手伝いをします。",
        title_11: "シームレスな使いやすさ",
        subtitle_11:
            "エンドユーザーのニーズとその製品のジャーニーを効率的かつスムーズなエクスペリエンスに変える方法を知っています。シンプルで洗練されたインターフェースを設計し、視覚的に魅力的で統一的で直感的に使用できるものを提供します。",
        title_12: "透明性と責任",
        subtitle_12:
            "私たちのすべてのデザインプロセスは最大限に透明でアジャイルです。定量的および定性的な方法を使用して、すべてのプロジェクト活動が確立された目標に合致するようにし、より良いユーザーエクスペリエンスを提供することに対して自己責任を負います。",
        title_13: "質問はありますか？お話ししましょう",
    },

    cybersecurity: {
        main_title: "サイバーセキュリティサービス",
        main_subtitle:
            "tokuuは、デジタルトランスフォーメーションを経験する企業を支援するための分析、テスト、コンサルティングの実践を提供しています。既存のコードベースを見直し、バグを修正し、ハッカー攻撃を防ぐための新しいセキュリティ層を導入し、従業員にサイバーセキュリティについて教育します。",
        name_2: "評価",
        content_Assessment_1: "コードおよびドキュメンテーションのレビュー",
        content_Assessment_2: "コンプライアンス評価（HIPAA、HL7、FDA、PCI DSS、GDPR、FedRAMP）",
        content_Assessment_3: "サイバーセキュリティコンサルティング",
        content_Assessment_4: "ファイアウォール構成の分析",
        content_Assessment_5: "従業員の企業のセキュリティポリシーとベストプラクティスへの遵守の評価",
        title_2: "サイバーセキュリティ評価",
        subtitle_2:
            "ITインフラストラクチャのコンポーネントを調査し、システムがセキュリティの脆弱性を持たないことを確認できるようにします。このプロセスには以下が含まれます。",
        name_3: "テスト",
        content_Testing_1: "脆弱性スキャン（クロスサイトスクリプティング、SQLインジェクション、リモートコード実行、SSL/TLSなど）",
        content_Testing_2: "DoS/DDoS攻撃のエミュレーション",
        content_Testing_3: "侵入テスト",
        content_Testing_4: "ユーザー認証とアクセス制御",
        title_3: "サイバーセキュリティテスト",
        subtitle_3:
            "カスタムハードウェア、ソースコード、ソフトウェアアーキテクチャのセキュリティの脆弱性を検出するため、手動および自動化テストを実行します。詳細については、次の通りです。",
        name_4: "改修",
        content_Overhaul_1: "開発およびQAリソースの効果的な管理",
        content_Overhaul_2: "ファイアウォールのセキュリティ修正",
        content_Overhaul_3: "ソフトウェアデリバリーパイプラインの連続テストと自動化",
        content_Overhaul_4: "企業のサイバーセキュリティ戦略の開発",
        title_4: "サイバーセキュリティ改修",
        subtitle_4: "ビジネスのITソリューションのセキュリティの脆弱性を評価した後、改善計画を策定します。",
        name_5: "実装",
        content_Implementation_1: "侵入検出および防止（IDS/IPS）システムの実装",
        content_Implementation_2: "エンドツーエンドのデータ暗号化とウイルス対策ソフトウェアの展開",
        content_Implementation_3: "無人セキュリティチェックアップとパッチの有効化",
        content_Implementation_4: "カスタムハードウェアセキュリティモジュール（HSM）およびトークンのインストール",
        title_5: "エンドプロダクトおよびネットワークセキュリティの実装",
        subtitle_5: "ITインフラストラクチャ内のソリューションとデータを保護するためにツールとプラクティスの組み合わせを使用します。",
        title_6: "tokuuをサイバーセキュリティサービスプロバイダーとして際立たせる要因",
        title_7: "セキュリティに焦点を当てる",
        subtitle_7:
            "私たちはすべてのテクノロジーソリューションにカスタムセキュリティ設計を提供し、オープンソースソフトウェアおよびハードウェアコンポーネントの徹底的なレビューを行い、製品化時に脆弱性が発生しないようにDevOpsのプラクティスを採用しています。",
        title_8: "IoT用の実装",
        subtitle_8:
            "連携製品を専門とする企業として、IoTコンテキストでのサイバーセキュリティを理解しています。tokuuはIoT開発のベストプラクティスに従い、ボットネットおよびランサムウェア攻撃を防ぎ、機密データを保護します。",
        title_9: "使いやすさを優先",
        subtitle_9:
            "企業のステークホルダーと協力してセキュリティをビジネス目標に合わせ、ソフトウェアとハードウェアの機能を最適化し、特にストレス状況下でのセキュリティとパフォーマンス要件を満たすようにします。",
        subtitle_10:
            "サイバーセキュリティサービスプロバイダーとして、tokuuは企業がハッカー攻撃に対抗するのを支援するために新しいセキュリティ層を導入します。業界固有のサイバーセキュリティソリューションとサービスのポートフォリオには、次のものが含まれます。",
        title_11: "ヘルスケアとライフサイエンス",
        subtitle_11:
            "HIPAAに準拠したEHRソリューション、患者ポータル、mHealthアプリ、およびトラッカー。生体認証（音声、虹彩、指紋、顔認識）で強化されたセキュリティ。",
        title_12: "産業製造",
        subtitle_12:
            "サイバーセキュリティ監査。ネットワークセキュリティソリューションと産業用ファイアウォールシステム。オンプレミスおよびクラウドベースのデータストレージソリューションのセキュリティ。",
        title_13: "コンシューマエレクトロニクス",
        subtitle_13:
            "データの暗号化と接続デバイス（ウェアラブル、スマートホームアプライアンス）間の安全な通信。固有のデバイスパスワード。OTAファームウェアの更新。",
    },

    embedded_system: {
        main_title: "組み込みシステム開発",
        main_subtitle:
            "tokuuは、ハードウェアとユーザーインターフェースを強力なソフトウェア、接続性、近接技術と組み合わせた複雑な組み込みシステムを開発します。製品戦略、設計、システム開発から組み込み統合、テスト、ユーザーエクスペリエンスの実装まで、組み込みシステムの開発サイクル全体をカバーしています。",

        name_2: "ファームウェア",
        content_Firmware_1: "センサー向けのベアメタルファームウェア",
        content_Firmware_2: "ボードサポートパッケージ（BSP）ソリューション",
        content_Firmware_3: "デジタルシグナルプロセッシング（DSP）開発",
        content_Firmware_4: "Androidオープンソースプロジェクト（AOSP）",
        content_Firmware_5: "Linuxカーネル開発",
        title_2: "ベアメタルシステム、DSPソリューション、RTOS、ボードブリングアップ、低電力設計、ブートローダーなどの構築。",
        subtitle_2:
            "IoTシステム内のデバイスを接続し、デバイスと機器をリモートで制御したり、センサーデータを収集および処理したりする組み込みソリューションをお探しの場合、当社の組み込みエンジニアがお手伝いいたします。私たちは以下をカバーしています:",

        name_3: "ミドルウェア",
        content_Middleware_1: "デバイスドライバー",
        content_Middleware_2: "SDK",
        content_Middleware_3: "AIベースのミドルウェア",
        content_Middleware_4: "コード最適化サービス",
        content_Middleware_5: "サードパーティーソリューションの移植",
        content_Middleware_6: "プロジェクトレスキューサービス",
        title_3: "IoTシステムの部品を結びつけ、一つのシステムとして機能させます。レガシーコンポーネントのギャップを埋めます。",
        subtitle_3:
            "異なるベンダーからのハードウェア、サードパーティーサービス、異なるオペレーティングシステムなど、異なる要素を一つのシステムに接続する必要がある場合、組み込み開発が必要です。いずれの場合も、それらを連携させる方法を知っています。相互作用を確保するために、以下を使用しています:",

        name_4: "アプリ",
        content_Apps_1: "IoTアプリ",
        content_Apps_2: "メディアストリーミングソフトウェア",
        content_Apps_3: "クラウドゲートウェイ",
        content_Apps_4: "ヒューマンマシンインターフェース（HMI）",
        content_Apps_5: "スマートセンサー",
        title_4: "接続されたデバイスとIoTシステムを管理し、収集したセンサーデータを処理し、具体的な洞察を得ます。",
        subtitle_4:
            "当社の組み込みシステム開発サービスの一環として、消費者および産業用IoTソリューションのパフォーマンスを最適化し、ワークフローを効率化し、データのセキュリティを強化し、高度なレポートメカニズムを取得するためのお手伝いをします。私たちは以下を開発します:",

        name_5: "HMI",
        content_HMIs_1: "ヘルスケアHMIシステム",
        content_HMIs_2: "装置制御およびキャリブレーション向けのHMIソリューション",
        content_HMIs_3: "産業用ロボティクスシステム",
        content_HMIs_4: "自動車用インフォテインメントソリューション",
        content_HMIs_5: "デジタルシグナルプロセッシング向けのソフトウェアソリューション",
        content_HMIs_6: "ナビゲーションアプリの開発と統合",
        title_5:
            "HMI開発のすべての側面を構築します：ハードウェアとソフトウェア、グラフィカルユーザーインターフェース、ウェブとクラウドインフラストラクチャ。",
        subtitle_5:
            "組み込みソフトウェア開発会社として、私たちはどの種類の複雑さでもHMIソリューションを開発します。基本的な製品、機械制御パネルやデータ可視化ソフトウェアなどを開発します。また、クライアントの要求に応じて、マルチメディア、ネットワーキング機能、その他の高度な機能を設定した複雑なエコシステムも開発します。私たちのチームは以下で作業します:",

        name_6: "接続性",
        content_Connectivity_1: "BluetoothおよびBLEアプリ開発",
        content_Connectivity_2: "Wi-Fi、WAN、LPWAN、またはセルラーネットワーク接続を備えた個人デバイス",
        content_Connectivity_3: "スマートホーム",
        content_Connectivity_4: "産業用接続ソリューション",
        title_6:
            "Bluetooth開発、Wi-Fi、IoT WAN、LPWAN、およびセルラーソリューションを活用したセキュアで低電力かつ安定した動作を実現します。",
        subtitle_6:
            "信頼性の高い接続を介してセキュアなデータ交換を可能にするハードウェアコンポーネントとアプリを設計および開発します。これはIoTシステムと接続デバイスの両方に適用されます。組み込みソフトウェア開発サービスの一環として、以下で作業します:",

        name_7: "近接",
        content_Proximity_1: "カスタムファームウェアとドライバー",
        content_Proximity_2: "近接ハードウェアの設計：ビーコン、NFCリーダー、RFIDタグ",
        content_Proximity_3: "iBeacon、ビーコン、およびその他のセンシングテクノロジを活用するアプリ開発",
        content_Proximity_4: "ソリューションのオーケストレーションのためのウェブシステム",
        title_7: "RFID、NFC、ビーコン、赤外線、およびレーザーテクノロジによって駆動されるデバイスとアプリに接続します。",
        subtitle_7:
            "当社の組み込み開発サービスは、近接技術の利点も提供します。これにより、マイクロロケーションやコンテキストの詳細なデータなど、さまざまなタイプのデータを収集できます。近接データは、在庫管理、顧客サービス、店内マーケティング、スタッフ管理の最適化に役立つ場合があります。以下を行います:",
    },

    frontend_development: {
        main_title: "フロントエンド開発サービス",
        main_subtitle:
            "当社のフロントエンドアプリケーション開発チームは、ITシステムと直感的な対話を設計し、複雑なアプリ（ウェブ、デスクトップ、モバイル、IoTダッシュボード、HMI）のUIとビジネスロジックをバランスさせます。",

        title_1: "フロントエンドソリューション",

        title_2: "ウェブアプリインターフェース",
        title_3: "シングルページアプリ",
        title_4: "デスクトップアプリUI",
        title_5: "IoTダッシュボード",
        title_6: "モバイルアプリインターフェース",
        title_7: "eコマーステーマ",
        title_8: "HMI",

        subtitle_2:
            "私たちは、サーバーで処理されたデータを視覚化し、さまざまなエンドポイントデバイスにコンテンツを活用する非連携型フロントエンドアーキテクチャを持つウェブアプリとウェブサイトを構築しています。",
        subtitle_3:
            "シングルページアプリとプログレッシブウェブアプリに精通しており、メッセンジャーやチャットボット、ソーシャルネットワーキングウェブサイト、オンラインマップなどを開発しています。",
        subtitle_4:
            "デスクトップアプリの直感的なインターフェースを設計するために、OSとハードウェアアーキテクチャのコアに詳細に潜り込んでいます。",
        subtitle_5:
            "センサーデータをリアルタイムで視覚化するためにJSフレームワークとクラウド対応ツールを使用し、PCからIoTおよびIIoTソリューションを管理するのに役立ちます。",
        subtitle_6:
            "Material Designガイドラインを満たすクロスプラットフォームおよびハイブリッドアプリを作成し、モバイルデバイスとWebブラウザで的確なユーザーエクスペリエンスを提供します。",
        subtitle_7:
            "オンライン小売業者がデバイスを行き来する顧客を引き付ける柔軟なeコマースウェブサイト（マーケットプレイス、クラウドファンディング、オークション）のフロントエンドを設計します。",
        subtitle_8:
            "スマート機器や消費者向けデバイスを制御するために、音声インターフェース、ARマニュアル、デバイスからブラウザへのストリーミングアプリ、IVIシステム、およびWebアプリを開発します。",

        title_9: "フロントエンド開発へのアプローチ",
        title_10: "ユーザージャーニーマッピング",
        title_11: "包括的デザイン",
        title_12: "パフォーマンスに焦点",
        title_13: "",

        subtitle_10:
            "フロントエンド開発プロジェクトに取り組む前に、ユーザーのニーズを調査し、ヒートマップとクリックマップを研究して行動を予測し、ユースケースを開発します。",
        subtitle_11:
            "異なるナビゲーションプリファレンス、ブラウジングパターン、アクセシビリティのニーズを持つユーザーがアプリ内を効率的に移動し、アクションを迅速に完了できるように、コンテンツを明確に表示します。",
        subtitle_12:
            "フロントエンド開発チームは、UX/UIデザイナーとバックエンドエキスパートと協力して、トラフィックの増加に対応し、スケーラブルなソフトウェアアーキテクチャを設計します。",
        subtitle_13: "",

        title_14: "当社の技術スタック",
        title_15: "ウェブアプリケーションインターフェース",
        title_16: "SPA",
        title_17: "デスクトップアプリ",
        title_18: "モバイルアプリ",
        title_19: "クラウドネイティブデータビジュアライゼーション",
        title_20: "インタラクティブデータプレゼンテーション",
        title_21: "機械学習",
        title_22: "サードパーティAPI",
    },

    backend_development: {
        main_title: "バックエンド開発サービス",
        main_subtitle:
            "私たちは、成長するビジネスニーズに対応するモバイル、Web、デスクトップ、IoTシステム向けに拡張可能なオンプレミスおよびクラウドベースのバックエンドソリューションを構築します。",

        title_1: "カバーする領域",

        title_2: "バックエンドアプリケーション開発",
        title_3: "クラウドインフラストラクチャ/移行/ソリューション",
        title_4: "技術バックエンド監査",
        title_5: "モバイルアプリのバックエンド開発",
        title_6: "IoTバックエンド",

        subtitle_2:
            "私たちのバックエンドウェブ開発サービスには、企業システム、複雑なウェブアプリケーション、IoTインフラストラクチャ、VoIPソリューション、ウェブポータルなどのスケーラブルで障害耐性のあるバックエンドの構築が含まれています。私たちはまた、レガシーシステムをサードパーティのサービスやアプリと統合するお手伝いもできます。",
        subtitle_3:
            "最高のクラウドプラットフォームで自然にスケーリングできる弾力的な計算能力を備えたバックエンドソリューションを構築します。また、レガシーバックエンドシステムをパブリック、プライベート、またはハイブリッドクラウドに移行するお手伝いもいたします。",
        subtitle_4:
            "既存のアプリケーションを最適化するか、レガシーシステムを新しい環境に移行するお手伝いをするために、徹底的なバックエンド監査を実施し、ボトルネックを特定し、インフラストラクチャ、アーキテクチャ、コードベース、データスキーマを刷新します。",
        subtitle_5:
            "私たちは、企業向けモバイルアプリ、スマートデバイス、マルチメディアソリューション、ソーシャルネットワーク向けのバックエンドを作成します。私たちのバックエンド開発チームはアプリのサーバーとビジネスロジックを処理し、すべてのデータがプラットフォーム間で同期されることを確認します。私たちのバックエンドは高速なパフォーマンスと安全なデータストレージを保証します。必要に応じてスケールし、サードパーティのサービスと統合します。",
        subtitle_6:
            "IoTデバイスを管理できるIoTバックエンドシステムを構築します。これらのシステムは、接続されたデバイスからシグナルを収集し、レポートを作成し、貴重な情報を提供します。私たちのお手伝いでワークフローを向上させ、効率を向上させることができます。",

        title_9: "提供されるもの",
        title_10: "最新のテクノロジー",
        title_11: "エンドツーエンドのセキュリティ",
        title_12: "スケーラビリティ",
        title_13: "",

        subtitle_10:
            "私たちは、戦訓豊富なテクノロジを使用し、負荷テストと連携して定期的なアーキテクチャとテクノロジの改良を行うことで、各ソリューションのスムーズなバックエンド開発を保証します。",
        subtitle_11:
            "私たちのバックエンドソリューションは、最も信頼性のあるメカニズムで保護されており（データの転送中と休止中のデータ保護を確保）、システムまたはアプリケーションの異種コンポーネント間でデータを安全に送信できます。",
        subtitle_12: "tokuuチームは、ビジネスニーズに従って自然にスケーリングできる拡張可能なバックエンドソリューションを構築します。",
        subtitle_13: "",

        title_14: "テクノロジースタックと検証済みの専門知識",
        title_15: "データベース",
        title_16: "プラットフォーム",
        title_17: "言語とフレームワーク",
        title_18: "プロフェッショナルコンプライアンス",
    },

    maintenance: {
        main_title: "アプリケーションの保守とサポートサービス",
        main_subtitle:
            "プロジェクトをさらに拡大したり、新しい機能を実装したり、レガシーコードを最新の技術で更新したりする場合、当社のカスタマーケア部門がお手伝いします。アプリケーションの保守とサポートサービスは、ソリューションの寿命を向上させ、延長するために作成されました。",

        title_1: "私たちの行うこと",

        title_2: "レベル1サポート（24/7）",
        title_3: "レベル2サポート（24/7）",
        title_4: "レベル3（L3）サポート",

        subtitle_2_1:
            "L1サポートエンジニアは、初期の通知に注意を払って、即座に対応できるようにします。低いネットワークパフォーマンス、アプリケーションの安定性などの問題が発生した場合、チームは情報を分析し、問題を解決する最良の方法を決定します。",
        subtitle_2_2: "私たちのL1サービスは次の項目をカバーします：",
        subtitle_2_3: "基本的なサポートとトラブルシューティング",
        subtitle_2_4: "システムの可用性とパフォーマンスのモニタリング",
        subtitle_2_5: "バックオフィスのアクティビティ：アカウントの変更、パスワードのリセット、エンドユーザーとのコミュニケーション",
        subtitle_2_6: "必要に応じて、L1エンジニアはL2またはL3サポートにトラブルチケットをエスカレーションします。",

        subtitle_3_1: "問題がソースコードの変更を必要としない場合、L2エンジニアはできるだけ早くシステムを復元します。",
        subtitle_3_2: "私たちのL2エンジニアは次のことができます：",
        subtitle_3_3: "サーバーの設定",
        subtitle_3_4: "モニタリングシステムの展開",
        subtitle_3_5: "ITインフラストラクチャのクラウドへの移行",
        subtitle_3_6: "DevOpsサービスの提供",
        subtitle_3_7: "システムの不具合がソースコードの問題による場合、サポートリクエストはL3エンジニアに割り当てられます。",

        subtitle_4_1: "L3エンジニアは次のような複雑な技術的な問題の専門的なトラブルシューティングを提供します：",
        subtitle_4_2: "バグの修正",
        subtitle_4_3: "監査およびコードレビューの実施",
        subtitle_4_4: "レガシーアプリの更新",
        subtitle_4_5: "新機能の実装",

        title_10: "柔軟性",
        title_11: "スケーラビリティ",
        title_12: "積極的なモニタリング",
        title_13: "迅速なインシデント管理",

        subtitle_10:
            "カスタマーケアチームの多面的な能力により、サポートエンジニアはビジネスニーズに合わせて調整できる個別のアプローチとさまざまなエンゲージメントモデルを提供し、予算目標を達成します。",
        subtitle_11:
            "私たちのチームは、規模を考慮して顧客をよりよくサポートしようと努力しています。私たちは独自のコンプライアンスマトリックスとサービスレベル契約に従って、拡張および構成が容易なソリューションを提供します。",
        subtitle_12:
            "私たちのソフトウェアの保守とサポートサービスを利用すると、脆弱性が発生する前にネットワーク、アプリケーション、セキュリティインフラストラクチャの健康状態を詳細に把握できます。",
        subtitle_13: "私たちは、予期せぬ中断に備え、それらを迅速に修正し、ソリューションを運用状態に戻します。",
    },

    devops_service: {
        main_title: "DevOps as a Service",
        main_subtitle:
            "tokuuは確立されたDevOpsサービス企業として、貴社のビジネス、開発、および運用チームがエンタープライズアプリケーションのアップグレード方法、ITセキュリティの向上、およびインフラストラクチャリソースの活用について最適な方法でコラボレーションできるようサポートします。",

        title_1: "DevOps開発とコンサルティングサービス",

        title_2: "分析と評価",
        title_3: "自動化と構成",
        title_4: "保守とサポート",

        subtitle_2_1:
            "私たちは包括的なDevOpsコンサルティングサービスを提供し、貴社の開発および運用環境を分析し、DevOpsの自動化をITエコシステムに統合する方法を示すロードマップを作成します。",
        subtitle_2_2: "私たちの監査の目的は次のとおりです：",
        subtitle_2_3: "ボトルネックの特定",
        subtitle_2_4: "適切なテクノロジツールの選択",
        subtitle_2_5: "適切なDevOpsモデルの作成",

        subtitle_3_1:
            "tokuuの専門家は、迅速でシームレスなデプロイメントと展開を確実にするために、デリバリーパイプラインを自動化します。進化するまたは複雑なシステムを効率的に管理できるように、インフラストラクチャを構成します。",
        subtitle_3_2: "このプロセスは以下をカバーします：",
        subtitle_3_3: "大規模なエンタープライズアプリケーションの自己維持型サービスへのセグメンテーション",
        subtitle_3_4: "ITインフラストラクチャのクラウドへの移行",
        subtitle_3_5: "迅速なバグの発見と修正",
        subtitle_3_6: "リスクと人的介入を最小限に抑えながら新機能の展開",

        subtitle_4_1:
            "DevOps Consulting Servicesチームは、ITインフラストラクチャがDevOpsフレームワーク内で正しく機能することを確認するだけでなく、技術部門がDevOpsのベストプラクティスを学び、採用するのにも役立ちます。",
        subtitle_4_2: "tokuuの24/7カスタマーケア部門は、以下の点でお手伝いします：",
        subtitle_4_3: "ITインフラストラクチャのクラウドへの移行",
        subtitle_4_4: "稼働状態を維持",
        subtitle_4_5: "定期的なパフォーマンスおよび負荷テストレポートに基づいて改善を計画",

        title_5: "DevOps実装へのアプローチ",

        title_6: "継続的インテグレーション",
        title_7: "継続的デリバリー",
        title_8: "継続的デプロイメント",

        subtitle_5:
            "私たちのDevOpsコンサルティングサービスは、ソフトウェアの実装とデリバリープロセス全体をコントロールし、ワークフローの主要なステップを自動化することを目指しています。これを実現するために、DevOpsコンサルティングおよび開発チームは、コードレビューと自動テストが各ソフトウェアビルドごとに実行され、成果物がクラウドにアップロードされ、本番環境に展開されることを確認します。",
        subtitle_6:
            "私たちはコード変更を定期的に中央のクラウドリポジトリにマージし、その後、自動テスト（UI、API信頼性、負荷、統合など）とビルドが実行されます。これにより、完全に検証および検証されたコードが生成され、デリバリーの準備ができます。",
        subtitle_7:
            "コード変更をテストおよび本番環境に自動的に展開するため、プロジェクトチームは常に展開対象の機能を持っています。また、クラウドでテスト環境を複製します。",
        subtitle_8:
            "tokuuはクライアントがアプリケーションリリースパイプラインを自動化できるようサポートします。その結果、動作するビルドはすぐに本番環境にプッシュされ、不一致が発見された場合には修正のために巻き戻されます。",

        title_9: "DevOpsサービスプロバイダーとしてtokuuを選ぶ理由",

        title_10: "DevOpsサービスアプローチ",
        title_11: "DevOpsの専門知識",
        title_12: "柔軟なエンゲージメントモデル",

        subtitle_10:
            "DevOpsコンサルティング会社として、tokuuはDevOpsを単独のサービスとして提供し、DevOpsチームを補完するために認定されたクラウドおよびインフラエンジニアのみを採用しています。私たちにとって、これは評判の問題です。",
        subtitle_11:
            "成熟したDevOps開発会社として、私たちは実践的なDevOpsコンサルティング経験と、市場で利用可能な新しいツールと技術に常に取り組んでいます。",
        subtitle_12:
            "私たちはビジネスの要件に合わせて調整できる透明で効果的な協力モデルを提供します。スタートアップでも大企業でも、貴社のビジネス要件に合わせて調整できます。",
    },

    internet_of_things: {
        main_title: "IoT開発サービス",
        main_subtitle:
            "革新的なスタートアップまたは企業として、経験豊富なテクノロジーパートナーに技術責任を委任することは賢い選択です。私たちのIoT開発サービスを利用すれば、tokuuは頼りになる経験豊富なテクノロジーパートナーとなります。スマートガジェットの開発、アナログ製品のデジタル化、カスタムIoTソリューションのインフラストラクチャの作成をサポートします。包括的なビジネス変革を計画している場合、IoTコンサルティングの専門家がシームレスにプロセスを導きます。",

        title_1: "エンドユーザーとビジネス向けのカスタムIoTソリューション",

        title_2: "産業用IoT",
        title_3: "消費者向けIoT",
        title_4: "ヘルスケアIoT",
        title_5: "スマートホーム",
        title_6: "ウェアラブル",

        subtitle_2: "産業用機器向けの予測メンテナンス、マシンビジョンシステム、協力型ロボット、およびHMI。",
        subtitle_3:
            "スマートアパレルとアクセサリー、ヘアブルデバイス、ベビーモニター、IoTベースのペットトラッキングソリューション、スマートホームシステム。",
        subtitle_4: "テレケアシステム、生体情報モニター、スマート医薬品トラッカー、包括的なヘルスケアデータ分析ソリューション。",
        subtitle_5:
            "接続されたホームソリューション：リモート制御可能なスマート照明システム、IoT駆動のHVACソリューション、セキュリティシステム、Alexa制御製品、自己学習型スマートホームデバイス。",
        subtitle_6:
            "ウェアラブルソリューション：スマートクローズ、スマートウォッチ、フィットネストラッカー、コネクテッドジュエリー、ヘアブル、拡張現実グラス、バーチャルリアルリティヘッドセット、ベビーケアソリューション、ペットトラッカー。",

        title_7: "当社のIoT開発スキルマトリックス",

        title_8: "IoTコンサルティング",
        title_9: "ハードウェアのプロトタイプおよびデザイン",
        title_10: "組み込みソフトウェア開発",
        title_11: "IoTダッシュボード",
        title_12: "接続デバイス向けのモバイルアプリ",

        subtitle_8_1:
            "当社のインターネット・オブ・シングス・コンサルティングサービスは、技術的な能力を超えてさまざまな領域にわたります。新製品をゼロから構築する必要がある場合、アイデアをMVPに変換し、市場で検証し、スケーリングするのをお手伝いします。ビジネスの機能を最適化する必要がある場合も、問題を特定し、IoTの助けを借りて既存のビジネスプロセスを再編成および自動化します。当社のチームは次の分野で幅広い経験があります：",
        subtitle_8_2: "ビジネス問題に関する高度なIoTコンサルティング",
        subtitle_8_3: "物理製品およびデジタル製品の開発コンサルティング",
        subtitle_8_4: "デジタルトランスフォーメーションの取り組みおよび変更管理",

        subtitle_9_1:
            "スマートデバイスのプロトタイプを開発前にコンセプトを確認します。デバイスのプロトタイプを作成するために、IoTボード、センサー、および既製のエンクロージャを使用します。開発プロセスには次の項目が含まれます：",
        subtitle_9_2: "回路図",
        subtitle_9_3: "PCBレイアウトおよびデザイン",
        subtitle_9_4: "PCB信号、電力の整合性、および熱解析",
        subtitle_9_5: "カスタムエンクロージャのデザイン",
        subtitle_9_6: "ファームウェア開発",
        subtitle_9_7: "完全な文書化、検証、コンプライアンス、および製造サポート",

        subtitle_10_1:
            "tokuuは、スマートデバイスをIoTインフラストラクチャに統合し、センサーデータをキャプチャし、エッジおよびフォグコンピューティング展開でデータ分析を実行し、データをキャプチャします。当社の専門知識は次の領域をカバーしています：",
        subtitle_10_2: "DSPおよびマイクロコントローラプログラミング",
        subtitle_10_3: "装置、UAV、およびスマートホーム向けのモーターコントロールアプリ",
        subtitle_10_4: "センサーデータの取得、統合、およびM2MのためのETLおよびミドルウェアソリューション",
        subtitle_10_5: "CloudBridgeの開発",
        subtitle_10_6: "リアルタイムおよび組み込みOS向けのBSP",
        subtitle_10_7: "AOSPの開発とサポート",

        subtitle_11_1:
            "tokuuのおかげで、IoTデバイスを設定し、現場およびリモートでサイバー物理システムを管理し、BIツールを使用してセンサーデータを分析し、対話型ダッシュボードで表示できます。当社のポートフォリオには次のものが含まれます：",
        subtitle_11_2: "WebベースのHMI",
        subtitle_11_3: "予防および予測メンテナンスシステム",
        subtitle_11_4: "AWS IoT Greengrass、Azure IoT Hub、およびGoogle Cloud IoT CoreをベースにしたカスタムIoTプラットフォーム",
        subtitle_11_5: "リアルタイムセンサーデータの可視化ソリューション",
        subtitle_11_6: "消費者向けエレクトロニクスのための管理ダッシュボード",

        subtitle_12_1:
            "IoTアプリケーション開発サービスの一環として、ネイティブおよびクロスプラットフォームのモバイルアプリを設計し、スマートデバイスが生成するデータへの移動アクセスを提供し、IoTソリューションのリモートコントロールとして機能します：",
        subtitle_12_2: "室内ナビゲーションおよびジオフェンスアプリ",
        subtitle_12_3: "ウェアラブルデバイス向けのモバイルアプリ",
        subtitle_12_4: "非接触決済ソリューション",
        subtitle_12_5: "画像認識アプリ",

        title_13: "IoTアプリケーション開発へのアプローチ",

        title_14: "接続",
        title_15: "収集",
        title_16: "アクション",

        subtitle_14:
            "tokuuは日常のオブジェクトに声を与え、インターネットゲートウェイを介してリアルタイムデータを送信できるようにします。これを実現するために、通信インターフェースとネットワーキングプロトコル（NFC、Wi-Fi、BLEなど）、RFIDタグ、ビーコン、およびセンサーを使用します。",
        subtitle_15:
            "キャプチャセンサーデータをキャプチャし、データ損失を防ぎ、データをストレージソリューションに取り込むための組み込みおよび分散システムを設計します。データを将来の使用のために保持するか、IoTアプリケーションまたはエンドポイントデバイスにリアルタイムで情報を提供します。",
        subtitle_16:
            "tokuuは、IoTシステムコンポーネントから取得したデータを実行可能なインサイトに変換します。データはカテゴリ分けされ、検索可能で、クライアントアプリからアクセス可能で、エンドポイントデバイスインターフェースの要件に従って視覚化されます。",

        title_17: "tokuuが正しいIoT企業である理由",

        title_18: "専門知識を備えた",
        title_19: "組み込みセキュリティ",
        title_20: "使いやすさを最優先",
        title_21: "初日からのスケーラビリティ",

        subtitle_18: "接続製品、および関連するIoTアプリケーション開発サービスは、当社の主要なコンピテンスです。",
        subtitle_19: "tokuuは、IoTアプリケーション開発およびハードウェア設計の重要な部分としてセキュリティを扱います。",
        subtitle_20:
            "ステークホルダーフィードバックを分析し、エンドユーザーを考えたIoTソリューションを作成するためにUXデザインのベストプラクティスに従います。",
        subtitle_21: "IoTソリューションが正確に動作し、ユーザー数、有効になる機能の数に関係なく、問題なく動作するようにします。",

        title_22: "私たちのノウハウ",

        title_23: "業界での経験に依存",

        subtitle_23:
            "tokuuは、産業用企業、デバイスメーカー、テクノロジースタートアップによるIoT開発プロジェクトにおける知識のギャップを埋めます。",
        title_24: "スポーツ",
        title_25: "メディアおよびエンターテインメント",
        title_26: "消費者向けエレクトロニクス",
        title_27: "産業製造",
        title_28: "ヘルスケアおよびライフサイエンス",

        subtitle_24:
            "私たちはすべてのことを行っています。アスリートのパフォーマンスを追跡するウェアラブルデバイス、テクノロジー支援リカバリープログラム、メディアストリーミングおよびソーシャルネットワーキングポータル。詳細については当社のポートフォリオをご覧いただき、過去のプロジェクトに関する詳細情報をご確認いただき、なぜ私たちがIoTアプリケーションを構築する正しい会社であるかをご自身で確認してください！",
        subtitle_25:
            "私たちの専門知識は、ビデオストリーミング、コンテンツデリバリーネットワーク、デジタルサイネージ、コンテクストマーケティングおよびナビゲーション向けの近接ソリューションに広がります。",
        subtitle_26:
            "インターネット・オブ・シングスの開発サービスの一環として、消費者向けエレクトロニクスブランドが既存のデバイスに接続を追加し、ゼロからスマートガジェットを設計するのを支援します。",
        subtitle_27:
            "私たちは産業企業に対して、アナログからデジタルへの移行方法、センサーを備えた装置とそれに付随するソフトウェアインフラストラクチャの強化、および予測メンテナンスにセンサーデータを活用する方法についてコンサルティングを行います。",
        subtitle_28:
            "tokuuは、デジタルヘルススタートアップと医療機関とのさまざまなプロジェクトで協力してきました。アンビエントライビングとテレケアソリューション、機械学習に基づく非侵襲的診断、およびセンサーデータの取得および処理ツールなど。",
    },

    storage: {
        main_title: "データストレージ開発サービス",
        main_subtitle:
            "私たちはSSD、MMC、およびNANDフラッシュメモリソリューション、可視化およびテストツールの開発経験を活かし、データストレージ企業が研究開発能力を強化し、新しいソリューションの導入コストを最小限に抑え、新しいストレージ市場セグメントでの展開を支援します。",
        title_2: "低水準のソリューション",
        title_3: "データ管理ソリューション",
        title_4: "自動化されたSSDテスト",
        title_5: "DevOpsサポート",
        subtitle_2_1: "モバイル、デスクトップ、エンタープライズレベルの完全なスタックSSDファームウェア開発：",
        subtitle_2_2: "SATA、NVMe、PCI Expressプロトコル",
        subtitle_2_3: "ファームウェアシステムサービス",
        subtitle_2_4: "デバッグ環境",
        subtitle_2_5: "製造テスト",
        subtitle_2_6: "障害分析",
        subtitle_2_7: "SSDシミュレーション環境",
        subtitle_3_1: "データストレージ、メンテナンス、バックアップ、アーカイブ、および移行のためのソフトウェアソリューション：",
        subtitle_3_2: "オブジェクトストレージシステム",
        subtitle_3_3: "データ移行ソリューション",
        subtitle_4_1: "テストインフラストラクチャのエンジニアリング：",
        subtitle_4_2: "ソフトウェアのインストールと自動テストベンチの展開",
        subtitle_4_3: "単体テスト",
        subtitle_4_4: "統合テスト",
        subtitle_4_5: "適合テスト",
        subtitle_4_6: "負荷およびパフォーマンステストを含むエンドツーエンドテスト",
        subtitle_5_1:
            "CIシステムのプレリリースサポート、ソフトウェアおよびハードウェアを含む中断しないソリューションの提供を確保するため：",
        subtitle_5_2: "CIインフラサポート",
        subtitle_5_3: "フィールドエンジニアリング",
        title_6: "tokuuの価値",
        title_7: "データストレージ開発のドメインエキスパート",
        title_8: "チームの迅速な展開能力",
        title_9: "世界のセキュリティ標準への準拠",
        title_10: "高品質な製品に焦点を当てる",
        subtitle_7:
            "業界の大手企業向けにデータストレージソリューションを開発する経験は、私たち自身の開発プロセスを磨くのに役立ちました。納期、品質、予算に関するリスクをどのように軽減し、顧客が開発コストを最適化するのを支援するかを知っています。",
        subtitle_8:
            "tokuuは、リソースの効率的な管理技術を活用して、リソースベンチ、定期的なスキルプロファイリング、トレーニングと認定、HRスクリーニング、および資格ロードマップを含むエンジニアの最適なスキルセットを持つプロジェクトチームを迅速にスタッフまたは展開します。",
        subtitle_9:
            "私たちは、ISO 27001の仕様に準拠し、すべての拠点に適切な法的、物理的、および技術的なコントロールを設置しています。私たちのセキュリティフレームワークには、顧客の知的財産を保護するための効果的な対策が含まれています。",
        subtitle_10: "私たちは、製品の早い段階でバグを見つけるための自動化された製品テストラボを組織します。",
        title_11: "専用の開発センター",
        title_12: "準備",
        title_13: "セットアップ",
        title_14: "ローンチ",
        title_15: "拡張",
        title_16: "運用",
        subtitle_11:
            "tokuuは、データストレージソリューションの開発に長年取り組んでおり、既に4つの専用開発センター（DDC）を構築しています。tokuuのDDCは、クライアントの内部プラクティス、独自の方法論、および企業文化に準拠しています。",
        subtitle_12_1: "必要な能力領域と資格を特定する",
        subtitle_12_2: "チームサイズと構造を計画する",
        subtitle_12_3: "DDCチームのセットアップのためのロードマップを開発する",
        subtitle_13_1: "コアチームのセットアップ",
        subtitle_13_2: "環境とツールのセットアップ",
        subtitle_13_3: "DDSのコアチームへの知識移転を開始する",
        subtitle_14_1: "課題に取り組み始める",
        subtitle_14_2: "できるだけ早く知識を適応させる",
        subtitle_15_1: "DDCのロードマップに従って専門家を追加する",
        subtitle_15_2: "新しいメンバーと知識を共有する",
        subtitle_16_1: "チームを計画されたサイズに拡大する",
        subtitle_16_2: "最大の生産性に達する",
    },

    computer_vision: {
        main_title: "コンピュータビジョンソリューション",
        main_subtitle:
            "私たちの機械学習の専門知識は、私たちのコンピュータビジョンサービスの基盤です。どんなプロジェクトを考えているかに関係なく、お客様の課題に対処する最適な方法を見つけ、カスタマイズされた認識プロセスと多重パラメータ分析ソリューションを構築します。それは、産業用自動化視覚検査（AVI）プラットフォーム、高度なCCTVシステム、または画像ベースの分析ツールの実装などです。",
        title_1: "",

        name_2: "オブジェクト認識",
        content_Recognition_1: "画像の分類、位置特定、ピクセルレベルのセグメンテーションを実行する強力なCVシステムの構築",
        content_Recognition_2: "顔の分析、ジェスチャーおよび動きの認識、および機械ビジョンに対するCV、ML、およびDLの方法の適用",
        content_Recognition_3:
            "背景分離、デジタルフィルタリング、ノイズ抑制、および影の除去のためのタスク指向のアルゴリズムの開発により、より高い画像精度を実現",
        content_Recognition_4: "パターンマッチングのためのフィーチャとクラシファイアの設計",
        title_2: "オブジェクト、動き、パターン認識のためのソリューション",
        subtitle_2:
            "tokuuは、画像取得とオブジェクト検出ソリューションを中心にしたコンピュータビジョン開発サービス全般を提供し、高速処理速度を犠牲にすることなくパフォーマンスを提供します。",

        name_3: "オブジェクトトラッキング",
        content_Tracking_1: "オブジェクトをビデオシーケンス内で位置特定するためのツールを備えたマルチカメラシステム",
        content_Tracking_2: "単一および多方向のオブジェクトトラッキングのためのカスタムニューラルネットワークとトラッキングアルゴリズム",
        title_3: "オブジェクトトラッキング",
        subtitle_3:
            "私たちはオブジェクトトラッキングアルゴリズムを活用してコンピュータビジョンソリューションを提供し、ビデオ内のオブジェクトの検出と軌道予測のための時間情報の分析に同様に効果的です。",

        name_4: "コンテンツ分析",
        content_web_1:
            "ビデオフレーム内のアクティビティや特定のコンテンツ（ヌード、喫煙など）を検出し、特定のアクション（警告、開始/停止コマンド）をトリガーするアクション認識システム",
        content_web_2:
            "大規模なカメラネットワークやクライアントサーバーシステム上での複雑なリアルタイムビデオコンテンツ分析のための分散アルゴリズム",
        content_web_3: "CVニューラルネットワークの軌跡予測ツールの実装により、ドローンと自動運転車に画像処理能力を提供",
        content_web_4: "クラウド、オンプレミスのサーバー、およびエッジ（カメラまたはエンコーダ内）で動作する分析ツール",

        title_4: "ビデオコンテンツ分析",
        subtitle_4: "画像分析プロジェクトをスタートさせ、集約されたビデオコンテンツの解釈を自動化するために、tokuuは次のものを提供します:",

        title_5: "コンピュータビジョンソリューションの種類",
        title_6: "オブジェクト、動き、パターン認識のためのソリューション",
        title_7: "オブジェクトトラッキング",
        title_8: "ビデオ分析",

        subtitle_6:
            "マルチファクタ認証製品、AI強化型CCTV監視ソリューション、およびバイオメトリックデジタルパターンマッチングソフトウェア。",
        subtitle_7:
            "ライブビデオストリーム内のマルチオブジェクトトラッキング（MOT）ソフトウェア、CNNベースのオブジェクト検出およびターゲットトラッキングシステム、さまざまなカメラタイプ用のCVソフトウェア。",
        subtitle_8:
            "災害管理およびセキュリティソリューション、交通モニタリングソフトウェア、IPカメラ、ビデオサーバー、およびPCベースのプラットフォーム用のビデオおよび画像分析アプリケーション。",

        title_9: "業界経験",
        subtitle_9:
            "オブジェクト検出、オブジェクトトラッキング、および大規模データ分析の実務経験を活かして、私たちのチームは人間の労力を削減し、あらゆる種類の操作を最適化するお手伝いをします。",

        title_10: "ヘルスケア",
        subtitle_10:
            "医療専門家が増え続ける図形情報に対処するのを支援するために、CT、X線、MRIスキャン画像などの大規模なデータセットを処理するソリューションを開発します。CVを活用して、二次元スキャン画像を3D対話型モデルに変換する対話型医療画像ソリューションを構築します。また、高齢者ケア向けの落下検出システムなど、CVベースの遠隔医療および患者モニタリング（RPM）製品も提供します。",

        title_11: "小売業",
        subtitle_11:
            "小売店やモール向けにさまざまなコンピュータビジョンソリューションを構築する専門知識があります。ディスプレイ最適化やプロモーションキャンペーンなどの店内マーケティングツールから、潜在的な万引き犯を検出し防止する監視ソリューションまで、ビデオ分析ツールは棚の管理やデータ収集などのプロセスをスピードアップし、人手を削減し、ビジネスコストを削減します。",

        title_12: "産業製造",
        subtitle_12:
            "ビデオオブジェクトトラッキング技術を活用した自動制御プロセスは、接続された工場にとって不可欠です。このような検査システムは、製品の包装エラーを回避し、品質管理を向上させ、オブジェクトの仕分けをスピードアップし、ロボットに人間のようなビジョン機能を提供します。",

        title_13: "コンピュータビジョンテクノロジースタック",
        subtitle_13: "",

        title_14: "コンピュータビジョンとディープラーニング",
        title_15: "モバイルプラットフォーム",
        title_16: "データ分析",
        title_17: "データ可視化",
    },

    blockchain: {
        main_title: "ブロックチェーンソフトウェア開発",
        main_subtitle:
            "安全なピアツーピア支払いと即時のマイクロトランザクション用のソリューションを構築したり、自己実行型のスマートコントラクトを開発したりする場合、私たちは知識とブロックチェーン技術のスキルを活用します。経験豊富なブロックチェーン開発会社として、tokuuはスタートアップと大企業の両方がブロックチェーンに基づいた分散型ネットワークの利点を活用できるよう支援します。",
        title_1: "私たちが構築するもの",
        title_2: "スマートコントラクト：作成とテスト",
        title_3: "分散型アプリケーション（Dapps）",
        title_4: "ICOのための技術開発",
        title_5: "ブロックチェーンエコシステム",
        subtitle_2:
            "tokuuチームはEthereum、Hyperledger、EOS、NEOベースなど、すべてのタイプのスマートコントラクトを作成します。スマートコントラクトコードに欠陥、誤動作、およびセキュリティの脆弱性がないことを確認するために、プレリリースの機能テストと非機能テストを実施します。",
        subtitle_3:
            "Dappsはビジネスロジックとフロントエンドによって結びつけられた一連の相互関連するスマートコントラクトを組み合わせたものです。これらのアプリケーションは、顧客がより速い支払い処理を可能にし、安全かつ信頼性のあるデータ記録を提供します。私たちのチームは、オラクルとの通信を含む完全なDappアーキテクチャを構築し、包括的なUX/UIデザインを作成します。",
        subtitle_4:
            "ビジネスアイデアがあり、プロジェクトの収益化プラットフォームが必要な場合、私たちのチームはICOの正確な技術的実現を提供します。ERC20準拠のトークンを開発し、ウォレットとオラクルを構築し、ホワイトペーパーの技術的および品質の部分を確認します。",
        subtitle_5:
            "ブロックチェーンエコシステムは透明で変更不可能なトランザクションを可能にする一連の暗号通貨取引アプリケーションを含みます。tokuuは暗号通貨ウォレット、分析ツール、マルチプラットフォーム取引所、ブロックチェーンエクスプローラー、オラクルアプリケーション、ポートフォリオモニタソリューションなど、ゼロからブロックチェーンエコシステムの要素を構築します。",
        title_6: "カスタムブロックチェーンソリューションの焦点",
        title_7: "暗号通貨開発",
        title_8: "Hyperledger上のブロックチェーン開発",
        title_9: "カスタマイズされたブロックチェーンソリューション",
        subtitle_7:
            "既存のデバイスをIoTソリューションに変換することを検討していますか？デバイスにセンサーを追加し、インターネットに接続し、大規模に実装するためのソフトウェアエコシステム全体を構築します。",
        subtitle_8:
            "私たちのブロックチェーン開発チームは、ビジネスのために安全な企業用ブロックチェーンを構築するためにHyperledgerテクノロジを活用するスキルを持っています。複数当事者の関与を不変かつ改ざん防止できるようにします。",
        subtitle_9:
            "特定の機能が必要な場合、業界のすべての側面を検討し、徹底的なビジネス分析を実施し、特注のブロックチェーンソリューションを構築します。",
        subtitle_10: "ブロックチェーンソフトウェア開発のスキルは、さまざまな産業において重要な役割を果たしています。",
        title_11: "ヘルスケア",
        title_12: "交通",
        title_13: "産業製造",
        subtitle_11:
            "私たちの専門家チームは、健康データの取得と管理のためのブロックチェーン駆動のソリューション、医療ワークフロー管理システム、および健康データの安全な分散型データベースを作成します。",
        subtitle_12:
            "ブロックチェーンアプリケーション開発とスマートコントラクト、IoTセンサーを組み合わせることで、交通会社は物流プロセスとサプライチェーンの透明性を向上させ、配送プロセス全体で商品と材料を追跡します。",
        subtitle_13:
            "産業企業はエネルギーコストを削減し、コスト効果の高いピアツーピアエネルギートレーディングプラットフォーム、IoTデバイスの分散型ネットワークなど、自動化されたエネルギー配布と消費ソリューションを導入します。",
        title_14: "テクノロジースタックと検証済みの専門知識",
        title_15: "HYPERLEDGERフレームワーク",
        title_16: "バックエンド",
        title_17: "エコシステム",
        title_18: "HYPERLEDGERツール",
        title_19: "暗号化、デジタル署名、ハッシング",
        title_20: "ブロックチェーンの専門知識",
        title_21: "品質管理",
        title_22: "暗号技術の専門知識",
        subtitle_20:
            "tokuuチームは、ビットコイン、Ethereum、Hyperledgerなどの主要なブロックチェーンプラットフォームおよび暗号システムのソリューションを設計し、構築します。ブロックチェーンのインフラストラクチャ全体またはその個々の要素（ブロックチェーンコア、ノード、エコシステムアプリケーションなど）を構築できます。",
        subtitle_21:
            "QAチームはプロジェクトチームに分析段階で参加し、QA戦略を開発し、実装された各機能をテストし、バグバウンティプログラムを実行します。これにより、ソリューション内の障害の数が減少し、ニーズを満たすのに役立ちます。",
        subtitle_22:
            "データ保護の実務経験を活かして、私たちはRSA、DES、AES、Diffie-Hellmann、ECC、TLS、SSL、SHA-1、SHA-2、SHA-3、MD5などの暗号化、デジタル署名、ハッシュアルゴリズムを含むセキュアな暗号ソリューションの幅広いスペクトルをクライアントに提供します。",
    },
    consumer: {
        main_title: "消費者向けエレクトロニクスソリューション",
        main_subtitle:
            "tokuuは、主要なブランドとスタートアップに対して消費者向けエレクトロニクス製品開発サービスを提供します。既存のデバイスに新しい機能を追加したり、ゼロから新しいガジェットを作成したりするのに役立ちます。そのために、ハードウェア設計、組み込みシステム開発、AR/VR、モバイルアプリ、Webソリューションのフルスタックの専門知識を活用します。",
        title_1: "tokuu消費者向けエレクトロニクスソリューションポートフォリオ",
        subtitle_1: "",
        title_2: "拡張現実（XR）",
        subtitle_2_1:
            "当社の仮想現実（VR）、拡張現実（AR）、およびミックスドリアリティ（MR）チームは、没入型コンテンツを作成し、そのデバイスへの適切な提供を調整し、製品やサービスにコンテキストデータを提供します。私たちは次のものの構築に実務経験があります：",
        subtitle_2_2: "ARリモートサポートソリューション",
        subtitle_2_3: "ARマニュアルとカタログ",
        subtitle_2_4: "没入型エンターテイメントソリューション",
        subtitle_2_5: "VRゲーム",
        title_3: "画像処理",
        subtitle_3_1:
            "私たちのチームは、画像とビデオコンテンツのパターンを解明するために機械学習を使用します。クラウドでデータ処理を有効にし、デバイスレベルでの機能を模倣して、ガジェットがユーザーの要求に迅速に応答できるようにします。私たちは次のものと協力しています：",
        subtitle_3_2: "印刷機器",
        subtitle_3_3: "ドローン（コンピュータビジョン）",
        subtitle_3_4: "スマートホームセキュリティシステム",
        subtitle_3_5: "医療画像ソリューション",
        title_4: "ユーザーインターフェイス",
        subtitle_4_1:
            "私たちは、画面ベースとスクリーンレスデバイスのユーザーインターフェイスの設計に実務経験があります。当社のドメイン知識は、ウェアラブルテクノロジー、ネイティブおよびクロスプラットフォームのモバイルアプリ、Webアプリ、デスクトップソフトウェアに及びます：",
        subtitle_4_2: "グラフィックHMI：IoTダッシュボード、ウェアラブル、自動車インターフェイス",
        subtitle_4_3: "音声インターフェイス：Alexa、Googleアシスタント、カスタム",
        subtitle_4_4: "ARインターフェイス",
        title_5: "バイタルサインおよび位置モニタリング",
        subtitle_5_1:
            "体内および位置センサーからデータをキャプチャし、クラウドでデータ処理を行い、スマート通知を有効にし、モバイル、デスクトップ、またはブラウザで情報を視覚化するのに役立ちます。私たちのポートフォリオには次のものが含まれます：",
        subtitle_5_2: "フィットネストラッカー",
        subtitle_5_3: "スマートアクセサリー",
        subtitle_5_4: "バイタルサインモニター",
        subtitle_5_5: "ペットトラッキングソリューション",
        title_6: "スマートエネルギー利用",
        subtitle_6_1:
            "現代の暖房、換気、および空調（HVAC）システムは、環境、動き、および占有センサーデータを活用してエネルギーの使用を最適化するためにIoTを活用しています。tokuuは、リアルタイムモニタリングソリューション、データ分析ソリューション、HVACリモート管理など、IoT HVACシステムを向上させるのに協力します。",
        subtitle_6_2: "リアルタイムモニタリングソリューション",
        subtitle_6_3: "データ分析ソリューション",
        subtitle_6_4: "HVACリモート管理",
        title_7: "フルサイクルの消費者向けエレクトロニクス製品開発",
        title_8: "アナログからデジタル",
        title_9: "アプリケーション開発",
        title_10: "ターンキープロジェクト",
        title_11: "プロジェクトレスキュー",
        subtitle_8:
            "既存のデバイスをIoTソリューションに変換することを検討していますか？センサーを追加し、インターネットに接続し、大規模な実装にソフトウェアエコシステム全体を構築します。",
        subtitle_9:
            "私たちはフルスタックの開発サービスプロバイダーとして、デバイスに組み込まれたソフトウェア、モバイルアプリケーション（iOS、Android、Xamarin、Flutter）、および管理ダッシュボードを提供できます。",
        subtitle_10:
            "当社のターンキーエレクトロニックデザインサービスには、ハードウェアおよびカスタムソフトウェア開発、コンプライアンス（HIPAA、FDA、GMP、DICOM、HL7）および大量生産の支援が含まれます。",
        subtitle_11:
            "PCBの故障診断からサイバーセキュリティおよびDevOpsの実装まで、私たちはプロジェクトの停滞を解消し、競争相手よりも前倒しで新製品を発売するための幅広いサービスを提供しています。",
    },

    software: {
        main_title: "ソフトウェアとテクノロジー業界",
        main_subtitle:
            "私たちの提案価値は、私たちがソフトウェアをゼロからエンジニアリングするクライアントを助けるビルダーであることです。既製のソリューションを構成するのではなく、特定のニーズと厳格な仕様のためにカスタムテクノロジーを構築するのを助けます。私たちは、最も要求の厳しいエンジニアリングの課題を扱う技術的なソフトウェア開発者です。",
        title_1: "私たちの主要なドメインエキスパートise",
        subtitle_1: "",

        title_2: "コンシューマーエレクトロニクス向けソリューション",
        subtitle_2_1:
            "コンシューマーエレクトロニクス領域でソフトウェアを展開したい場合、それを市場向けのソリューションに変えます。ファームウェア、ドライバー、カスタマイズされたオペレーティングシステム、またはターゲットオーディエンスのニーズを満たす接続デバイスで拡張します。既存の顧客ベースにマーケティングをする場合、tokuuと提携してソフトウェアを特定のエンドユーザーの要件に適応させます。",
        subtitle_2_2: "AR/VRリモートアシスタンスソリューション",
        subtitle_2_3: "スマートホームセキュリティシステム",
        subtitle_2_4: "グラフィックHMIおよびARインターフェイス",
        subtitle_2_5: "スマートウェアラブル",

        title_3: "オイル＆ガス向けソリューション",
        subtitle_3_1:
            "伝統的なビジネスモデルに依存しているエネルギー企業にアプローチし、重要な運用プロセスのデータを過小評価しがちです。AR/VR、ML、アルゴリズム、および視覚化技術を活用してプロセスのデジタル化の利点をデモンストレーションします。",
        subtitle_3_2: "自動化されたUAV検査システム",
        subtitle_3_3: "ML駆動型分析",
        subtitle_3_4: "データ視覚化ベースのダッシュボードとレポート",
        subtitle_3_5: "AR/VR視覚化アプリ",
        subtitle_3_6: "AI対応の企業向けインテリジェント検索プラットフォーム",
        subtitle_3_7: "リモートロケーションと緊急事態のVRシミュレーション",

        title_4: "メディアおよびエンターテインメント向けデジタルコンテンツ配信",
        subtitle_4_1:
            "メディアおよびエンターテインメントのクライアントが、富のある、パーソナライズされた、エンターテインメント性の高い、リアルタイムのデジタルコンテンツを提供するためのターゲットオーディエンスの期待を管理する方法を知っています。最適なアプリケーションを提供し、デジタルサイネージ、アセット管理、スマートテレビ、デジタル画像処理ソリューションの構築でのニッチな経験を活用します。",
        subtitle_4_2: "デジタルアセット管理ソリューション",
        subtitle_4_3: "デジタルサイネージエコシステム",
        subtitle_4_4: "マルチキャストシステム",
        subtitle_4_5: "パターンおよび顔認識ソリューション",

        title_5: "ヘルスケアソリューション",
        subtitle_5_1:
            "健康施設にワークフロー自動化、医療画像解析、およびテレケアソリューションを提供することを目指している場合、私たちはお手伝いできます。業界の規制遵守要件を理解し、正しい電磁干渉を持つ複雑な電子システムを設計する方法を知っており、健康診断から最大の洞察を得るためにAI技術を使用する方法も理解しています。",
        subtitle_5_2: "カスタムリモート患者モニタリング（RPM）ソリューション",
        subtitle_5_3: "画像注釈ツール",
        subtitle_5_4: "ワークフロー自動化用の患者ポータルソリューション",
        subtitle_5_5: "在庫追跡システム：RFID、ビーコン",
        subtitle_5_6: "人口健康管理（PHM）ソフトウェア",

        title_6: "コンピテンシーチーム",
        title_7: "現地監督",

        subtitle_6:
            "主要な専門知識に基づいてグループ化されたコンピテンシーチームは、指定されたビジネス領域でのベストプラクティスに加工および組織化されたテクノロジーとドメインの知識を蓄積します。コンピテンシーチームは知識移転とスキル実現の継続性を確保するトレーニングとコーチングのハブとして機能します。",
        subtitle_7:
            "リモートチーム間での最適な知識移転を確保し、誤通信を最小限に抑えるために、複数のチームメンバーを顧客の現地へ派遣することがあります。現地での存在は、現地のプロセスに対する洞察を得るのに役立ち、プロジェクトのオンボーディングを容易にします。",
    },

    data_storage: {
        main_title: "データストレージ業界向けソリューション",
        main_subtitle:
            "新しい市場でのストレージソリューションの足跡を拡大し、SSD開発の取り組みを強化したり、フラッシュメモリの実装におけるR&Dイニシアティブを強化したりする場合、私たちにはそのようなプロジェクトに対応するための経験とリソースがあります。",

        title_2: "SSDストレージシステムファームウェア",
        title_3: "プロトコル、ドライバー＆ツール",
        title_4: "NANDフラッシュメモリ",
        title_5: "カスタムテスト",

        subtitle_2:
            "私たちはSSD製品のためのリアルタイムの組み込みシステムファームウェアを設計、実装、評価します。業界のトップ企業と多くの年にわたる共同作業から得た専門知識を活用しています。",
        subtitle_3:
            "私たちの組み込み開発者とQAエンジニアは、PCIe/NVMe、SATA、eMMC、SCSIなどの複数のストレージプロトコルに関する実務経験を持っています。また、すべての種類のテストと品質保証のためのカスタムツールとテストスタンドを開発します。",
        subtitle_4:
            "消去回数、エラーコレクション能力、NANDフラッシュメモリチップの書き込み耐久性など、ストレージの耐久性に関するニーズを理解するために取り組みます。",
        subtitle_5:
            "ファームウェア開発を加速させるために、私たちは継続的な統合（CI）プロセスを採用しています。イベントトリガードテスト、タイムトリガードテスト、およびテストベンチを含むカスタムテストインフラストラクチャを評価するためのニーズを評価し、いくつかのプロトコル上でテストAPIのセットを実装することもできます。これにより、テストのパフォーマンスが向上します。",
        title_6: "経験",
        title_7: "ファームウェア+ソフトウェア",
        title_8: "性能",

        subtitle_6:
            "tokuuはストレージソリューションのドメインでのユニークな経験を提供しています。特定のプロジェクト経験に加えて、私たちは業界の最大手の一部の企業向けに専門の開発チームを構築しました。",
        subtitle_7:
            "ファームウェア開発者がより良いコードを書き、品質保証を確保するために、ソフトウェア開発が優れている必要があることを理解しています。共同で開発されたツールを作成および使用することにおいては専門家です。",
        subtitle_8:
            "今日のストレージソリューションに要求される最も重要な機能である速度と信頼性を提供するために、数十年にわたる深い経験を適用します。パフォーマンスは市場受け入れの主要な要因であり、私たちはその提供方法を知っています。",
    },

    about_us: {
        title_1: "あなたのためにより良い未来を創造する",
        subscribe_1:
            "tokuuは、新製品開発とR&Dに特化した会社として、テック分野の最も困難な問題に取り組んできました。今では、イノベーションとアイディア発想に加えて、デジタルトランスフォーメーションビジネスコンサルティングを提供する拡張されたサービスと、tokuu Venture Fundの支援を受けたtokuu Venture Studioが、初期段階のスタートアップをサポートするために私たちの能力を補完しています。私たちのスーパーパワーは、これらすべてを1つの屋根の下でグローバルスケールで提供することです。それでは、一緒により良い未来を築き始めましょう。",
        subscribe_2: "私たちがどのように実現するかを学びましょう。",
    },

    temp: {
        main_title: "",
        main_subtitle: "",
        title_10: "",
        subtitle_10: "",
    },
};

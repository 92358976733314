<template>
    <v-container class="custom-container">
        <slot></slot>
    </v-container>
</template>
<script>
export default { name: "CustomContainer", components: {}, data: () => ({}) };
</script>
<style lang="scss" scoped>
@import "@/styles/media.scss";

.custom-container {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 70px;
    padding-bottom: 85px;
    position: relative;
    max-width: 1390px !important;

    @include pad {
        // max-width: 768px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>

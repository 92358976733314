import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import i18n from "@/plugins/i18n";
import "@/styles/common.scss";
import { createPinia, PiniaVuePlugin } from "pinia";
import CustomContainerVue from "./components/CustomContainer.vue";
import ScrollReveal from "scrollreveal";

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.component("CustomContainer", CustomContainerVue);

Vue.directive("scroll-reveal", {
    inserted: function (el, binding) {
        ScrollReveal().reveal(el, binding.value || {});
    },
});

Vue.directive("video-hover", {
    bind(el, binding) {
        const target = binding.value || el;
        const video = target.querySelector("video");

        if (video) {
            target.addEventListener("mouseover", () => {
                video.play();
            });

            target.addEventListener("mouseout", () => {
                video.pause();
            });
        }
    },
});

new Vue({
    router,
    i18n,
    vuetify,
    pinia,
    render: (h) => h(App),
}).$mount("#app");

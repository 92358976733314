<template>
    <div>
        <template v-for="i of items">
            <v-menu v-if="i.children.length > 0" :key="i.title" open-on-hover offset-y transition="slide-y-reverse-transition">
                <template #activator="{ on, attrs, value }">
                    <span class="white--text cursor mr-6 d-flex" dark v-bind="attrs" v-on="on" text>
                        <span class="mr-1">{{ $t(i.title) }}</span>
                        <v-icon class="white--text" :class="{ rotate: value }" small>mdi-chevron-down</v-icon>
                    </span>
                </template>
                <v-list class="pa-6">
                    <v-list-item class="mb-4">
                        <v-list-item-title class="text-32 font-weight-bold">
                            {{ $t(i.title) }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="({ name, path }, index) in i.children" :key="index" @click="$router.push(path)">
                        <v-list-item-title class="item-title cursor">
                            {{ $t(name) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <span v-else class="white--text cursor mr-6" dark text @click="$router.push(i.path)" :key="i.title">
                <span> {{ $t(i.title) }}</span>
            </span>
        </template>
    </div>
</template>
<script>
export default { name: "HeaderNav", props: { items: Array }, components: {}, data: () => ({}), methods: {} };
</script>
<style lang="scss" scoped>
.v-list-item {
    .item-title {
        transition: color 0.2s;
    }
    &:hover .item-title {
        color: rgb(0, 173, 255);
    }
}
</style>

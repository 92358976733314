export default {
    common: {
        home: "home",
        contact_us: "Contact Us",
        learn_more: "Learn More",
        show_more: "Show More",
        get_in_touch: "Get in Touch",
        get_in_touch_2: "Get in Touch for Details",
        send_success: "send succsss!",
        send_repeat: "send repeat!",
        phone_or_mail_error: "phone or mail error!",
        message_error: "message error!",
    },
    hander: {
        head_title_1: "Services",
        head_title_2: "Custom Software Development",
        head_title_3: "Web Systems",
        head_title_4: "Mobile Apps",
        head_title_5: "Cloud Systems",
        head_title_6: "AWS Software",
        head_title_7: "Desktop Apps",
        head_title_8: "QT Development",

        head_title_9: "UX/UI Design",
        head_title_10: "Cyber Security",
        head_title_11: "Embedded System Development",
        head_title_12: "Front End",
        head_title_13: "Back End",
        head_title_14: "Maintenance and Support",
        head_title_15: "DevOps",
        head_title_16: "Solutions",
        head_title_17: "IoT",
        head_title_18: "Storage",

        head_title_19: "Computer Vision",
        head_title_20: "Blockchain",
        head_title_21: "Industries",
        head_title_22: "Consumer Electronics",
        head_title_23: "Healthcare",
        head_title_24: "Software and Technology",
        head_title_25: "Automotive",
        head_title_26: "Data Storage",
        head_title_27: "About",
    },

    home_page: {
        title_1: "Build It with Us",
        subtitle_1: "Apply now to receive $125K in cash and development services to boost your early-stage startup.",
        title_2: "Transform Your Business",
        subtitle_2: "Don’t settle for a standalone technology fix. Think omnichannel digital ecosystem.",
        title_3: "Engineer from \nA to Z",
        subtitle_3: "Complex end-to-end development with one full-stack team. Start from any stage.",
        title_4: "Build to Scale",
        subtitle_4: "Set your product up for success from stage one.",

        title_5: "tokuu Innovation Engine",

        title_6: "Innovation & Ideation",
        subtitle_6:
            "We deliver early-stage ideation through the tokuu Innovation Lab and hands-on Bootcamps to achieve breakthrough growth",
        title_7: "Needs Assessment",
        subtitle_7: "Our Business Solutions and Solutions Engineering teams help pinpoint your needs and define a clear path forward",
        title_8: "Solution Discovery",
        subtitle_8: "Our architects and engineers are ready to dive into the toughest problems and provide creative recommendations",
        title_9: "Prototyping & R&D",
        subtitle_9: "We engineer new technology which often requires prototypes and proof-of-concept models to discover a viable solution",
        title_10: "Design & Specification",
        subtitle_10:
            "The devil is in the details so we go the extra mile to ensure designs are human-centered and specs are captured at a granular level ",
        title_11: "Development & Testing",
        subtitle_11:
            "Our global development teams are built to deliver full-stack end-to-end solutions across hardware, firmware, and software",
        title_12: "Production & Support",
        subtitle_12: "We offer a full complement of post-development services including global sourcing, maintenance, support, and DevOps",

        title_13: "Full-stack Capabilities",

        title_14: "IoT Solutions",
        subtitle_14:
            "Wearable Gadgets ／ Back-end Infrastructure ／ IoT Apps and Software ／ Converting Analog to Digital ／ Industrial IoT Systems ／ Data Warehousing and Analytics",
        title_15: "Embedded Software",
        subtitle_15:
            "Firmware Development ／ Middleware and Custom Drivers ／ Bluetooth Apps ／ Human Machine Interface Development ／ Smart Home Systems ／ Sensor Solutions",
        title_16: "Hardware Design",
        subtitle_16:
            "Electronic Сircuit Design ／ PCB Simulations ／ Electronic System Design ／ PCB Layout Development ／ FPGA Design ／ Enclosure Design",
        title_17: "Web App Development",
        subtitle_17:
            "Payment Solutions ／ Intelligent Bot Apps ／ Digital Asset Management Systems ／ Portal Development ／ Social Networks ／ Enterprise Web SystemsAI ／ ML Apps Development",
        title_18: "Mobile Solutions",
        subtitle_18:
            "Enterprise Mobile Apps ／ Smart Devices & Wearables ／ Digital Image Processing ／ Multimedia Distribution ／ Uber-like Apps ／ Mobile Messengers ／ VoIP Apps Development ／ Social Networks",
        title_19: "Cloud Solutions",
        subtitle_19:
            "Cloud App Development ／ IoT Cloud Solutions ／ Cloud Infrastructure Optimization ／ Cloud Integration ／ Cloud Consulting ／ Cloud Deployment Service ／ Migration to Cloud ／ DevOps",

        title_20: "Customer Reviews",
        title_21: "Thorsten Stremlau",
        content_21: "Global CTO, Lenovo",
        subtitle_21:
            "With tokuu, we are in expert hands, concerning not only project management. tokuu understands our client's needs and treats them the way we would.",
        title_22: "Lutwin Schommer",
        content_22: "CEO, AGU Baby",
        subtitle_22:
            "Because of their excellent teamwork, we've achieved our goals. After the first six months of development, they had fixed the most important issues, doubled our audience, and increased the number of installations on both app stores.",
        title_23: "Jared Schrieber",
        content_23: "President, Revolution Robotics",
        subtitle_23:
            "I think any start-up that is looking to build their first hardware product and doesn’t have all of the expertise in house, should consider working with tokuu. Because they bring experience from hundreds of projects.",

        title_24: "Solutions We Deliver",

        title_25: "IoT",
        subtitle_25: "Consumer gadgets, smart home healthcare solutions, computer numerical controls, DSP and network solutions.",
        title_26: "Storage",
        subtitle_26: "Firmware and algorithms for NAND flash memory, SSDs/eMMC, and firmware functionality validation.",
        title_27: "Industrial Automation and Robotics",
        subtitle_27:
            "Solutions for remote process control, equipment monitoring, production line automation; electronics driven by firmware, MCUs, sensors, and algorithms.",
        title_28: "AR/VR/MR",
        subtitle_28:
            "Business and game apps facilitating knowledge-sharing, employee onboarding, field service management, and immersification.",
        title_29: "Content Distribution and Conferencing",
        subtitle_29:
            "Smart TV solutions, video capturing and processing software, media content distribution systems, instant messengers with video sharing features, and interactive video conferencing solutions.",
        title_30: "Computer Vision Software",
        subtitle_30: "Solutions for object, movement, pattern recognition, object tracking, video content analysis.",
        title_31: "AI/ML",
        subtitle_31: "Solutions delivering analytics, product recommendations, cognitive computing, and predictive analytics.",
        title_32: "Blockchain",
        subtitle_32:
            "Solutions for securing verifiable transactions, maintaining and tracking transactional data, analyzing transaction flow and wealth distribution.",

        title_33: "Solutions We Deliver",
        title_34: "Oil & Gas",
        subtitle_34:
            "We help automate multi-party communication, equipment monitoring, health and safety condition compliance, and leveraging data for making informed decisions.",

        title_35: "Healthcare",
        subtitle_35:
            "We develop diagnostic devices and software, remote patient monitoring solutions, AI-based medical imaging solutions, and data acquisition and management systems compliant with industry-specific standards and regulations.",

        title_36: "Consumer Electronics",
        subtitle_36:
            "We help employ sensors and algorithms to build devices and apps for tracking performance, pet activity, data visualization, and analysis.",

        title_37: "Automotive",
        subtitle_37:
            "We tap hardware, firmware, embedded, and ML know-how to build HMI/multimedia units, advanced driver assistance systems and object recognition software.",

        title_38: "Data Storage",
        subtitle_38: "We understand how to incorporate flash into SSD to make more intelligent and fast performing storage solutions.",

        title_39: "Industrial Manufacturing",
        subtitle_39:
            "We implement solutions for remote control of technical processes, equipment monitoring, motion control, production line automation, M2M and service process automation.",
    },
    contact_us: {
        input_1: "First name",
        input_2: "Last name",
        input_3: "E-mail",
        input_4: "Phone Number",
        input_5: "Company name",
        input_6: "Type of service you're looking for:",
        input_7: "Message",

        subtitle_1: "I agree to tokuu",
        subtitle_2: "Privacy and Cookies Policy.",

        submit: "SUMBIT",
    },
    address_info: {
        title_1: "HEADQUARTERS",
        name_1: "Houston, US",
        address_1: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_1: "+1 888 552-5001",
        phone_1_back: "+1 888 552-5001",

        title_2: "HEADQUARTERS",
        name_2: "Houston, US",
        address_2: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_2: "+1 888 552-5001",
        phone_2_back: "+1 888 552-5001",

        title_3: "HEADQUARTERS",
        name_3: "Houston, US",
        address_3: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_3: "+1 888 552-5001",
        phone_3_back: "+1 888 552-5001",

        title_4: "HEADQUARTERS",
        name_4: "Houston, US",
        address_4: "tokuu Development Corp. 1155 Dairy Ashford Rd., Suite 125 Houston, Texas 77079 USA",
        phone_4: "+1 888 552-5001",
        phone_4_back: "+1 888 552-5001",
    },

    custom_dev: {
        title_1: "Custom Software Development Services",
        subtitle_1:
            "From mobile consumer apps and line-of-business corporate web solutions to system level desktop utilities and large-scale corporate IT systems, we can bring to life any software idea you may have.",

        name_2: "Web",
        content_web_1: "Digital Asset Management Systems",
        content_web_2: "VoIP Solutions",
        content_web_3: "Web Portals",
        content_web_4: "Payment Solutions",
        content_web_5: "IoT Solutions",
        content_web_6: "AI/ML Web Apps",
        content_web_7: "Intelligent Bot Apps",
        content_web_8: "Social Networks",
        content_web_9: "ETL Solutions",
        title_2: "Optimize your business processes with functional, interactive and secure web apps and systems.",
        subtitle_2:
            "At tokuu, we create both standalone web solutions and multi-level system parts that help companies efficiently deal with operational and technological challenges.",

        name_3: "Mobile",
        content_mobile_1: "Enterprise Mobile Apps",
        content_mobile_2: "Social Networking Apps",
        content_mobile_3: "Mobile Messengers",
        content_mobile_4: "Payment Integrations",
        content_mobile_5: "VoIP Apps",
        content_mobile_6: "AR Appss",
        content_mobile_7: "Smart Devices & Wearables Solutions",
        content_mobile_8: "GEO-Location Apps",
        content_mobile_9: "Digital Image Processing & Camera Software",
        content_mobile_10: "Multimedia Distribution Solutions",
        title_3: "Complex cross-platform, native or enterprise mobile solutions for businesses in all industries.",
        subtitle_3:
            "We design and develop all types of mobile custom software development solutions — complex mobile apps for any platform, mobile SDKs and utilities, lean mobile business solutions smoothly integrated with web services and internal corporate systems.",

        name_4: "Cloud",
        content_cloud_1: "Cloud Application Development",
        content_cloud_2: "IoT Cloud-based Solutions",
        content_cloud_3: "Cloud Optimization",
        content_cloud_4: "Cloud Integration",
        content_cloud_5: "Migration to the Cloud",
        content_cloud_6: "Cloud Consulting",
        content_cloud_7: "DevOps and Cloud Management",
        content_cloud_8: "Cloud Deployments",
        title_4:
            "Streamline your business operations and enhance the operational performance of your software with high-end cloud technologies.",
        subtitle_4:
            "We do all things cloud-related: build scalable solutions of any complexity, customize and configure cloud solutions according to your business processes, design and construct cloud infrastructures, migrate legacy IT systems, support and maintain existing cloud apps.",

        name_5: "Desktop",
        content_desktop_1: "Native and Cross-Platform Desktop Apps",
        content_desktop_2: "System-Level Desktop Apps",
        content_desktop_3: "Multimedia Apps",
        content_desktop_4: "3D Modeling / CAD Systems",
        content_desktop_5: "Plugins",
        content_desktop_6: "Libraries and SDKs",
        title_5: "High-performance, responsive, productive desktop software for any of your business or technical needs.",
        subtitle_5:
            "Our desktop development expertise spans a wide spectrum of areas: hardware or OS-specific desktop applications, standalone or web-enabled apps; utility or plug-in solutions.",
    },

    industry_experience: {
        main_title: "Industry Experience",
        title_1: "Consumer Electronics",
        subtitle_1:
            "Our consumer electronics expertise comprises custom software development solutions for mobile, wearables, home appliances, Bluetooth enabled devices, automotive systems, storage platforms and more.",
        title_2: "Sports",
        subtitle_2:
            "Our portfolio features custom software solutions for sports data tracking, processing and analysis, training apps with advanced recognition patterns, AR/VR and machine learning sports solutions.",
        title_3: "Healthcare",
        subtitle_3:
            "Our team of experts helps healthcare companies build software solutions for patient health diagnostication, systems for health data acquisition and management, medical portals, solutions for medical workflow management, and HIPAA-compliant apps.",
        title_4: "Oil and Gas",
        subtitle_4:
            "With our AR/VR, ML, algorithmic data processing and visualization solutions, Oil & Gas companies can optimize their operational, data management, and equipment monitoring processes.",
        title_5: "Industrial Manufacturing",
        subtitle_5:
            "We help industrial companies to better manage their operational activities by building systems for machine control and production, remote equipment monitoring, energy consumption optimization, and more.",
        title_6: "Software & Technology",
        subtitle_6:
            "Together with our reputable technology partners, we are proud to bring emerging technologies and innovative products to life. Sensor devices, data storage solutions, and ML-driven software are some of the competencies we have acquired through dedicated collaborations.",

        subtitle_7:
            "From wearables and IoT devices to smart homes and advanced security systems—we foster end-user experience with solutions built with the latest embedded technologies. tokuu has two decades of in-house expertise in developing consumer electronics based on bare metal, BSP, RTOS, DSP, AOSP, and other technologies.",
        subtitle_8:
            "tokuu offers embedded development as part of sports tech projects. We work on solutions that improve training and prevent injury, including projects for professional sports, fitness, and rehabilitation. To track and analyze physical performance, we build wearables. Depending on the project, we equip devices with different connectivity solutions—RFID, GPS/GIS, or Bluetooth. For more in-depth analysis, we may add advanced tools like computer vision.",
        subtitle_9:
            "tokuu provides full-cycle embedded systems development services for healthcare institutions as well as companies that produce healthcare equipment. We develop storage solutions, hardware, firmware, and middleware components for medical devices and wearable sensor systems for collecting and processing health data.",
        title_10: "Automotive",
        subtitle_10:
            "Our team provides full-cycle embedded development for automotive embedded systems. We build in-vehicle solutions like HMIs, system and ADAS controllers, and connectivity mechanisms, as well as off-board software for fleet management, remote vehicle diagnostics, and cloud-based data management.",
        title_11: "Media & Entertainment",
        subtitle_11:
            "As part of our embedded solutions development services, we craft media players, media processing and streaming tools, software for content compression and protection, and media storage solutions.",
        title_12: "Industrial Manufacturing",
        subtitle_12:
            "Our embedded software development services help businesses win big. We can improve your daily routine with improved asset tracking and power control. If you envisage more innovative scenarios, tokuu is up to the challenge. We help automate workflows on the factory floor and improve safety at work.",
    },
    time_line: {
        main_title: "Our Approach: from RFP to Support",
        title_1: "Discovery",
        subtitle_1:
            "We collect your ideas and requirements and convert them into solution concepts, objectives, and new opportunities for your business.",
        title_2: "Assessment",
        subtitle_2:
            "We study your domain, business priorities, and project scope and assess them against relevant technologies, possible technical constraints, and implementation practicalities.",
        title_3: "Planning",
        subtitle_3:
            "We design the system: define the architecture, modules, interfaces, and data for your system, assign the team and decide on the development life cycle and methodology.",
        title_4: "Implementation",
        subtitle_4:
            "We develop and complete your project deliverables. Depending on your requirements and timeframe, we use a range of proven approaches and methodologies.",
        title_5: "Development Approaches and Methodologies",
        subtitle_5_1: "Iterative or agile approach - Scrum and Kanban methodologies.",
        subtitle_5_2: "Use-case driven, architecture-centric, iterative, and incremental approach - RUP methodology.",
        subtitle_5_3: "Breaking down project activities into linear sequential phases - Waterfall methodology.",
        title_6: "Delivery",
        subtitle_6: "We employ the proper tech stack to develop all solution components and stabilize the deployment.",
        title_7: "Support",
        subtitle_7: "We ensure that your system is fully functional, always performs as expected, and scales along with your needs.",
    },
    time_line_2: {
        main_title: "How We Work",
        title_1: "Discovery",
        subtitle_1:
            "We collect and process your embedded system project requirements, undertaking comprehensive research and analysis at the early stages to mitigate project risks. Before you commit, you’ll know all the facts through estimation and planning.",
        title_2: "Rough System Design",
        subtitle_2:
            "We help you choose an operating system, third-party libraries, and frameworks, and determine and partition hardware and software components. You’ll get to review the final blueprint before it’s passed on to our embedded development team.",
        title_3: "Development & Implementation",

        subtitle_3_1: "Our embedded system development services include:",
        subtitle_3_2: "Hardware and device driver development, as well as board bring-up",
        subtitle_3_3: "Developing secure bootloaders for the custom hardware",
        subtitle_3_4: "BSP development, including RTOS, Linux, and other operating system porting",
        title_4: "Next, we implement:",
        subtitle_4_1: "Third-party software package porting or middleware and protocol stacks",
        subtitle_4_2: "Software development tool setup and configuration",
        subtitle_4_3: "Firmware design, coding, and testing",
        subtitle_4_4: "Design and software architecture review and testing",
        subtitle_4_5: "Continuous integration, deployment, configuration, and embedded system management.",

        title_5: "Integration",
        subtitle_5:
            "We bring all the embedded system components together, testing entire embedded systems to ensure they work as a whole and comply with all your project specifications.",
        title_6: "Delivery",
        subtitle_6:
            "We perform comprehensive QA and debugging as a part of our embedded development. Our product and system testing practices ensure that the code is clean, readable, and well-commented. Only after that is it ready for final delivery.",
        title_7: "Maintenance and Support",
        subtitle_7:
            "We monitor and manage your system performance 24/7. If any bugs arise, we can fix them immediately and add any new functionality upon request. This is an important part of embedded software development services. ",
    },
    tech_stack: {
        main_title: "Tech Stack & Verified Expertise",
        title_1: "WEB",
        subtitle_1:
            "Our web development team relies on the latest, most reliable technologies and frameworks to ensure solid, uninterrupted performance for your product.",

        title_2: "MOBILE",
        subtitle_2:
            "Our mobile app development expertise extends across multiple platforms and technologies, allowing us to create mobile solutions of any complexity, upgrade your custom mobile app, or integrate it with any system.",

        title_3: "DESKTOP",
        subtitle_3:
            "With more than two-decades of experience in custom development services, the tokuu expert team knows how to choose the most effective technology for every project.",

        title_4: "CLOUD",
        subtitle_4:
            "Our cloud app development expertise covers all the major platforms. We follow the best industry practices and build agile, flexible and scalable cloud solutions.",

        title_5: "VERIFIED EXPERTISES",
        subtitle_5:
            "Our experience is proved out by numerous successful projects, certificates and partnerships. We provide the best quality custom software development service at every project phase: from drafting app architecture to final deployment.",

        subtitle_6: "FIRMWARE",
        subtitle_7: "MIDDLEWARE",
        subtitle_8: "SOFTWARE",
        subtitle_9: "CONNECTIVITY",
        subtitle_10: "PROXIMITY",
    },

    why_tokuu: {
        main_title: "Why Tukuu?",
        title_1: "Decades of Experience",
        subtitle_1:
            "20+ years in custom software development services.  75% of our architects, developers and business analysts have 6-12 years of hands-on experience in cross-domain areas.",

        title_2: "Flexibility and Transparency",
        subtitle_2: "We offer our clients a full range of engagement models to suit their business needs and project objectives.",

        title_3: "Mature Process",
        subtitle_3:
            "We mitigate risks by driving complex long-term projects in line with proven methodologies, including Agile (SCRUM) and RAD, as well as “classical” software development processes like RUP.",

        title_4: "Quality Control",
        subtitle_4:
            "Our QA department participates in the development process from the earliest stages to ensure comprehensive testing coverage and accurate quality assessment.",

        title_5: "Decades of Experience",
        subtitle_5:
            "We are a team of seasoned professionals with more than 20 years of hands-on experience in hardware, firmware and embedded application development, as well as profound practical knowledge of a wide range of industries.",

        title_6: "End-to-end Development",
        subtitle_6:
            "tokuu provides full-cycle development — from initial analysis, requirements management, hardware and PCB design, enclosure design, and rapid prototyping, to implementation, ongoing support, and continuous improvements.",

        title_7: "Transparency and Communication",
        subtitle_7:
            "We guarantee total transparency into the process and progress of your project. Whether we are using Agile, Kanban, RUP, or Waterfall engagement, you’ll be able to maintain very close communication with Product Managers and Development Leads, while getting access to detailed documentation, regular reporting and project management software tools.",

        title_8: "Quality Control",
        subtitle_8:
            "Our QA department participates in the development process from the earliest stages to ensure comprehensive testing coverage and accurate quality assessment.",
    },
    how_can_we_help: {
        main_title: "How Can We Help?",
        title_1: "Post-Release Maintenance and Support",
        subtitle_1: `We understand how important it is for our customers to deliver useful and convenient software to the market to stay competitive and profitable.
        <br/> <br/>
        We address these challenges through special maintenance and support services aimed to fix functional inconsistencies, patch vulnerabilities, and improve system’s performance. A well-thought QA strategy along with post-release maintenance and support helps mitigate risks associated with the new software launch.
        <br/> <br/>
        We assess your future solution enhancement needs and suggest a relevant plan: incorporate user feedback, ensure your solution against failure or malfunction caused by operating system upgrades, and reinforce your app’s functional features.
        `,
        title_2: "Optimal Quality",
        subtitle_2: `With our custom software development service, you get a solution of the highest quality that meets three core criteria. Our software must:
        <br/> <br/>
        - Address the business needs it was made for 
        <br/> <br/>
        - Be designed in compliance with engineering standards and conventions
        <br/> <br/>
        - Work according to its design, without bugs
        <br/> <br/>
        To achieve optimal quality on all three criteria, we employ ongoing QA activities throughout the software development process. Our QA team acts as a separate group that brings an unbiased perspective and high degree of transparency to the quality assessment process.
        `,

        title_3: "R&D Initiatives",
        subtitle_3: `With a focus on R&D, tokuu embedded software engineers go far and wide when exploring the breadth of capabilities and fields of application for the next generation of industry hallmarks, identifying potential pockets of growth for our customers.
        <br/> <br/>
        We also offer R&D projects within our embedded software development services. Here’s an example of our work: we tried and tested ways to port custom hardware platforms to Android. The aim was to make proprietary devices work with products running on top of the Android OS. Another example is game development. Our engineers wanted to achieve a more immersive experience for gamers. They did so by integrating motion controllers and other dimension technologies.`,

        title_4: "QA Labs",
        subtitle_4: `Quality assurance of embedded systems is also available as a separate service for our clients. We provide testing and debugging for a range of embedded solutions.
        <br/> <br/>
        To craft efficient, resilient, and infallible software, we’ve established two focused QA labs: our Firmware & Embedded QA Lab and our Mobile QA Lab. These are applicable during unit, integration, system, and acceptance testing.`,

        title_5: ".Net and Java for Enterprise Backends",
        subtitle_5: `We use tried and tested .NET and Java as the backbone of your solution.
        <br/> <br/>
        Using these dependable technologies, our team crafts scalable distributed systems, and architects reliable and maintainable data-intensive applications that match the key goals of your business and ensure its efficiency.`,

        title_6: "Mean Stack & Node.Js Development for SPA",
        subtitle_6: `Using MongoDB with NoSQL data schema and Node.js development aptitude, we build event-driven web back ends and real-time communication applications.
        <br/> <br/>
        In doing this, we shift a great piece of logic to a highly interactive rich JavaScript client, ensuring its flexibility through asynchronous input/output.`,

        title_7: "Post-Release Software Maintenance and Support",
        subtitle_7: `tokuu offers application maintenance and support services to ensure your solution demonstrates secure, fault-free operation and fast performance. Our team helps customers boost agility and simplify support and maintenance for software development projects, while allowing for continuous improvement and consistency of UX.`,

        title_8: "",
        subtitle_8: `We generally use a Help Desk system based on Atlassian JIRA for prioritizing, documenting and routing support issues. The Help Desk enables our application maintenance and support team to quickly respond to users’ inquiries, check the progress of each task, exchange comments, and track a history of actions for each ticket.`,

        title_9: "Tangible Results with DevOps",
        subtitle_9: `From DevOps consulting and ideation to deployment, your Development and Operations teams work on a software solution together, acquire new skills, and automate end-to-end software delivery processes by using proper tools and following DevOps best practices. This will help you:`,

        title_10: "",
        subtitle_10: `Bring your product to the market faster
        <br/> <br/>
        Reduce overall project costs
        <br/> <br/>
        Roll out the right features when your customers need them
        <br/> <br/>
        Deliver a product that is secure and operates smoothly
        <br/> <br/>
        Meet your customers’ expectations`,

        title_11: "IoT in the Context of Digital Transformation",
        subtitle_11: `We develop robust cyber-physical systems that fully or partially automate well-identified processes in enterprises. These systems help companies undergoing digital transformation to free up employees’ time and focus on delivering better services to their customers.`,
        title_12: "Risk-free Innovation",
        subtitle_12: `At tokuu, every knowledge-intensive project is preceded by a proof of concept/discovery phase. This is how we can identify the technology barriers to implementing your IoT idea, create a project roadmap, and set realistic goals.`,

        title_13: "Post-Release Maintenance and Suppo",
        subtitle_13: `We understand how important it is for our customers to deliver useful and convenient software to the market to stay competitive and profitable.
        <br/> <br/>
        We address these challenges through special maintenance and support services aimed to fix functional inconsistencies, patch vulnerabilities, and improve system’s performance. A well-thought QA strategy along with post-release maintenance and support helps mitigate risks associated with the new software launch.`,

        title_14: "Controllable Timelines",
        subtitle_14: ``,

        title_15: "Photo Apps and Digital Imaging",
        subtitle_15: `Apart from object classification, segmentation, and other machine vision solutions, tokuu has a large portfolio of AI apps with advanced filters and digital photo editors based on face recognition, image processing and edge detection. Computer vision solutions allow users to retouch their photos, edit backgrounds, and add stickers and masks to their shots.`,

        title_16: "Custom software for Diverse Camera Types",
        subtitle_16: `tokuu's machine vision systems include management software for all types of cameras from drone-mounted to IP and stationary. Extending hardware capabilities with HD video and imagery recording and analysis, we deliver 24/7 video surveillance service, often in extreme conditions and in inaccessible places.`,

        title_17: "Data-based Approach",
        subtitle_17: `If you’re working on an innovative solution, we’ll start with a Discovery Phase to assess the feasibility of your idea. This helps us choose the right technology stack and BOM components, and design a system architecture for future scalability and high performance.`,

        title_18: "Growth through Innovation",
        subtitle_18: `tokuu designs consumer electronics solutions to help businesses venture into the Internet of Things and thus explore new revenue opportunities. With an IoT solution, your brand gets an opportunity to extend service beyond the point of purchase and personalize customer experience.`,

        title_19: "USB and Trusted Flash",
        subtitle_19: `tokuu can help when it comes to hands-on experience with mass storage devices built on the USB standard (refer to Leef, Sandisk, and other project examples). 
        <br/> <br/>
        We’re also experienced at working with trusted execution environments (TEE) to ensure code and data are protected with respect to confidentiality and integrity.`,

        title_20: "SSD Data Storage",
        subtitle_20: `Solid-state drives that use integrated circuit assemblies as memory have exploded due to their lack of mechanical parts but they are not without issues. We thoroughly understand these issues and mitigation techniques.`,
    },

    ui_ux_design: {
        main_title: "UI/UX Design Services",
        main_subtitle:
            "tokuu covers a whole range of UI/UX design services: from UX research and gathering end-user data to building prototypes and running usability audits. We help companies build engaging products and create meaningful user and customer experiences that convert into tangible business results.",

        title_1: "Our UI/UX Services Cover",
        title_2: "Enterprise Apps",
        subtitle_2_1:
            "We design user interfaces for enterprise-level systems: SaaS apps, complex web solutions, rich internet applications, cross-platform desktop software and more.",
        subtitle_2_2:
            "We build information architecture that organizes big data into a structure that simplifies understanding and delivers a compelling user experience.",
        title_3: "Embedded Devices",
        subtitle_3_1:
            "tokuu creates user interfaces for IoT devices: smart watches and glasses, fitness trackers, GPS pet collars, as well as complete UI/UX solutions for smart homes and connected cars.",
        subtitle_3_2:
            "We carry out thorough UX research and build UI/UX designs that provide a coherent experience across a whole embedded system, and ensures fluent cross-device communication. Regardless of screen size, resolution and form factor, we guarantee a seamless user experience across all devices.",
        title_4: "Web Solutions",
        subtitle_4_1:
            "We craft user experiences for web solutions: content management systems, web portals, eCommerce websites, and intelligent bot apps just to name a few.",
        subtitle_4_2:
            "From wireframes to user flows and prototypes, our UI/UX team will help you tailor interfaces that match the specific needs of your web app users.",
        title_5: "AR/VR Apps",
        subtitle_5_1:
            "We create specific environments and interfaces based on conceptual user flows, ergonomics and interaction models especially for VR and AR apps.",
        title_6: "Websites",
        subtitle_6_1:
            "tokuu can help you create intuitive and highly functional UI/UX design for your landing pages and websites, and run UI/UX audit to ensure that each design component functions correctly on every screen size.",
        title_7: "Mobile Apps",
        subtitle_7_1:
            "We create graphic design and interfaces for iOS, Android, and cross-platform mobile apps so they work on every device and fit every screen size. Beyond that, we focus on learning customer needs to create great experiences for mobile app users. Our UX/UI team ensures all designs are consistent in form and function and easy to use.",

        title_8: "Our Approach: from Understanding Needs to Delivering Experiences",
        title_9: "What You Get",

        title_10: "Higher Conversion Rates",
        subtitle_10:
            "Through our use of graphics and layouts as well as messaging and interaction, we will help you create user experiences that result in higher conversion rates. From user research to crafting CTAs, our UI/UX design team will help you increase your project ROI.",
        title_11: "Seamless Usability",
        subtitle_11:
            "We know how to transform your end-user needs and their product journey into efficient and smooth experiences. We design simple yet sophisticated interfaces that are visually appealing, holistic and intuitive to use.",
        title_12: "Transparency & Accountability",
        subtitle_12:
            "All our design processes are maximally transparent and agile. We use qualitative and quantitative methods to ensure all your project activities align with your established goals, and hold ourselves accountable for delivering better user experiences.",
        title_13: "Questions? Let's Talk",
    },

    cybersecurity: {
        main_title: "Cybersecurity Services",
        main_subtitle:
            "tokuu has devised a set of analytical, testing, and consulting practices to help enterprises undergoing Digital Transformation. We review their existing code base, fix bugs, introduce new layers of security to repel hacker attacks, and educate employees on cybersecurity.",

        name_2: "Assessment",
        content_Assessment_1: "Code and documentation review",
        content_Assessment_2: "Compliance assessment (HIPAA, HL7, FDA, PCI DSS, GDPR, FedRAMP)",
        content_Assessment_3: "Cybersecurity consulting",
        content_Assessment_4: "Firewall configuration analysis",
        content_Assessment_5: "Evaluating your employees' adherence to your corporate security policy and best practices",
        title_2: "Cybersecurity Assessment",
        subtitle_2:
            "We examine the components of your IT infrastructure so you can rest assured your system is free of security vulnerabilities. The process includes:",

        name_3: "Testing",
        content_Testing_1: "Vulnerability scanning (cross-site scripting, SQL injections, remote code execution, SSL/TLS, etc.)",
        content_Testing_2: "DoS/DDoS attack emulation",
        content_Testing_3: "Penetration testing",
        content_Testing_4: "User authorization and access control",
        title_3: "Cybersecurity Testing",
        subtitle_3:
            "To detect security flaws in custom hardware, source code, and software architecture, we run manual and automated tests. In detail this means:",

        name_4: "Overhaul",
        content_Overhaul_1: "Effective management of development and QA resources",
        content_Overhaul_2: "Firewalled security fixes",
        content_Overhaul_3: "Continuous testing and automation of the software delivery pipeline",
        content_Overhaul_4: "Corporate cybersecurity strategy development",
        title_4: "Cybersecurity Overhaul",
        subtitle_4:
            "Once we have assessed the security flaws in your business IT solutions, we will come up with an improvement plan covering:",

        name_5: "Implementation",
        content_Implementation_1: "Implementing intrusion detection and prevention (IDS/IPS) systems",
        content_Implementation_2: "Deploying end-to-end data encryption and antivirus software",
        content_Implementation_3: "Enabling unattended security checkups and patches",
        content_Implementation_4: "Installing custom hardware security modules (HSMs) and tokens",
        title_5: "End Product and Network Security Implementation",
        subtitle_5:
            "We use a combination of tools and practices to protect your solution and the data stored within your IT infrastructure:",

        title_6: "What Makes tokuu Stand out as a Cybersecurity Service Provider?",

        title_7: "Focusing on Security",
        subtitle_7:
            "We provide a custom security design in every technology solution, conduct a thorough review of open-source software and hardware components, and turn to DevOps practices to make sure no vulnerability makes it to production.",

        title_8: "Implementing for IoT",
        subtitle_8:
            "As a company specializing in connected products, we understand cybersecurity in the IoT context. tokuu follows IoT development best practices to prevent botnet and ransomware attacks and secure your sensitive data.",

        title_9: "Prioritizing Usability",
        subtitle_9:
            "We collaborate with your company’s stakeholders to align security with your business goals and optimize software and hardware functionality to meet security and performance requirements — particularly under stress conditions.",

        subtitle_10:
            "As a cybersecurity service provider, tokuu introduces new layers of security to help companies fight off hacker attacks. Our portfolio of industry-specific cybersecurity solutions and services features:",

        title_11: "Healthcare and Life Science",
        subtitle_11:
            "HIPAA-compliant EHR solutions, patient portals, mHealth apps, and trackers. Enhanced security with biometrics (voice, iris, fingerprint, and facial recognition).",

        title_12: "Industrial Manufacturing",
        subtitle_12:
            "Cybersecurity audit. Network security solutions and industrial firewall systems. Secure on-premise and cloud-based data storage solutions.",

        title_13: "Consumer Electronics",
        subtitle_13:
            "Data encryption and secure communication between connected devices (wearables, Smart Home appliances). Unique device passwords. OTA firmware updates.",
    },

    embedded_system: {
        main_title: "Embedded Systems Development",
        main_subtitle:
            "tokuu engineers complex embedded systems that combine hardware and user interfaces with powerful software, connectivity, and proximity technologies. We cover the full embedded systems development cycle: from product strategy, design, and system development to implementing embedded integrations, testing, and user experience.",

        name_2: "Firmware",
        content_Firmware_1: "Bare metal firmware for sensors",
        content_Firmware_2: "Board support package (BSP) solutions",
        content_Firmware_3: "Digital signal processing (DSP) development",
        content_Firmware_4: "Android open-source projects (AOSP)",
        content_Firmware_5: "Linux kernel development",
        title_2: "Build bare metal systems, DSP solutions, RTOSs, board bring-up, low-power designs, boot loaders, and more.",
        subtitle_2:
            "Whether you are looking for embedded solutions to connect devices within an IoT system, remotely control devices and equipment, or gather and process sensor data, our embedded engineers can help. We cover:",

        name_3: "Middleware",
        content_Middleware_1: "Device drivers",
        content_Middleware_2: "SDKs",
        content_Middleware_3: "AI-based middleware",
        content_Middleware_4: "Code optimization services",
        content_Middleware_5: "3D party solution porting",
        content_Middleware_6: "Project rescue services",
        title_3: "Tie together the parts of your IoT system and make them work as one. Bridge the legacy component gap.",
        subtitle_3:
            "You may need embedded development if you want to connect disparate elements into a single system. Those elements may include hardware from different vendors, third-party services, and different operating systems. In any case, we know how to make them work together. To ensure interaction, we use:",

        name_4: "Apps",
        content_Apps_1: "IoT apps",
        content_Apps_2: "Media streaming software",
        content_Apps_3: "Cloud gateways",
        content_Apps_4: "Human–machine interfaces (HMI)",
        content_Apps_5: "Smart sensors",
        title_4: "Manage connected devices and IoT systems, process the collected sensor data, and obtain actionable insights.",
        subtitle_4:
            "As part of our embedded systems development service, we help businesses optimize the performance of their consumer and industrial IoT solutions, efficiently manage workflows, enhance the security of their data, and get advanced reporting mechanisms. We develop:",

        name_5: "HMIs",
        content_HMIs_1: "Healthcare HMI systems",
        content_HMIs_2: "HMI solutions for equipment control and calibration",
        content_HMIs_3: "Industrial robotics systems",
        content_HMIs_4: "Automotive infotainment solutions",
        content_HMIs_5: "Software solutions for digital signal processing",
        content_HMIs_6: "Navigation app development and integration",
        title_5:
            "We build all aspects of HMI development: hardware and software, graphical user interfaces, web and cloud infrastructures.",
        subtitle_5:
            "As an embedded software development company, we develop HMI solutions of any type and complexity. We build basic products, like machine command panels or data visualization software. We also develop complex ecosystems for our clients, setting up multimedia, networking capabilities, and other advanced features. Our team works with:",

        name_6: "Connectivity",
        content_Connectivity_1: "Bluetooth and BLE app development",
        content_Connectivity_2: "Personal devices with Wi-Fi, WANs, LPWAN or cellular network connectivity",
        content_Connectivity_3: "Smart homes",
        content_Connectivity_4: "Industrial connectivity solutions",
        title_6:
            "Hook up with Bluetooth development, Wi-Fi, IoT WANs, LPWAN, and Cellular Solutions for secure, low-power, and stable operation.",
        subtitle_6:
            "We design and develop hardware components and apps that enable secure data exchange over a reliable connection that lasts. This applies both to IoT systems and the connected devices. Within our embedded software development services, we work with:",

        name_7: "Proximity",
        content_Proximity_1: "Custom firmware and drivers",
        content_Proximity_2: "Proximity hardware design: beacons, NFC readers, RFID tag",
        content_Proximity_3: "App development to leverage iBeacon, beacons, and other sensing tech",
        content_Proximity_4: "Web systems for solution orchestration",
        title_7: "Connect with devices and apps driven by RFID, NFC, beacon, infrared, and laser technologies.",
        subtitle_7:
            "Our embedded development services also bring the benefits of proximity technologies to the table. Now, you can collect data of all types, like micro-location and contextual details. Proximity data may help optimize stock management, customer services, in-store marketing, and staff management. We do:",
    },
    frontend_development: {
        main_title: "Front-end Development Services",
        main_subtitle:
            "Our front-end application development team will design intuitive interactions with your IT systems and balance the UI and business logic of complex apps: web, desktop, mobile, IoT dashboards, and HMIs.",

        title_1: "Front-end Solutions",

        title_2: "Web App Interfaces",
        title_3: "Single-page Apps",
        title_4: "Desktop App UIs",
        title_5: "IoT Dashboards",
        title_6: "Mobile App Interfaces",
        title_7: "eCommerce Themes",
        title_8: "HMIs",

        subtitle_2:
            "We build web apps and websites with decoupled front-end architecture, which visualize data processed by servers and leverage content to a variety of endpoint devices.",
        subtitle_3:
            "We are au fait with single-page and progressive web applications — messengers, chatbots, social networking websites, and online maps.",
        subtitle_4:
            "We drill down to the OS and hardware architecture core to design desktop apps with intuitive interfaces mimicking the functionality of the system UI.",
        subtitle_5:
            "We use JS frameworks and cloud-friendly tools to visualize sensor data in real time and help you manage IoT and IIoT solutions from your PC.",
        subtitle_6:
            "We create cross-platform and hybrid apps that meet the Material Design guidelines and provide an on-point user experience on mobile devices and in web browsers.",
        subtitle_7:
            "We design flexible front ends for eCommerce websites (marketplaces, crowdfunding, auctions) to help online retailers engage device-hopping customer",
        subtitle_8:
            "We develop voice interfaces, AR manuals, device-to-browser streaming apps, IVI systems, and web apps for companies looking to exercise control over smart equipment and consumer devices.",

        title_9: "Our Approach to Front-end Development",
        title_10: "User Journey Mapping",
        title_11: "Inclusive Design",
        title_12: "Focus on Performance",
        title_13: "",

        subtitle_10:
            "Before we embark on a front-end development project, we research user needs and study heat and click maps to predict behavior and develop use cases.",
        subtitle_11:
            "We present content with clarity, so that users with different navigation preferences, browsing patterns, and accessibility needs find their way around your app and complete actions faster.",
        subtitle_12:
            "Our front-end development team works in tandem with UX/UI designers and back-end experts to design software architecture that scales well and handles any increase in traffic.",
        subtitle_13: "",

        title_14: "Our Tech Stack",
        title_15: "WEB APPLICATION INTERFACES",
        title_16: "SPA",
        title_17: "DESKTOP APPS",
        title_18: "MOBILE APPS",
        title_19: "CLOUD-NATIVE DATA VISUALIZATION",
        title_20: "INTERACTIVE DATA PRESENTATION",
        title_21: "MACHINE LEARNING",
        title_22: "THIRD-PARTY APIS",
    },

    backend_development: {
        main_title: "Back-End Development Services",
        main_subtitle:
            "We build extensible on-premise and cloud-based back-end solutions for mobile, web, desktop, and IoT systems that scale together with your growing business needs.",

        title_1: "We Cover",

        title_2: "Back-end Application Development",
        title_3: "Cloud Infrastructure / Migration / Solutions",
        title_4: "Technical Back-end Audit",
        title_5: "Mobile App Back-end Development",
        title_6: "IoT Back Ends",

        subtitle_2:
            "Our backend web development services include building scalable, fault-tolerant back ends for enterprise systems, complex web applications, IoT infrastructure, VoIP solutions, and web portals—just to name a few.We can also help you integrate your legacy system with third-party services and apps.",
        subtitle_3:
            "We build back-end solutions with elastic computing capacity that enables your system to scale naturally with the industry’s best cloud platforms. Additionally, we help migrate your legacy back-end systems to public, private, or hybrid clouds.",
        subtitle_4:
            "To help you optimize your existing application or migrate a legacy system to a new environment, we can perform a thorough back-end audit, identify bottlenecks, and revamp your infrastructure, architecture, codebase, and data schemas.",
        subtitle_5:
            "We create back ends for enterprise mobile apps, smart devices, multimedia solutions, and social networks. Our back-end development team will handle your app’s server and business logic and make sure all the data is synced across platforms. Our back end ensures fast performance and secure data storage. It will scale on demand and integrate with third-party services.",
        subtitle_6:
            "We build IoT back-end systems that let you manage your IoT devices. These systems collect signals from connected devices, create reports, and offer valuable insights. With our help, you can enhance workflows and boost efficiency.",

        title_9: "What You Get",
        title_10: "Latest Tech",
        title_11: "End-to-end Security",
        title_12: "Scalability",
        title_13: "",

        subtitle_10:
            "We ensure smooth backend development for each solution by using advanced, battle-tested technologies and carrying out constant architecture and tech refinement in concert with load testing.",
        subtitle_11:
            "Our back-end solutions come protected with the most reliable mechanisms (ensuring data protection both in-transit and at-rest) and can securely transmit data between heterogeneous components of your systems or apps.",
        subtitle_12: "tokuu teams build expandable backend solutions that scale naturally according to your business needs.",
        subtitle_13: "",

        title_14: "Tech Stack & Verified Expertise",
        title_15: "DATABASES",
        title_16: "PLATFORMS",
        title_17: "LANGUAGES & FRAMEWORKS",
        title_18: "PROFESSIONAL COMPLIANCE",
    },

    maintenance: {
        main_title: "Application Maintenance & Support Services",
        main_subtitle:
            "If you want to further scale your project, implement new features, or update legacy code with modern technologies, our Customer Care department is ready to help. Our application maintenance & support services have been created to improve and extend the lifetime of your solution.",

        title_1: "What We Do",
        title_2: "Level 1 Support (24/7)",
        title_3: "Level 2 Support (24/7)",
        title_4: "Level 3 (L3) Support",

        subtitle_2_1:
            "Our L1 support engineers keep a close eye on initial notifications to be sure we can respond immediately. If issues concerning low network performance, application stability and others arise, the team will analyze the information, and determine the best way to resolve a problem.",
        subtitle_2_2: "Our L1 services cover:",
        subtitle_2_3: "Basic support and troubleshooting",
        subtitle_2_4: "System availability and performance monitoring",
        subtitle_2_5: "Back-office activities: modifying accounts, password resets, communication with end users",
        subtitle_2_6: "If necessary, our L1 engineers escalate tickets to Level 2 or Level 3 support.",

        subtitle_3_1:
            "If the issue doesn’t require changes to the source code, our L2 engineers will restore the system as quickly as possible.",
        subtitle_3_2: "Our L2 engineers can:",
        subtitle_3_3: "Configure servers",
        subtitle_3_4: "Deploy monitoring systems",
        subtitle_3_5: "Move IT infrastructure to the cloud",
        subtitle_3_6: "Provide DevOps services",
        subtitle_3_7:
            "If the system malfunction is caused by issues in the source code, the support request will be assigned to a Level 3 engineer.",

        subtitle_4_1: "Our L3 engineers will provide expert troubleshooting of complex technical issues such as:",
        subtitle_4_2: "Fixing bugs",
        subtitle_4_3: "Performing audit and code review",
        subtitle_4_4: "Updating legacy apps",
        subtitle_4_5: "Implementing new features",

        title_10: "Flexibility",
        title_11: "Scalability",
        title_12: "Proactive Monitoring",
        title_13: "Swift Incident Management",

        subtitle_10:
            "Thanks to the multi-faceted capabilities of our Customer Care team, our support engineers provide an individual approach and a variety of engagement models that can be adjusted to your business needs and meet your budget objectives.",
        subtitle_11:
            "Our team strives to better support customers at scale. We follow our own Compliance Matrix and Service Level Agreements to deliver solutions that are easy to expand and configure.",
        subtitle_12:
            "With our software maintenance and support services, you can get a thorough view of the health of your networks, applications, and security infrastructure before vulnerabilities occur.",
        subtitle_13:
            "We help keep your enterprise prepared for unexpected disruptions, fix them ASAP, and return your solution to an operational state.",
    },

    devops_service: {
        main_title: "DevOps as a Service",
        main_subtitle:
            "As an established DevOps services company, tokuu will help your business, development, and operations teams stay on the same page regarding the optimal way to upgrade enterprise apps, improve IT security, and utilize infrastructure resources.",

        title_1: "DevOps Development and Consulting Services",

        title_2: "Analysis and Evaluation",
        title_3: "Automation and Configuration",
        title_4: "Maintenance and Support",

        subtitle_2_1:
            "We conduct comprehensive DevOps consulting services—analyzing your development and operational environment and creating a roadmap that shows you how to integrate DevOps automation into your IT ecosystem.",
        subtitle_2_2: "Our audit is aimed at:",
        subtitle_2_3: "Identifying bottlenecks",
        subtitle_2_4: "Choosing the right technology tools",
        subtitle_2_5: "Creating a suitable DevOps model.",

        subtitle_3_1:
            "tokuu experts automate your delivery pipeline to ensure fast yet seamless deployments and rollouts across IT processes. We configure your infrastructure so that you can manage evolving or complex systems efficiently.",
        subtitle_3_2: "The process covers:",
        subtitle_3_3: "Segmentation of bulky enterprise apps into self-sustained services",
        subtitle_3_4: "IT infrastructure migration to the cloud",
        subtitle_3_5: "Prompt bug discovery and fixing",
        subtitle_3_6: "Deployment of new features with minimal risk and human involvement.",

        subtitle_4_1:
            "Besides ensuring that your IT infrastructure properly functions within the DevOps framework, our DevOps Consulting Services team will help your technology department learn and adopt DevOps best practices.",
        subtitle_4_2: "The tokuu 24/7 Customer Care department will assist you in:",
        subtitle_4_3: "Moving IT infrastructure to the cloud",
        subtitle_4_4: "Keeping it up and running",
        subtitle_4_5: "Mapping out improvements based on regular performance and load testing reports.",

        title_5: "Our Approach to DevOps Implementation",
        title_6: "Continuous Integration",
        title_7: "Continuous Delivery",
        title_8: "Continuous Deployment",

        subtitle_5:
            "Our DevOps consulting services aim to take control of the entire software implementation and delivery process and automate the key steps in the workflow. To do this, our DevOps consulting and development teams will make sure the code review and automated tests run with each software build, and the deliverables get uploaded to the cloud and rolled out to production.",
        subtitle_6:
            "We merge code changes into a central cloud repository on a regular basis, after which automated tests (UI, API reliability, load, integration, etc.) and builds are run. This results in fully verified and validated code, ready to proceed to delivery.",
        subtitle_7:
            "We automatically deploy code changes to a testing and production environment, so the project team always has a deployment-ready piece of functionality. We also replicate testing environments in the cloud.",
        subtitle_8:
            "tokuu helps clients automate the application release pipeline. As a result, every working build is immediately pushed to production or rolled back for fixing if inconsistencies are discovered.",

        title_9: "Why Choose tokuu as Your DevOps Service Provider?",
        title_10: "DevOps-as-a-Service Approach",
        title_11: "Expert Knowledge of DevOps",
        title_12: "Flexible Engagement Models",

        subtitle_10:
            "As a DevOps consulting company, tokuu offers DevOps as a standalone service and hires only certified cloud and infrastructure engineers to augment our DevOps team. For us, it’s a matter of reputation.",
        subtitle_11:
            "As a mature DevOps development company, we combine practical DevOps consulting experience with constant learning, getting our hands on every new tool and technology available on the market.",
        subtitle_12:
            "We offer transparent and effective cooperation models that can be adjusted to your business requirements, whether you’re a startup or a large enterprise.",
    },

    internet_of_things: {
        main_title: "IoT Development Services",
        main_subtitle:
            "As an innovative startup or enterprise, shifting tech responsibility to an experienced tech partner is a smart choice. With our IoT development services, tokuu is an experienced tech partner you can rely on. We can help you build smart gadgets, make analog products digital, and create infrastructure for custom IoT solutions. If you plan to undergo a holistic business change, our IoT consulting strategists can guide you through the process seamlessly.",

        title_1: "Custom IoT Solutions for End Users and Businesses",

        title_2: "Industrial IoT",
        title_3: "Consumer IoT",
        title_4: "Healthcare IoT",
        title_5: "Smart Homes",
        title_6: "Wearables",

        subtitle_2: "Predictive maintenance, machine vision systems, collaborative robots, and HMIs for industrial equipment.",
        subtitle_3: "Smart apparel and accessories, hearables, baby monitors, IoT-based pet-tracking solutions, and smart home systems.",
        subtitle_4:
            "Telecare systems, vital sign monitors, smart medication trackers, and comprehensive healthcare data analytics solutions.",
        subtitle_5:
            "Connected home solutions: remote controlled smart lighting systems,  IoT-powered HVAC solutions, security systems, Alexa-controlled products, self-learning smart home devices.",
        subtitle_6:
            "Wearable solutions: smart clothes, smart watches, fitness trackers, connected jewelry, hearables, augmented reality glasses and virtual reality headsets, baby care solutions, pet trackers.",

        title_7: "Our IoT Development Skills Matrix",
        title_8: "IoT Сonsulting",
        title_9: "Hardware Prototyping and Design",
        title_10: "Embedded Software Development",
        title_11: "IoT Dashboards",
        title_12: "Mobile Apps for Connected Devices",

        subtitle_8_1:
            "Our internet of things consulting services extend far beyond technical capabilities. If you need to build a product from scratch, we can help you turn the idea into an MVP, validate it on the market, and scale. We can also assist if you need to optimize how your business functions. First, we determine the problem, then we reorganize and automate existing business processes with the help of IoT. Our team has extensive experience in:",
        subtitle_8_2: "High-level IoT consulting around a business problem",
        subtitle_8_3: "Physical and digital product development consultancy",
        subtitle_8_4: "Digital transformation efforts and change management",

        subtitle_9_1:
            "Verify your concepts before committing to full-scale development with the help of our smart device prototypes. To prototype a gadget, we use IoT boards, sensors, and ready-made enclosures. The development process includes:",
        subtitle_9_2: "Schematics",
        subtitle_9_3: "PCB layout and design",
        subtitle_9_4: "PCB signal, power integrity, and thermal analysis",
        subtitle_9_5: "Custom enclosure design",
        subtitle_9_6: "Firmware development",
        subtitle_9_7: "Full documentation, verification, compliance, and fabrication support",

        subtitle_10_1:
            "tokuu develops firmware and embedded systems to integrate smart devices into IoT infrastructure, capture sensor data, relay it to the cloud, and perform data analysis on a device in edge and fog computing deployments. Our expertise encompasses:",
        subtitle_10_2: "DSP and microcontroller programming",
        subtitle_10_3: "Motor control apps for equipment, UAVs, and Smart Homes",
        subtitle_10_4: "ETL and middleware solutions for sensor data acquisition, integration, and M2M",
        subtitle_10_5: "CloudBridge development",
        subtitle_10_6: "BSPs for real-time and embedded OSs",
        subtitle_10_7: "AOSP development and support",

        subtitle_11_1:
            "With the help of tokuu, you can configure IoT devices, manage cyber-physical systems onsite and remotely, and analyze sensor data using BI tools and present it via interactive dashboards. Our portfolio includes:",
        subtitle_11_2: "Web-based HMIs",
        subtitle_11_3: "Preventative and predictive maintenance systems",
        subtitle_11_4: "Custom IoT platforms based on AWS IoT Greengrass, Azure IoT Hub, and Google Cloud IoT Core",
        subtitle_11_5: "Real-time sensor data visualization solutions",
        subtitle_11_6: "Admin dashboards for consumer electronics",

        subtitle_12_1:
            "As part of our IoT app development services, we design native and cross-platform mobile apps that give you on-the-go access to data generated by smart devices and act as remote controls for IoT solutions:",
        subtitle_12_2: "indoor navigation and geofencing apps",
        subtitle_12_3: "Mobile apps for wearables",
        subtitle_12_4: "Contactless payment solutions",
        subtitle_12_5: "Image recognition apps",

        title_13: "Our Approach to IoT Application Development",
        title_14: "Connect",
        title_15: "Collect",
        title_16: "Act",

        subtitle_14:
            "tokuu gives everyday objects a voice, enabling them to transmit real-time data via internet gateways. To achieve this, we use communication interfaces and networking protocols (NFC, Wi-Fi, BLE, etc.), RFID tags, beacons, and sensors.",
        subtitle_15:
            "We design embedded and distributed systems to capture sensor data, prevent data loss, and ingest data into storage solutions. Keep your data for further use or deliver the information in real time to an IoT application or endpoint device.",
        subtitle_16:
            "tokuu turns the data acquired from IoT system components into actionable insights: it is categorized, searchable, accessible via client apps, and visualized in line with the requirements of endpoint device interfaces.",

        title_17: "Why tokuu is the Right IoT Company for You",
        title_18: "Dedicated Expertise",
        title_19: "Built-in Security",
        title_20: "Usability as a Top Priority",
        title_21: "Scalability from Day One",

        subtitle_18: "Connected products—and the associated IoT application development services—are our core competence.",
        subtitle_19: "tokuu treats security as an integral part of IoT application development and hardware design.",
        subtitle_20:
            "We analyze stakeholder feedback and follow UX design best practices to create the Internet of Things solutions with the end user in mind.",
        subtitle_21: "We ensure IoT solutions perform flawlessly, regardless of the workload, number of users, and enabled features.",

        title_22: "OUR KNOW-HOW",

        title_23: "Rely on Our Experience in the Industry",
        subtitle_23:
            "tokuu bridges knowledge gaps in IoT development projects carried out by industrial companies, device manufacturers, and technology startups:",
        title_24: "Sports",
        title_25: "Media and Entertainment",
        title_26: "Consumer Electronics",
        title_27: "Industrial Manufacturing",
        title_28: "Healthcare and Life Science",

        subtitle_24:
            "We’ve done it all. Wearable devices that track athlete performance. Technology-aided recovery programs. Media streaming and social networking portals. Have a look at our portfolio for details on past projects and see for yourself why we’re the right company to build your IoT application!",
        subtitle_25:
            "Our expertise spans video streaming, content delivery networks, digital signage, and proximity solutions for contextual marketing and navigation.",
        subtitle_26:
            "As part of our internet of things development services, we assist consumer electronics brands in adding connectivity to existing devices and designing smart gadgets from the ground up.",
        subtitle_27:
            "We consult industrial companies on how to go from analog to digital, enhance equipment with sensors and their accompanying software infrastructure, and leverage sensor data for predictive maintenance.",
        subtitle_28:
            "tokuu has collaborated with digital health startups and medical organizations on a variety of projects: ambient living and telecare solutions, non-invasive diagnostics based on machine learning, and sensor data acquisition and processing tools.",
    },

    storage: {
        main_title: "Data Storage Development Services",
        main_subtitle:
            "We tap our development experience in SSD, MMC and NAND flash memory solutions, as well as visualization and testing tools, to help data storage companies bolster their R&D capabilities, minimize the cost of launching new solutions, and expand their footprint in new storage market segments.",

        title_2: "Low-level Solutions",
        title_3: "Data Management Solutions",
        title_4: "Automated SSD Testing",
        title_5: "DevOps Support",

        subtitle_2_1: "Full-stack SSD firmware development for mobile, desktop, and enterprise-level solutions:",
        subtitle_2_2: "SATA, NVMe, PCI Express protocols",
        subtitle_2_3: "Firmware system services",
        subtitle_2_4: "Debugging environment ",
        subtitle_2_5: "Manufacturing testing",
        subtitle_2_6: "Failure analysis",
        subtitle_2_7: "SSD simulation environment",

        subtitle_3_1: "Software solutions for data storage, maintenance, back-up, archiving, and migration:",
        subtitle_3_2: "Object storage systems",
        subtitle_3_3: "Data migration solutions",

        subtitle_4_1: "Engineering of a testing infrastructure:",
        subtitle_4_2: "Software installation and automated test-bench deployment",
        subtitle_4_3: "Unit testing",
        subtitle_4_4: "Integration testing",
        subtitle_4_5: "Conformance testing",
        subtitle_4_6: "End-to-end testing including load and performance testing",

        subtitle_5_1: "Pre-release support of the CI system to ensure uninterrupted solution delivery, including software and hardware:",
        subtitle_5_2: "CI infrastructure support",
        subtitle_5_3: "Field engineering",
        title_6: "tokuu’s Value",
        title_7: "Domain Expertise in Data Storage Development",
        title_8: "Ability to Ramp Up Teams",
        title_9: "Compliance with the World’s Security Standards",
        title_10: "Focus on High Product Quality",

        subtitle_7:
            "Our experience developing data storage solutions for industry giants has allowed us to hone our own development processes. We know how to mitigate risks regarding delivery times, quality, budget, and help our clients optimize development costs.",
        subtitle_8:
            "tokuu leverages efficient resource management techniques including a resource bench, regular skill profiling, training and certification, HR screening, and qualification roadmaps. These techniques ensure we quickly staff or ramp up project teams with engineers possessing the best-matching skillset.",
        subtitle_9:
            "We have the proper legal, physical, and technical controls installed across all our locations, in line with ISO 27001 specifications. Our security framework has effective measures for protecting our client’s intellectual property.",
        subtitle_10: "We organize turn-key test labs for automatic product testing to find bugs at the early stages of development.",

        title_11: "Dedicated Development Center",
        title_12: "Preparation",
        title_13: "Setup",
        title_14: "Launch",
        title_15: "Expansion",
        title_16: "Operation",

        subtitle_11:
            "tokuu has been active in the development of data storage solutions for years, and has already built 4 dedicated development centers (DDCs). tokuu’s DDCs comply with the client’s internal practices, proprietary methodologies, and corporate culture.",
        subtitle_12_1: "Identify required competency areas and qualifications",
        subtitle_12_2: "Plan team size and structure",
        subtitle_12_3: "Develop roadmap for DDC team setup",

        subtitle_13_1: "Setup core team",
        subtitle_13_2: "Setup environment and tools",
        subtitle_13_3: "Start knowledge transfer to the DDS’s core team",

        subtitle_14_1: "Start working on the task",
        subtitle_14_2: "Adapt knowledge as soon as possible",

        subtitle_15_1: "Add specialists in line with DDC roadmap",
        subtitle_15_2: "Share knowledge with the new members",

        subtitle_16_1: "Expand the team to planned size ",
        subtitle_16_2: "Reach maximum productivity",
    },

    computer_vision: {
        main_title: "Computer Vision Solutions",
        main_subtitle:
            "Our ML expertise is the foundation of our Computer Vision service offering. We will find the best way to address your challenges by crafting tailored recognition processes and multi-parameter analysis solutions no matter what project you are envisaging: an industrial automation visual inspection (AVI) platform, an advanced CCTV system or image-based analytics tool implementation.",
        title_1: "",

        name_2: "Object Recognition",
        content_Recognition_1: "Building powerful CV systems that perform image classification, localization and pixel-level segmentation",
        content_Recognition_2: "Applying CV, ML and DL methods for face analysis, gesture and movement recognition, and machine vision",
        content_Recognition_3:
            "Developing task-oriented algorithms for background separation, digital filtering, noise suppression and shadow removal to achieve a higher degree of image accuracy",
        content_Recognition_4: "Designing features and classifiers for pattern matching",
        title_2: "Solutions for Object, Movement, and Pattern Recognition",
        subtitle_2:
            "tokuu offers a whole gamut of computer vision development services around image retrieval and object detection solutions, featuring high processing speed without sacrificing performance by:",

        name_3: "Object Tracking",
        content_Tracking_1: "Multi-camera systems with tools to locate an object in a video sequence",
        content_Tracking_2: "Custom neural networks and tracking algorithms for single and multi-oriented object tracking",
        title_3: "Object Tracking",
        subtitle_3:
            "We employ object tracking algorithms to deliver computer vision solutions, which are equally effective at detecting an object in a video and analyzing temporal information for trajectory prediction:",

        name_4: "Content Analysis",
        content_web_1:
            "Action recognition systems to detect activities or certain content (nudity, smoking, etc.) in a video frame, and trigger a specific action (alert, start/stop command)",
        content_web_2:
            "Distributed algorithms for complex real-time video content analysis over large-scale camera networks or client-server systems",
        content_web_3:
            "Implemented trajectory prediction tools for CV neural networks to empower drones and driverless cars with image processing capabilities",
        content_web_4: "Analytics tools working in the cloud, in servers on-premise and on the edge (in camera or encoder)",

        title_4: "Video Content Analysis",
        subtitle_4:
            "To kick-start your image analysis project and automate the interpretation of aggregated video content, tokuu delivers:",

        title_5: "Computer Vision Solution Types",
        title_6: "Solutions for object, movement, pattern recognition",
        title_7: "Object Tracking",
        title_8: "Video Analysis",

        subtitle_6:
            "Multi-factor authentication products, AI-enhanced CCTV monitoring solutions, and biometric digital pattern matching software.",
        subtitle_7:
            "Multi-object tracking (MOT) software in a live video stream, CNN-based object detection and target tracking systems, and CV software for diverse camera types.",
        subtitle_8:
            "Disaster management and security solutions, traffic-monitoring software, video and image analysis apps for IP cameras, video servers and PC-based platforms.",

        title_9: "Industry Experience",
        subtitle_9:
            "With our hands-on experience in object detection, object tracking, and large scale data analytics, our team will help reduce human effort and optimize operations of all types.",

        title_10: "Healthcare",
        subtitle_10:
            "To help medical experts tackle an increasing amount of graphical information, we engineer solutions that process large data sets, like CT, X-Ray, and MRI scan images for more accurate diagnostics, surgical procedures and therapy. Leveraging CV, we build interactive medical imaging solutions which convert two dimensional scans images into 3D interactive models. Also, we deliver CV-based telemedicine and remote patient monitoring (RPM) products, like fall detection systems for elderly care.",

        title_11: "Retail",
        subtitle_11:
            "We have expertise in building a wide range of computer vision solutions for retail stores and malls, from in-store marketing tools like display optimization and promotion campaigns, to surveillance solutions to detect potential shoplifters and deter theft. Apart from this, video analysis tools speed up processes like shelf management and data collection, reducing manual labor and cutting business costs.",

        title_12: "Industrial Manufacturing",
        subtitle_12:
            "Automated control processes, enhanced with video object tracking technologies, are essential for connected factory. Such inspection systems help, for example, avoid errors in product packaging, improve quality control, speed up object sorting, and provide robots with human-like vision functionality.",

        title_13: "Computer Vision Technology Stack",
        subtitle_13: "",

        title_14: "COMPUTER VISION AND DEEP LEARNING",
        title_15: "MOBILE PLATFORMS",
        title_16: "DATA ANALYSIS",
        title_17: "DATA VISUALIZATION",
    },

    blockchain: {
        main_title: "Blockchain Software Development",
        main_subtitle:
            "Whether you want to build solutions for secure peer-to-peer payments and immediate microtransactions, or develop self-executing smart contracts, we will put our knowledge and blockchain technology skills into action. As an experienced blockchain development company, tokuu helps both startups and enterprises take advantage of the decentralized network built on blockchain.",

        title_1: "What We Build",
        title_2: "Smart Contracts: Writing and Testing",
        title_3: "Decentralized Apps (Dapps)",
        title_4: "Technical Development for ICO",
        title_5: "Blockchain Ecosystems",

        subtitle_2:
            "The tokuu team writes all types of smart contracts, including Ethereum, Hyperledger, EOS, and NEO-based. To make sure there are no flaws, misbehaviors and security holes in the smart contract code, we perform full-scale pre-release functional and non-functional testing.",
        subtitle_3:
            "Dapps combine a number of interrelated smart contracts, united by a business logic and frontend. These apps help customers enable faster payment processing, and provide secure and reliable data records. Our team builds up a complete Dapp architecture, including communication with oracles, and creates a comprehensive UX/UI design.",
        subtitle_4:
            "If you have a business idea and need a platform for project monetization, our team is well-versed in delivering Initial Coin Offerings (ICO). We will provide an accurate technical realization of your ICO ecosystem: develop an ERC20-compliant token, build your wallet and oracles, and review the technical and QA parts of your Whitepaper.",
        subtitle_5:
            "Blockchain ecosystems span a full range of cryptocurrency trading apps allowing for transparent and immutable transactions. tokuu crafts any blockchain ecosystem element from scratch including crypto wallets, analytics tools, multiplatform exchanges, blockchain explorers, oracle applications, and portfolio monitor solutions.",

        title_6: "Custom Blockchain Solutions in Focus",
        title_7: "Cryptocurrency Development",
        title_8: "Blockchain Development on Hyperledger",
        title_9: "Customized Blockchain Solutions",

        subtitle_6: "",
        subtitle_7:
            "Whether you want to enable cryptocurrency payment integration for your solution or need an ERC20-token on the Ethereum network, a custom cryptocurrency from scratch, or an element, such as Mining Client, Virtual Machine, Consensus Algorithm or a P2P network — we can contribute to any cryptocurrency development initiative.",
        subtitle_8:
            "Our blockchain development team is skilled enough to leverage Hyperledger technology to build a secure corporate blockchain for your business and keep multi-party engagements immutable and tamperproof.",
        subtitle_9:
            "If you need more specific functionality tailored to your needs, we will consider all the aspects of the industry, conduct thorough business analysis, and build a custom-fit blockchain solution.",

        subtitle_10: "Our skills in blockchain software development have been instrumental in a wide array of industries:",

        title_11: "Healthcare",
        title_12: "Transportation",
        title_13: "Industrial Manufacturing",

        subtitle_11:
            "Our team of experts creates blockchain-driven solutions for health data acquisition and management, systems for medical workflow management, and secure decentralized databases for storing health data.",
        subtitle_12:
            "Through combining blockchain application development with smart contracts and IoT sensors, we enable transportation companies to improve their logistics processes and supply chain transparency — tracking goods and materials throughout the entire delivery process.",
        subtitle_13:
            "We help industrial enterprises reduce energy costs and introduce automated energy distribution and consumption solutions: platforms for cost-effective peer-to-peer energy trading, and decentralized networks of IoT devices.",

        title_14: "Tech Stack and Verified Expertise",

        title_15: "HYPERLEDGER FRAMEWORKS",
        title_16: "BACK-END",
        title_17: "ECOSYSTEM",
        title_18: "HYPERLEDGER TOOLS",
        title_19: "ENCRYPTION, DIGITAL SIGNATURE, HASHING",

        title_20: "Expertise in Blockchain",
        title_21: "Quality Control",
        title_22: "Expertise in Cryptography",

        subtitle_20:
            "The tokuu team architects solutions for all major blockchain platforms and cryptosystems, such as Bitcoin, Ethereum, and Hyperledger. If you need to build an entire blockchain infrastructure or any of its separate elements — blockchain core, nodes, or ecosystem apps, we are at your service.",
        subtitle_21:
            "Our QA team joins the project team at the analysis stage to develop a QA strategy, test each implemented feature, and run a Bug Bounty Program, which helps reduce the number of malfunctions in the solution and meet your needs.",
        subtitle_22:
            "With hands-on experience in data protection, we offer clients a wide spectrum of secure cryptographic solutions including encryption, digital signature and hashing algorithms (RSA, DES, AES, Diffie-Hellmann, ECC, TLS, SSL, SHA-1, SHA-2, SHA-3, MD5, etc.).",
    },
    consumer: {
        main_title: "Consumer Electronics Solutions",
        main_subtitle:
            "tokuu offers consumer electronics product development services to major brands and startups. We’ll help you add novel features to existing devices or create new gadgets from the ground up. For this, we draw on our full-stack expertise in hardware design, embedded systems development, AR/VR, mobile apps, and web solutions.",
        title_1: "The tokuu Consumer Electronics Solutions Portfolio",
        subtitle_1: "",

        title_2: "Extended Reality",
        subtitle_2_1:
            "Our Virtual, Augmented, and Mixed Reality team will help you create immersive content, fine-tune its delivery to destination devices, and enhance your products or services with contextual data. We have hands-on experience building:",
        subtitle_2_2: "AR remote assistance solutions",
        subtitle_2_3: "AR manuals and catalogs",
        subtitle_2_4: "Immersive entertainment solutions",
        subtitle_2_5: "VR games",

        title_3: "Image Processing",
        subtitle_3_1:
            "Our team uses Machine Learning to uncover patterns in images and video content. We enable data processing in the cloud and mimic the functionality on the device level so that gadgets can respond faster to user requests. We have worked with:",
        subtitle_3_2: "Printing equipment",
        subtitle_3_3: "Drones (computer vision)",
        subtitle_3_4: "Smart Home security systems",
        subtitle_3_5: "Medical imaging solutions",

        title_4: "User Interfaces",
        subtitle_4_1:
            "We have practical experience designing user interfaces for screen-based and screenless devices. Our domain knowledge spans wearable technology, native and cross-platform mobile applications, web apps, and desktop software:",
        subtitle_4_2: "Graphic HMIs: IoT dashboards, wearables, automotive interfaces",
        subtitle_4_3: "Voice Interfaces: Alexa, Google Assistant, custom",
        subtitle_4_4: "AR interfaces",

        title_5: "Vital Signs and Location Monitoring",
        subtitle_5_1:
            "We’ll help you capture the data from body and position sensors, process it in the cloud, enable smart notifications, and visualize the information on mobile, desktop, or in a browser. Our portfolio features:",
        subtitle_5_2: "Fitness trackers",
        subtitle_5_3: "Smart accessories",
        subtitle_5_4: "Vital signs monitors",
        subtitle_5_5: "Pet tracking solutions",

        title_6: "Smart Energy Usage",
        subtitle_6_1:
            "Modern HVAC systems leverage IoT to optimize energy usage. tokuu helps you create green tech solutions for HVAC that harvest environmental, motion, and occupancy sensor data to add comfort and minimize electricity bills. Partner with tokuu to enhance your IoT HVAC system with:",
        subtitle_6_2: "Real-time monitoring solutions",
        subtitle_6_3: "Data analysis solutions",
        subtitle_6_4: "HVAC remote management",

        title_7: "Full-cycle Consumer Electronics Product Development",

        title_8: "Analog to Digital",
        title_9: "App Development",
        title_10: "Turn-key Projects",
        title_11: "Project Rescue",

        subtitle_8:
            "Consider turning an existing device into an IoT solution? We’ll enhance the gadget with sensors, connect it to the Internet, and build an entire software ecosystem to implement it at scale.",
        subtitle_9:
            "As a full-stack development services provider, we can augment your device with embedded software, mobile applications (iOS, Android, Xamarin, Flutter), and admin dashboards.",
        subtitle_10:
            "Our turn-key electronic design services include hardware and custom software development, and assistance with compliance (HIPAA, FDA, GMP, DICOM, HL7) and mass production.",
        subtitle_11:
            "From PCB failure diagnostics to cybersecurity and DevOps implementation, we offer a wide range of services to help companies jump-start stalled projects and launch new products ahead of the competition.",
    },

    software: {
        main_title: "Software and Technology Industry",
        main_subtitle:
            "Our value proposition is that we're builders who help clients engineer software from scratch. Rather than help companies configure off-the-shelf solutions, we help them build custom technology for specific needs and demanding specifications. We're technical software developers who handle the most demanding engineering challenges.",
        title_1: "Our Key Domain Expertise",
        subtitle_1: "",

        title_2: "Solutions for Consumer Electronics",
        subtitle_2_1:
            "If you are looking to launch your software in the Consumer Electronics domain, we will transform it into a market-ready solution. We will extend it with firmware, drivers, customized operating system, or a connected device that meets your target audience’s needs. If you aim to market to an existing customer base, partner with tokuu to adapt your software to the specific end users’ requirements.",
        subtitle_2_2: "AR/VR remote assistance solutions",
        subtitle_2_3: "Smart Home security systems",
        subtitle_2_4: "Graphic HMIs and AR interfaces",
        subtitle_2_5: "Smart wearables",

        title_3: "Solutions for Oil & Gas",
        subtitle_3_1:
            "We will help you reach out to the Energy companies that are still relying on the traditional business model, which tends to underleverage data on the critical operational processes. We will employ AR/VR, ML, algorithms, and visualization techniques to demo the advantages of process digitalization.",
        subtitle_3_2: "Systems for automated UAV inspections",
        subtitle_3_3: "ML-driven analysis",
        subtitle_3_4: "Data visualization-based dashboards and reports",
        subtitle_3_5: "AR/VR visualization apps",
        subtitle_3_6: "AI-enabled enterprise intelligent search platforms",
        subtitle_3_7: "VR simulation of remote locations and emergency situations",

        title_4: "Digital Content Distribution for Media and Entertainment",
        subtitle_4_1:
            "We know how to help your clients in Media and Entertainment manage the expectations of their target audience to get rich, personalized, entertaining, real-time digital content, which is accessible across multiple form factors. We will come up with the best application of your original technology and reinforce it with our niche experience building digital signage, asset management, smart TV, and digital image processing solutions.",
        subtitle_4_2: "Digital asset management solutions",
        subtitle_4_3: "Digital Signage ecosystems",
        subtitle_4_4: "Multi-casting systems",
        subtitle_4_5: "Pattern and face recognition solutions",

        title_5: "Healthcare Solutions",
        subtitle_5_1:
            "If you aim to equip health facilities with workflow automation, medical image analysis, and telecare solutions powered by your technology, we can help. We understand the industry regulatory compliance requirements, know how to design complex electronic systems that have the right electromagnetic compatibility, and appreciate how to use AI technologies to get maximum insight from health diagnostics.",
        subtitle_5_2: "Custom remote Patient monitoring (RPM) solutions",
        subtitle_5_3: "Image annotation tools",
        subtitle_5_4: "Patient portal solutions for workflow automation",
        subtitle_5_5: "Inventory tracking systems: RFID, beacons",
        subtitle_5_6: "Population health management (PHM) software",

        title_6: "Competency Teams",
        title_7: "Onsite Supervision",

        subtitle_6:
            "Grouped by primary expertise, our competency teams accumulate technology and domain knowledge processed and organized into best practices in designated business areas. Functioning as training and coaching hubs, competency teams ensure continuity in knowledge transfer and skill actualization.",
        subtitle_7:
            "To secure optimal knowledge transfer among remote teams and minimize miscommunication, we may commission several team members to travel to a customer’s site. Onsite presence will allow us to obtain insight into local processes and facilitate project onboarding.",
    },
    data_storage: {
        main_title: "Solutions for Data Storage Industry",
        main_subtitle:
            "Whether you are looking to expand your storage solution footprint in a new market, bolster your SSD development efforts or reinforce your R&D initiatives in flash memory implementation, we have the experience and resources for any of such projects.",

        title_2: "SSD Storage System Firmware",
        title_3: "Protocols, Drivers & Tools",
        title_4: "NAND Flash Memory",
        title_5: "Custom Testing",

        subtitle_2:
            "We design, implement and evaluate real-time embedded system firmware for SSD products. We tap the expertise we have acquired over many years of working with the top names in the industry.",
        subtitle_3:
            "Our embedded developers and QA engineers have hands-on experience with multiple storage protocols like PCIe/NVMe, SATA, eMMC and SCSI. We also develop custom tools and test stands for all manner of testing and quality assurance.",
        subtitle_4:
            "We work to understand your needs for erase counts, error correction capabilities and a range of storage longevity requirements like the write endurance in a NAND flash memory chip.",
        subtitle_5:
            "To accelerate firmware development, we have adopted a continuous integration (CI) process. We work with you to evaluate your needs to create a custom testing infrastructure including event-triggered testing, time-triggered testing, and test benches. We can also implement a set of test APIs over several protocols, which allows for faster test performance.",

        title_6: "Experience",
        title_7: "Firmware + Software",
        title_8: "Performance",

        subtitle_6:
            "tokuu offers unique experience in the domain of storage solutions. In addition to our specific project experience, we have built dedicated development teams for some of the biggest names in the industry.",
        subtitle_7:
            "We understand you have to be great at software development in order to enable firmware developers to write better code and ensure better quality assurance. We are experts at creating and using jointly developed tools to get the job done.",
        subtitle_8:
            "We apply decades of in-depth experience to help deliver two of the most critical features demanded of today’s storage solutions: speed and reliability. Performance is a key driver of market acceptance and we know how to deliver.",
    },

    about_us: {
        title_1: "Creating a Better Future for You",
        subscribe_1:
            "tokuu was built from the ground up to specialize in new product development and R&D, tackling the most difficult problems in the tech sphere. Now we've expanded to offer innovation and ideation plus digital transformation business consulting. And the tokuu tokuu Venture Studio, backed by the tokuu Venture Fund, augment our capabilities to serve early-stage startups. Our superpower is to deliver all of this under one roof on a global scale. So let's get started and build a better future together.",
        subscribe_2: "Learn How We Do It",
    },

    temp: {
        main_title: "",
        main_subtitle: "",
        title_10: "",
        subtitle_10: "",
    },
};

<template>
    <v-menu open-on-hover offset-y>
        <template #activator="{ on, attrs, value }">
            <span class="cursor lang-switcher d-flex align-center py-1 px-2" dark v-bind="attrs" v-on="on" text>
                <span class="mr-1 capitalize">{{ $i18n.locale }}</span>
                <v-icon :class="{ rotate: value }" small>mdi-chevron-down</v-icon>
            </span>
        </template>
        <v-list>
            <v-list-item class="lang-list-itme cursor" v-for="item in languageOptions" :key="item.key" @click="changeLanguage(item)">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    name: "Language",
    components: {},
    data: () => ({
        languageOptions: [
            { key: "en", title: "English" },
            { key: "ja", title: "日本語" },
            // { key: "zh", title: "中文" },
        ],
    }),
    mounted() {
        console.warn("---->>>>");
        this.$i18n.locale = this.languageOptions[0].key;
    },
    methods: {
        changeLanguage(item) {
            this.$i18n.locale = item.key;
        },
    },
};
</script>
<style lang="scss" scoped>
.lang-switcher {
    width: 50px;
    border: 1px solid hsla(0, 0%, 100%, 0.7);
    border-radius: 3px;
    color: #a0e0ff;
    i {
        color: #a0e0ff;
    }
}
.lang-list-itme {
    transition: background 0.2s;
    background-color: #fff;
    .v-list-item__title {
        transition: color 0.2s;
    }
    &:hover {
        .v-list-item__title {
            color: rgb(0, 173, 255);
        }
        background-color: rgba(0, 173, 255, 0.1);
    }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
// RouterTab 内置路由
// import { RouterTabRoutes } from 'vue-router-tab'

Vue.use(VueRouter);

const routes = [
    // 引入 RouterTab 内置路由以支持 iframe 页签
    //   ...RouterTabRoutes,
    {
        path: "/", // 默认页和父级路由一致
        name: "PageHome",
        component: () => import(/* webpackChunkName: "PageHome" */ "../views/PageHome.vue"),
        meta: {
            title: "PageHome", // 页签标题
        },
    },
    {
        path: "/custom_application_development",
        name: "CustomApplicationDevelopment",
        component: () => import(/* webpackChunkName: "CustomApplicationDevelopment" */ "../views/CustomApplicationDevelopment.vue"),
        meta: {
            title: "CustomApplicationDevelopment", // 页签标题
        },
    },
    {
        path: "/ui_ux_design",
        name: "UiUxDesign",
        component: () => import(/* webpackChunkName: "UiUxDesign" */ "../views/UiUxDesign.vue"),
        meta: {
            title: "UiUxDesign", // 页签标题
        },
    },
    {
        path: "/about",
        name: "About",
        component: () => import(/* webpackChunkName: "About" */ "../views/About.vue"),
        meta: {
            title: "About", // 页签标题
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
        meta: {
            title: "Contact Us", // 页签标题
        },
    },
    {
        path: "/cybersecurity",
        name: "Cybersecurity",
        component: () => import(/* webpackChunkName: "Cybersecurity" */ "../views/Cybersecurity.vue"),
        meta: {
            title: "Cybersecurity", // 页签标题
        },
    },
    {
        path: "/embedded_system",
        name: "EmbeddedSystem",
        component: () => import(/* webpackChunkName: "EmbeddedSystem" */ "../views/EmbeddedSystem.vue"),
        meta: {
            title: "EmbeddedSystem", // 页签标题
        },
    },
    {
        path: "/frontend_development",
        name: "FrontendDevelopment",
        component: () => import(/* webpackChunkName: "FrontendDevelopment" */ "../views/FrontendDevelopment.vue"),
        meta: {
            title: "FrontendDevelopment", // 页签标题
        },
    },

    {
        path: "/backend_development",
        name: "BackendDevelopment",
        component: () => import(/* webpackChunkName: "BackendDevelopment" */ "../views/BackendDevelopment.vue"),
        meta: {
            title: "BackendDevelopment", // 页签标题
        },
    },
    {
        path: "/application_maintenance_and_support",
        name: "Maintenance",
        component: () => import(/* webpackChunkName: "Maintenance" */ "../views/Maintenance.vue"),
        meta: {
            title: "Maintenance", // 页签标题
        },
    },
    {
        path: "/devops_as_a_service",
        name: "DevopsService",
        component: () => import(/* webpackChunkName: "DevopsService" */ "../views/DevopsService.vue"),
        meta: {
            title: "DevopsService", // 页签标题
        },
    },
    {
        path: "/internet_of_things",
        name: "InternetOfThings",
        component: () => import(/* webpackChunkName: "InternetOfThings" */ "../views/InternetOfThings.vue"),
        meta: {
            title: "InternetOfThings", // 页签标题
        },
    },
    {
        path: "/nand_storage_memory_solutions",
        name: "Storage",
        component: () => import(/* webpackChunkName: "Storage" */ "../views/Storage.vue"),
        meta: {
            title: "Storage", // 页签标题
        },
    },
    {
        path: "/video_analytics_and_camera_app_development",
        name: "ComputerVision",
        component: () => import(/* webpackChunkName: "ComputerVision" */ "../views/ComputerVision.vue"),
        meta: {
            title: "ComputerVision", // 页签标题
        },
    },
    {
        path: "/blockchain_software_development",
        name: "Blockchain",
        component: () => import(/* webpackChunkName: "Blockchain" */ "../views/Blockchain.vue"),
        meta: {
            title: "Blockchain", // 页签标题
        },
    },
    {
        path: "/consumer_electronics_industry",
        name: "Consumer",
        component: () => import(/* webpackChunkName: "Consumer" */ "../views/Consumer.vue"),
        meta: {
            title: "Consumer", // 页签标题
        },
    },
    {
        path: "/software_and_technology_industry",
        name: "Software",
        component: () => import(/* webpackChunkName: "Software" */ "../views/Software.vue"),
        meta: {
            title: "Software", // 页签标题
        },
    },
    {
        path: "/data_storage_industry",
        name: "DataStorage",
        component: () => import(/* webpackChunkName: "DataStorage" */ "../views/DataStorage.vue"),
        meta: {
            title: "DataStorage", // 页签标题
        },
    },
    //   {
    //     path: '/page/:id',
    //     name: 'Page',
    //     component: importPage('Page'),
    //     meta: {
    //       key: 'path',
    //       title(route) {
    //         return `Page ${route.params.id}`
    //       }
    //     }
    //   }
];

/**修正router push 相同页时Avoided redundant navigation to current location 错误 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    //@ts-ignore
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 如果有 savedPosition，则滚动到该位置
        if (savedPosition) {
            return savedPosition;
        }
        // 否则，如果目标路由的哈希存在，则滚动到该哈希所在的位置
        if (to.hash) {
            return { selector: to.hash };
        }
        // 默认滚动到页面顶部
        return { x: 0, y: 0 };
    },
});
export default router;

<template>
    <div>
        <v-app-bar app color="headerBackground" height="90" max-width="100vw">
            <CustomContainer class="d-flex align-center py-0" v-scroll-reveal="{ reset: false, distance: '-50px' }">
                <!-- pc -->
                <template v-if="$vuetify.breakpoint.lgAndUp">
                    <img class="cursor" src="@/assets/logo.png" height="100" style="margin-right: 10%" @click="$router.push('/')" />
                    <HeaderNav class="d-flex mr-auto" :items="items" />
                    <Language />
                    <v-btn color="white" class="ml-6" large elevation="0" @click="onBtnContact">
                        <span class="black--text font-weight-bold">{{ $t("common.contact_us") }}</span>
                    </v-btn>
                </template>

                <!-- h5 -->
                <template v-else>
                    <img src="@/assets/logo.png" width="120" class="mr-auto ml-3" @click="$router.push('/')" />
                    <Language v-if="!$vuetify.breakpoint.xsOnly" />
                    <v-btn class="ml-2" color="white" small text @click="onBtnContact">
                        <span class="font-weight-bold"> {{ $t("common.contact_us") }}</span>
                    </v-btn>
                    <v-btn icon @click="drawer = true">
                        <v-icon color="white">mdi-menu</v-icon>
                    </v-btn>
                </template>
            </CustomContainer>
        </v-app-bar>

        <v-navigation-drawer color="headerBackground" v-model="drawer" width="100%" fixed right temporary style="max-width: 500px">
            <div class="pa-6 d-flex">
                <Language v-if="$vuetify.breakpoint.xsOnly" />
                <v-btn icon class="ml-auto mr-n2" @click="drawer = !drawer">
                    <v-icon color="white" large>mdi-close</v-icon>
                </v-btn>
            </div>
            <!-- <v-autocomplete append-icon="mdi-magnify" solo class="pa-6 search-select"> </v-autocomplete> -->
            <v-expansion-panels class="custom-panels px-6" accordion>
                <v-expansion-panel v-for="(item, i) in items" :key="i">
                    <v-expansion-panel-header :hide-actions="!item.children || item.children.length == 0">
                        <div @click="onBtnClick(item)">{{ $t(item.title) }}</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-4 cursor">
                        <div v-for="(ele, index) of item.children" :key="index" @click="onBtnClick(ele)" class="my-2">
                            {{ $t(ele.name) }}
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-navigation-drawer>
    </div>
</template>
<script>
import Language from "@/components/header/Language.vue";
import HeaderNav from "@/components/header/HeaderNav.vue";
export default {
    name: "Header",

    components: { Language, HeaderNav },

    data: () => ({
        drawer: false,
        items: [
            {
                title: "hander.head_title_1",
                children: [
                    {
                        name: "hander.head_title_2",
                        path: "/custom_application_development",
                        children: [
                            { name: "hander.head_title_3" },
                            { name: "hander.head_title_4" },
                            { name: "hander.head_title_5" },
                            { name: "hander.head_title_6" },
                            { name: "hander.head_title_7" },
                            { name: "hander.head_title_8" },
                        ],
                    },
                    { name: "hander.head_title_9", path: "/ui_ux_design" },
                    { name: "hander.head_title_10", path: "/cybersecurity" },
                    { name: "hander.head_title_11", path: "/embedded_system" },
                    { name: "hander.head_title_12", path: "/frontend_development" },
                    { name: "hander.head_title_13", path: "/backend_development" },
                    { name: "hander.head_title_14", path: "/application_maintenance_and_support" },
                    { name: "hander.head_title_15", path: "/devops_as_a_service" },
                ],
            },
            {
                title: "hander.head_title_16",
                children: [
                    { name: "hander.head_title_17", path: "/internet_of_things" },
                    { name: "hander.head_title_18", path: "/nand_storage_memory_solutions" },
                    { name: "hander.head_title_19", path: "/video_analytics_and_camera_app_development" },
                    { name: "hander.head_title_20", path: "/blockchain_software_development" },
                ],
            },
            {
                title: "hander.head_title_21",
                children: [
                    { name: "hander.head_title_22", path: "/consumer_electronics_industry" },
                    // { name: "hander.head_title_23", path: "/frontend_development" },
                    { name: "hander.head_title_24", path: "/software_and_technology_industry" },
                    // { name: "hander.head_title_25", path: "/frontend_development" },
                    { name: "hander.head_title_26", path: "/data_storage_industry" },
                ],
            },
            { title: "hander.head_title_27", children: [], path: "/about" },
        ],
    }),
    computed: {},
    methods: {
        onBtnContact() {
            this.$router.push("contact");
        },
        onBtnClick(item) {
            if (item && item.path) this.$router.push(item.path);
        },
    },
};
</script>
<style lang="scss" scoped>
.rotate {
    transform: rotate(180deg);
}
::v-deep .search-select.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: unset !important;
}

::v-deep .custom-panels.v-expansion-panels {
    .v-expansion-panel {
        background-color: transparent;
        &::before {
            display: none;
        }
        .v-expansion-panel-header {
            font-size: 32px;
            color: white;
            .v-icon {
                font-size: 32px;
                color: white;
            }
        }
        .v-expansion-panel-content {
            color: white;
        }
        &:not(:first-child):after {
            border-color: rgba(255, 255, 255, 0.5);
        }
    }
}
</style>
